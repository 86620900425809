import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Button,
} from "reactstrap";
import http from "service/http";
import { useForm } from "react-final-form-hooks";
import { TextInput, useLoading, SelectInput } from "feature/Component";
import numeral from "numeral";
import store from "store";
import "./style.scss";
import { FormControl } from "@material-ui/core";
import { useTranslation } from "react-i18next";
// import formCreateInputs from 'service/form/create'

let user = store.get("user") || { level: "" };

const correctNumber = (amount) => numeral(amount).format("0,0.000");

function HistoryRow({ account, winlose, date }) {
  let betlistUri =
    "/betlist/game/" +
    winlose.game_code +
    "?fromDate=" +
    date.fromDate +
    "&toDate=" +
    date.toDate +
    "&username=" +
    account;
  if (winlose.game_code == "ssport") betlistUri += "&game=ssport";
  if (winlose.game_code == "maxbet") betlistUri += "&game=maxbet";
  if (winlose.game_code == "sexy_baccarat")
    betlistUri += "&site=" + winlose.providerCode;
  return (
    <tr>
      <td style={{ textAlign: "left" }}>
        <a href={betlistUri}>
          <b>{winlose.game == "maxbet" ? "M-Sport" : winlose.game}</b>
        </a>
      </td>
      <td>{correctNumber(winlose.totalTurnover)}</td>
      <td>{correctNumber(winlose.downlineWl)}</td>
      <td>{winlose.commPercen ? winlose.commPercen : 0}</td>
      <td>{correctNumber(winlose.downlineComm)}</td>
      <td>{correctNumber(winlose.downlineWl)}</td>
      <td>{correctNumber(winlose.downlineTotal)}</td>
      <td>{correctNumber(winlose.companyTotal * -1)}</td>
      <td>0</td>
      <td>0</td>
    </tr>
  );
}

function History(props) {
  const { t } = useTranslation();
  let total1 = {
    turnover: 0,
    downlineWl: 0,
    downlineComm: 0,
    downlineTotal: 0,
    downline: {
      sale: 0,
      comm: 0,
      winlose: 0,
      total: 0,
    },
    admin: {
      sale: 0,
      comm: 0,
      winlose: 0,
      total: 0,
    },
    companyTotal: 0,
    totalNet: 0,
  };
  user = props;
  const [loading] = useLoading();
  const [initialInputs, updateInitialInputs] = useState({});
  const [accounts, updateAccounts] = useState({});
  const [total, updateTotal] = useState(total1);
  // const [fromDate, toDate] = formCreateInputs(['fromDate', 'toDate'], form)
  const {
    user_level: role,
    email,
    username: agentUsername,
  } = store.get("user");
  const onSubmit = async (params) => {
    // console.log(params)
    loadReposrt(params);
  };
  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: initialInputs,
  });
  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    loadReposrt({
      fromDate: query.get("fromDate"),
      toDate: query.get("toDate"),
      banker: query.get("banker") || undefined,
    });
  }, []);
  const loadReposrt = (date) => {
    http
      .get({
        path: `admin/report/player/` + props.match.params.id,
        params: date,
      })
      .then((rs) => {
        // console.log(rs)
        const newAcc = rs;
        newAcc.winlose.map((a) => {
          // console.log(total1.downline.total , a.totalComm , a.total, total1.downline.total + a.totalComm + a.total)
          total1 = {
            turnover: total1.turnover + a.totalTurnover,
            downlineWl: total1.downlineWl + a.downlineWl,
            downlineComm: total1.downlineComm + a.downlineComm,
            downlineTotal: total1.downlineTotal + a.downlineTotal,
            downline: {
              sale: total1.downline.sale + a.totalTurnover,
              comm: total1.downline.comm + a.totalComm,
              winlose: total1.downline.winlose + Number(a.totalWinLose),
              total: total1.downline.total + a.total,
            },
            admin: {
              sale: total1.admin.sale + a.totalTurnover,
              comm: 0,
              winlose: total1.admin.winlose + a.totalWinLose,
              total: total1.admin.total + a.totalWinLose,
            },
            companyTotal: total1.companyTotal + a.companyTotal,
            totalNet: 0,
          };
        });
        updateAccounts(newAcc);
        updateTotal(total1);
        updateInitialInputs({
          fromDate: date.fromDate,
          toDate: date.toDate,
        });
        // console.log(total1.downline)
      });
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <React.Fragment>
                <Table responsive hover className="fullReportTable">
                  <thead>
                    <tr style={{ background: "rgb(5 48 130)", color: "white" }}>
                      <th scope="col" rowSpan="2" className="rowSpan">
                        {props.match.params.id}
                      </th>
                      <th scope="col" rowSpan="2" className="rowSpan">
                        {t("MEMBER_TURNOVER")}
                      </th>
                      <th scope="col" rowSpan="2" className="rowSpan">
                        {t("TOTAL_DOWNLINE_WINLOSE")}
                      </th>
                      <th scope="col" colSpan="4" className="colSpan">
                        {t("PLAYER")}
                      </th>
                      <th rowSpan="2" className="rowSpan">
                        {t("COMPANY_TOTAL")}
                      </th>
                      <th scope="col" rowSpan="2" className="rowSpan">
                        {t("SHARE_PERCEN")}
                      </th>
                      <th scope="col" rowSpan="2" className="rowSpan">
                        {t("TOTAL_NET")}
                      </th>
                    </tr>
                    <tr>
                      <th>{t("COMM_PERCEN")}</th>
                      <th>{t("COMMISSIONS")}</th>
                      <th>{t("WINLOSE")}</th>
                      <th>{t("TOTAL")}</th>
                    </tr>
                  </thead>
                  {accounts.player ? (
                    <tbody>
                      {accounts.winlose.map((winlose, i) => (
                        <HistoryRow
                          account={accounts.player}
                          winlose={winlose}
                          date={initialInputs}
                          index={i++}
                          key={i}
                        />
                      ))}
                      <tr
                        style={{ fontWeight: "bold", background: "#8f9ba68a" }}
                      >
                        <td>Total</td>
                        <td>{correctNumber(total.turnover)}</td>
                        <td>{correctNumber(total.downlineWl)}</td>
                        <td>0</td>
                        <td>{correctNumber(total.downlineComm)}</td>
                        <td>{correctNumber(total.downlineWl)}</td>
                        <td>{correctNumber(total.downlineTotal)}</td>

                        <td>{correctNumber(total.companyTotal * -1)}</td>
                        <td>0</td>
                        <td>{total.totalNet}</td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td>{t("NO_DATA")}</td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </React.Fragment>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default History;
