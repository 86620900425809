const ADMIN = 'ADMIN'
const ADMINCS = 'ADMIN_CS'
const AGENT = 'AGENT'
const STAFF = 'AGENT_CS'
const PARTNER = 'PARTNER'

const nav = (t) => [
  {
    permission: '*',
    name: t('DASHBOARD'),
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW',
    },
    role: '*'
  },
  {
    name: t('TRANSACTIONS'),
    icon: 'icon-notebook',
    children: [
      {
        name: t('DEPOSITS'),
        url: '/transaction/deposits',
        icon: 'icon-action-redo',
        permission: 'transaction_deposit',
        role: [ADMIN, ADMINCS]
      },
      {
        name: t('WITHDRAW'),
        url: '/transaction/withdraws',
        icon: 'icon-action-undo',
        permission: 'transaction_withdraw',
        role: [ADMIN, ADMINCS]
      },
      {
        name: t('TRANSFERS'),
        url: '/transaction/transfers',
        icon: 'icon-shuffle',
        permission: 'transaction_transfer',
        role: [ADMIN, ADMINCS, AGENT]
      },
      {
        name: t('TRANSFERS_PLAYER'),
        url: '/transaction/transfers-player',
        icon: 'icon-shuffle',
        role: [ADMIN, ADMINCS, AGENT]
      },
    ]
  },
  {
    name: t('BETTING_HISTORIES'),
    url: '/betting-histories',
    icon: 'icon-menu',
    permission: 'betlist',
    role: [AGENT, ADMIN, ADMINCS, PARTNER]
  },
  {
    name: t('RABAT_BONUS'),
    url: '/rabat',
    icon: 'icon-menu',
    role: [AGENT, ADMIN, ADMINCS, PARTNER]
  },
  {
    name: t('TRANSACTION_HISTORY'),
    url: '/transaction-history',
    icon: 'icon-menu',
    role: [AGENT, ADMIN, ADMINCS, PARTNER]
  },
  {
    name: t('GAME_MANAGEMENT'),
    url: '/games',
    icon: 'icon-disc',
    permission: 'betlist',
    role: [ ADMINCS,ADMIN]
  },
  {
    name: t('BARREL_REPORTS'),
    icon: 'icon-directions',
    children: [{
      name: t('EXPENSES'),
      url: '/expenses',
      icon: 'icon-arrow-right',
      role: [AGENT, ADMIN, ADMINCS, PARTNER]
    },{
      name: t('CONFIG_SHARE'),
      url: '/barrel-config-share',
      icon: 'icon-arrow-right',
      role: [ADMIN, ADMINCS]
    },{
      name: t('BARREL_REPORTS'),
      url: '/barrel',
      icon: 'icon-arrow-right',
      role: [AGENT, ADMIN, ADMINCS, PARTNER]
    }]
  }, {
    name: t('REPORTS'),
    icon: 'icon-directions',
    children: [{
      name: t('FULL_REPORTS'),
      url: '/reports',
      icon: 'icon-arrow-right',
      role: [AGENT, ADMIN, ADMINCS, PARTNER]
    },{
      name: t('FULL_REPORTS_PAYABLE'),
      url: '/reports-payable',
      icon: 'icon-arrow-right',
      role: [AGENT, ADMIN, ADMINCS, PARTNER]
    },{
      name: t('NEW_REPORTS'),
      url: '/reports-new',
      icon: 'icon-arrow-right',
      role: [AGENT, ADMIN, ADMINCS, PARTNER]
    }]
  },{
    name: t('ANNOUNCEMENT'),
    icon: 'icon-bell',
    url: '/announcement',
    role: [ADMINCS,ADMIN]
  },{
    name: t('BANKING'),
    icon: 'icon-credit-card',
    url: '/banking',
    permission: 'config_banking',
    role: [ ADMINCS,ADMIN]
  },
  {
    name:t( 'USER_MANAGEMENT'),
    url: '/users',
    icon: 'icon-people',
    permission: 'user_management',
    role: [ ADMINCS,ADMIN, AGENT],
    children: [
      {
        name: t('USERS'),
        icon: 'icon-list',
        url: '/users',
        permission: 'user_management',
        role: [ ADMINCS,ADMIN, PARTNER, AGENT]
      },
      {
        name: t('USERS_ACTION'),
        icon: 'icon-list',
        url: '/users-action',
        permission: 'user_management',
        role: [ ADMINCS,ADMIN, PARTNER, AGENT]
      },
      {
        name: t('CREATE_PARTNER'),
        icon: 'icon-user-follow',
        url: '/create-partner',
        permission: 'create_partner',
        role: [ ADMINCS,ADMIN, PARTNER]
      }, {
        name: t('CREATE_PLAYER'),
        icon: 'icon-user-follow',
        url: '/create-player',
        permission: 'create_player',
        role: [PARTNER, AGENT]
      },
      {
        name: t('CREATE_AGENT'),
        icon: 'icon-user-follow',
        url: '/create-agent',
        permission: 'create_agent',
        role: [PARTNER]
      },
      {
        name: t('CREATE_STAFF'),
        icon: 'icon-user-follow',
        url: '/create-staff',
        permission: 'create_staff',
        role: [ADMIN, PARTNER, AGENT]
      },
    ]
  },

  {
    name:t( 'PROMOTION'),
    url: '/promotion',
    icon: 'icon-present',
    role: [ ADMINCS,ADMIN],
    children: [
      {
        name: t('SETTING'),
        icon: 'icon-settings',
        url: '/promotion-setting',
        role: [ADMINCS,ADMIN]
      }
    ]
  },

  // {
  //   name: t('POSITION_TAKING'),
  //   icon: 'icon-action-redo',
  //   url: '/position-taking',
  //   permission: 'create_partner',
  //   role: [ADMIN, ADMINCS, PARTNER, AGENT]
  // },
  // {
  //   name: t('POSITION_TAKING_GROUP'),
  //   icon: 'icon-action-redo',
  //   url: '/position-taking-group',
  //   permission: 'create_partner',
  //   role: [ADMIN, ADMINCS, PARTNER, AGENT]
  // },
  // {
  //   name: t('COMMISSIONS'),
  //   icon: 'icon-action-redo',
  //   url: '/commissions',
  //   permission: 'create_partner',
  //   role: [ADMIN, ADMINCS]
  // },

  // {
  //   name: t('COMMISSIONS'),
  //   icon: 'icon-action-redo',
  //   url: '/commissions-new',
  //   permission: 'create_partner',
  //   role: [ADMIN, ADMINCS]
  // },
  {
    name: t('COMMISSIONS'),
    icon: 'icon-action-redo',
    url: '/comm',
    permission: 'create_partner',
    role: [ADMIN, ADMINCS]
  },

  {
    name: t('GAME_SETTING'),
    icon: 'icon-settings',
    url: '/game',
    permission: 'config_game',
    role: [AGENT]
  },
  {
    name: t('PROFILE'),
    icon: 'icon-folder',
    url: '/profile',
    permission: '*',
    role: '*'
  },
  {
    name: t('LOGOUT'),
    icon: 'icon-logout',
    url: '/login',
    permission: '*',
    role: '*'
  }
]
export default (acl, userLevel, t) => ({
  items: userLevel === STAFF ? nav.map((item) => {
    const { permission, children } = item
    if(permission === '*') return item;
    if(!children) {
      if(acl.includes(permission)) {
        return item
      }
      return false
    }
    const result = children.filter((item) => {
      const { permission } = item
      return acl.includes(permission)
    })
    if(!result.length) return false
    return {
      ...item,
      children: result
    }
  }).filter(exist => exist)
  : nav(t).map((item) => {
    const { role, children } = item
    if(role === '*') return item;
    if(!children) {
      if(role.includes(userLevel)) {
        return item
      }
      return false
    }
    const result = children.filter((item) => {
      const { role } = item
      return role.includes(userLevel)
    })
    if(!result.length) return false
    return {
      ...item,
      children: result
    }
  }).filter(exist => exist)
})
