import React, { useEffect, useState } from 'react'
import store from 'store'
import config from 'config'
import moment from 'moment'
import * as QRCode from 'qrcode.react'
import copy from 'copy-to-clipboard'
import {
  Badge,
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Fade,
  Form,
  FormGroup,
  FormText,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  ButtonGroup,
  Table
} from 'reactstrap';
import Joi from '@hapi/joi'
import http from 'service/http'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'

import { useDialog, TextInput, SelectInput } from 'feature/Component'
import { useForm } from 'react-final-form-hooks'
import { useTranslation } from 'react-i18next'

function Profile() {
  const { t } = useTranslation()

  const [user, setUser] = useState(store.get('user'))
  const [dataEdit, setDataEdit] = useState({
    email: user.email,
    fullname: user.fullname,
    mobile_phone: user.mobile_phone,
  })
  const url = config.baseUIUrl + '/create-partner?user_level=PARTNER&parent=' + user.username
  const urlAgent = config.baseUIUrl + '/create-agent?user_level=AGENT&parent=' + user.username
  const urlPlayer = config.baseUIUrl + '/create-player?user_level=PLAYER&parent=' + user.username

  const clickshare = (message) => {
    if (navigator.share) {
      navigator.share({
        title: 'Web Fundamentals',
        text: message,
        // url: '',
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      copy(message)
      alert('copied to clipboard')
    }
  }
  const [Dialog, showDialogWithMessage] = useDialog({
    title: t('UPDATE_USER_SUCCESS')
  })
  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    type: 'error',
    title: t('ERROR')
  })
  const onSubmit = async payload => {
    try {
      await http.put({ path: `admin/changepass`, payload })
      showDialogWithMessage(t('YOU_REQUEST_SUBMITED_SUCCESS'))
    } catch (error) {
      if(error.message) showDialogErrorWithMessage(error.message)
      throw error
    }
  }
  const updateInfo = async () => {
    try {
      await http.put({ path: `users/` + user.user_id, payload: dataEdit })
      showDialogWithMessage(t('YOU_REQUEST_SUBMITED_SUCCESS'))
      const newUser = {...user, ...dataEdit}
      store.set('user', newUser)
      setUser(newUser)
    } catch (error) {
      if(error.message) showDialogErrorWithMessage(error.message)
      throw error
    }
  }
  const schema = Joi.object().keys({
    oldpassword: Joi.string().regex(/^[a-zA-Z0-9]{3,30}$/),
    newpassword: Joi.string().regex(/^[a-zA-Z0-9]{3,30}$/)
  })
  const { form, handleSubmit } = useForm({
    onSubmit,
    validate: validate(schema),
    initialValues: {
      oldpassword: '',
      newpassword: '',
    }
  })
  const [oldpassword, newpassword] = formCreateInputs(['oldpassword', 'newpassword'], form)
  return (
    <div className="animated fadeIn">
        <Row>
          <Col xs="12" md="6" lg="3">
            <Card>
              <CardHeader>
                <strong>{t('USER_INFO')}</strong>
              </CardHeader>
              <CardBody>
                <div className="form-horizontal">
                  <FormGroup row>
                    <Col md="4">
                      <Label>{t('USERNAME')}</Label>
                    </Col>
                    <Col xs="12" md="8">
                      <p className="form-control-static">{user.username}</p>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="4">
                      <Label htmlFor="text-input">{t('EMAIL')}</Label>
                    </Col>
                    <Col xs="12" md="8">
                      <Input type="email" name="email" id="exampleEmail" value={dataEdit.email} onChange={(e) => setDataEdit({...dataEdit, email: e.target.value})} />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="4">
                      <Label htmlFor="text-input">{t('FULLNAME')}</Label>
                    </Col>
                    <Col xs="12" md="8">
                      <Input type="text" name="email" id="exampleEmail" value={dataEdit.fullname} onChange={(e) => setDataEdit({...dataEdit, fullname: e.target.value})} />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="4">
                      <Label htmlFor="text-input">{t('PHONE')}</Label>
                    </Col>
                    <Col xs="12" md="8">
                      <Input type="text" name="email" id="exampleEmail" value={dataEdit.mobile_phone} onChange={(e) => setDataEdit({...dataEdit, mobile_phone: e.target.value})} />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col xs="6">
                      <Button type='btn' onClick={updateInfo} color="info" className="px-4" >{t('UPDATE')}</Button>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="4">
                      <Label htmlFor="text-input">{t('POINT')}</Label>
                    </Col>
                    <Col xs="12" md="8">
                      <p className="form-control-static">{user.point}</p>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="4">
                      <Label htmlFor="text-input">{t('ROLE')}</Label>
                    </Col>
                    <Col xs="12" md="8">
                      <p className="form-control-static">{user.user_level}</p>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="4">
                      <Label htmlFor="text-input">{t('STATUS')}</Label>
                    </Col>
                    <Col xs="12" md="8">
                      <p className="form-control-static">{user.user_inactive ? t('INACTIVE') : t('ACTIVE')}</p>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="4">
                      <Label htmlFor="text-input">{t('LAST_LOGIN')}</Label>
                    </Col>
                    <Col xs="12" md="8">
                      <p className="form-control-static">{moment(user.lastest_login).format('YYYY-MM-DD HH:mm:ss')}</p>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col  xs="12" md="9">
                      <Label>{t('TOTAL_PARTNER_ACTIVE_SUSPENDED')}</Label>
                    </Col>
                    <Col md="3">
                      <p className="form-control-static">{user.child.find(x => x.user_level == 'PARTNER' && x.user_inactive == 0) ? user.child.find(x => x.user_level == 'PARTNER' && x.user_inactive == 0).number : 0} / {user.child.find(x => x.user_level == 'PARTNER' && x.user_inactive != 0) ? user.child.find(x => x.user_level == 'PARTNER' && x.user_inactive != 0).number : 0}</p>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col  xs="12" md="9">
                      <Label>{t('TOTAL_AGENT_ACTIVE_SUSPENDED')}</Label>
                    </Col>
                    <Col md="3">
                      <p className="form-control-static">{user.child.find(x => x.user_level == 'AGENT' && x.user_inactive == 0) ? user.child.find(x => x.user_level == 'AGENT' && x.user_inactive == 0).number : 0} / {user.child.find(x => x.user_level == 'AGENT' && x.user_inactive != 0) ? user.child.find(x => x.user_level == 'AGENT' && x.user_inactive != 0).number : 0}</p>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col  xs="12" md="9">
                      <Label>{t('TOTAL_PLAYER_ACTIVE_SUSPENDED')}</Label>
                    </Col>
                    <Col md="3">
                      <p className="form-control-static">{user.child.find(x => x.user_level == 'PLAYER' && x.user_inactive == 0) ? user.child.find(x => x.user_level == 'PLAYER' && x.user_inactive == 0).number : 0} / {user.child.find(x => x.user_level == 'PLAYER' && x.user_inactive != 0) ? user.child.find(x => x.user_level == 'PLAYER' && x.user_inactive != 0).number : 0}</p>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="4">
                      <Label htmlFor="text-input">{t('OLD_PASS')}</Label>
                    </Col>
                    <Col xs="12" md="8">
                      <TextInput input={oldpassword} type="password" label={t('ENTER_PASS')} fullWidth/>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="4">
                      <Label htmlFor="text-input">{t('NEW_PASS')}</Label>
                    </Col>
                    <Col xs="12" md="8">
                      <TextInput input={newpassword} type="password" label={t('ENTER_PASS')} fullWidth/>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col xs="6">
                      <Button type='btn' onClick={handleSubmit} color="info" className="px-4" >{t('UPDATE')}</Button>
                    </Col>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" md="6" lg="3">
            <Card>
              <CardHeader>
                <strong>{t('USER_INFO')}</strong>
              </CardHeader>
              <CardBody>
                <Form className="form-horizontal">

                  <FormGroup row>
                  <Col xs="12" lg="12">
                    <Table responsive bordered>
                      <thead>
                      <tr>
                        <th>{t('PLATFORM')}</th>
                        <th>{t('PT')} (%)</th>
                        <th>{t('COMM')} (%)</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        user.pt.map(pt => (<tr>
                          <th>{pt.game}</th>
                          <td>{pt.pt}</td>
                          <td>{pt.comm}</td>
                        </tr>))
                      }

                      </tbody>
                    </Table>
                  </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
          {user.user_level == 'ADMIN' || user.user_level == 'PARTNER' ? (<Col xs="12" sm="6" lg="3">
            <Card className="text-white bg-primary profile">
              <CardHeader>
                <strong>{config.title} {t('IPAY_QR_CODE')} - {t('REGISTER_PARTNER')}</strong>
              </CardHeader>
              <CardBody className="pb-0">
                <ButtonGroup className="float-right">
                  <div onClick={() => clickshare(url)}><i className="icon-share"></i></div>
                </ButtonGroup>
                <img style={{ width: '90%', marginBottom: 20 }} src={config.logo} alt=""/>

                <br/>
                <div style={{ textAlign: 'center' }}>
                  <QRCode size={256} value={url}/>
                </div>
                <br/>

              </CardBody>
              <img src="share.png" alt=""/>
            </Card>
          </Col>) : ''
          }
          {user.user_level == 'PARTNER' ? (<Col xs="12" sm="6" lg="3">
            <Card className="text-white bg-success">
              <CardHeader>
                <strong>{config.title} {t('IPAY_QR_CODE')} - {t('REGISTER_AGENT')}</strong>
              </CardHeader>
              <CardBody className="pb-0">
                <ButtonGroup className="float-right" >
                  <div onClick={() => clickshare(urlAgent)}><i className="icon-share"></i></div>
                </ButtonGroup>
                <img style={{ width: '90%', marginBottom: 20 }}  src={config.logo} alt=""/>

                <br/>
                <div style={{textAlign: 'center'}}>
                  <QRCode size={256} value={urlAgent} />
                </div>
                <br/>

              </CardBody>
              <img src="share.png" alt=""/>
            </Card>
          </Col>) : ''}
          {user.user_level == 'PARTNER' || user.user_level == 'AGENT' ? (<Col xs="12" sm="6" lg="3">
            <Card className="text-white bg-info">
              <CardHeader>
                <strong>{config.title} {t('IPAY_QR_CODE')} - {t('REGISTER_PLAYER')}</strong>
              </CardHeader>
              <CardBody className="pb-0">
                <ButtonGroup className="float-right" >
                  <div onClick={() => clickshare(urlPlayer)}><i className="icon-share"></i></div>
                </ButtonGroup>
                <img style={{ width: '90%', marginBottom: 20 }}  src={config.logo} alt=""/>

                <br/>
                <div style={{textAlign: 'center'}}>
                  <QRCode size={256} value={urlPlayer} />
                </div>
                <br/>

              </CardBody>
              <img src="share.png" alt=""/>
            </Card>
          </Col>) : ''}
        </Row>
      <Dialog />
      <ErrorDialog />
    </div>
  )
}

export default Profile
