import React, { useState, useEffect } from 'react';
import { Tooltip, Button, IconButton, Dialog, DialogContent, DialogTitle, TextField,
  Radio, RadioGroup, FormControlLabel, FormControl, FormLabel
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import http from 'service/http';
import store from 'store';
import { useForm } from 'react-final-form-hooks'
import TreeView from '../Users/treeView'
import { useDialog, TextInput, useLoading } from 'feature/Component'
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next'
import LoadingUi from 'feature/Component/Loading/ui'

function User({ username, show, positionTaking, email, level, index, toggleTree, user_id, showModalPT, showModalPT1, user_level, setPTDATA,
                parent_id }) {
  return (
    <tr key={username} style={{ display: show ? '' : 'none' }}>
      <td style={{ textAlign: 'left', width: 150, color: 'blue', cursor: 'pointer' }}>
          <b onClick={() => setPTDATA(username)}>{username}</b>
    </td>
      <td>{user_level}</td>
      {positionTaking.map(({ game_code, pt, ptmin,
                             game_name,
                             ptshare }) =>
        <td key={game_code}>
          <Tooltip title={"Edit " + game_code}>
            {/*{user.username !== 'admin' ? (<Button onClick={() => showModalPT1()} size="small" >*/}
              {/*{pt} %*/}
            {/*</Button>) :*/}
              {/*(<Button onClick={() => showModalPT({ game_code, pt, user_id, ptmin,*/}
                {/*game_name,*/}
                {/*ptshare })} size="small" >*/}
                {/*{pt} %*/}
              {/*</Button>)}*/}
            <Button onClick={() => showModalPT({ game_code, pt, user_id, ptmin, parent_id,
              game_name,
              ptshare })} size="small" >
              {pt} %
            </Button>
          </Tooltip>
        </td>)}
    </tr>
  )
}
const LoadChild = ({ root, docs, initialUsers}) => {
  return <TreeView parent={root} children={docs} initialUsers={initialUsers}/>
}
function Users(props) {
  let user = store.get('user') || { level: '' };
  const { username, position_taking } = user;
  const { t } = useTranslation()
  user = {...props}
  const [PT, updatePT] = useState([])
  const [docs, updateDocs] = useState([])
  const [treedocs, updateTreeDocs] = useState([])
  const [ptModal, updatePTModal] = useState({})
  const [ptData, setPtData] = useState({
    game_code: '',
    pt: 100,
    ptmin: 0,
    game_name: '',
    ptshare: 'freeshare'
  })
  const [loading, withLoading] = useLoading()
  const [ErroDialog, showErrorDialogWithMessage] = useDialog({
    title: t('ERROR'),
    type: 'error'
  })
  // const loadDocs = () => {
  //   if(!PT.length) {
  //     return updateDocs([])
  //   }
  //   updateDocs(treedocs)
  //
  // }
  const setPTDATA = (parent_id) => {
    const ptdata = PT.filter(x => x.parent_id == parent_id)
    // console.log(ptdata)
    updateDocs(ptdata)
  }
  const initialUsers = async (params = {}, level = false) => {

    if(level) {
      const ptdata = PT.filter(x => x.parent_id == params.parent_id)
      console.log(ptdata, level, params)
      updateDocs(ptdata)
      updateTreeDocs(treedocs.map((tr, i) => {
        if(tr.username == params.parent_id) tr.users = ptdata
        return tr
      }))
    } else updateTreeDocs(PT)
    // alert(params.parent_id)
// console.log(treedocs)
  }
  const initialPT = async (parent_id = null, level = null) => {
    const [resp, respGames] = await withLoading(() =>  Promise.all([
      http.get({ path: 'admin/config/position-takings' }),
      http.get({ path: 'games' })
    ]))
    const ptdata = resp.map((item, index) => {
      const PTJSON = JSON.parse(item.position_taking)
      const PT = respGames
        .filter(({ isActive }) => isActive)
        .map((item) => {
          // console.log(item.game_code)
          const found = PTJSON.find(({ game_code }) => game_code === item.game_code)
          if (found) {
            found.game_name = item.name
            return found
          }
          return { game_code: item.game_code, pt: 0, game_name: item.name }
        })
      return {
        ...item,
        index,
        positionTaking: PT,
        show: true
      }
    })
    updatePT(ptdata)
    // console.log(ptdata)
    let docc = ptdata.filter(x => x.parent_id == username)
    if(parent_id) docc = ptdata.filter(x => x.parent_id == parent_id)
    updateDocs(docc)
    updateTreeDocs(docc)
    // if(parent_id && level) initialUsers({parent_id}, level)
  }

  const toggleTree = (index, level) => {
    try {
      let ableToUpdate = true
      const newTable = PT.map((item, i) => {
        if (!ableToUpdate) {
          return item
        }
        if (i > index && item.level <= level) {
          ableToUpdate = false
          return item
        }

        if (ableToUpdate) {
          if (i > index && (item.level === level + 1 || item.show)) {
            return {
              ...item,
              show: !item.show
            }
          }
        }
        return item
      })
      // console.log(newTable)
      updatePT(newTable)
    } catch (error) {
      console.log(error)
    }
  }

  const showModalPT = async data => {
    withLoading(() => new Promise(resolve => setTimeout(resolve, 3000)))
    updatePTModal(data)
    setPtData({...data})

    // console.log(data)
  }
  const showModalPT1 = () => {
    showErrorDialogWithMessage(t('PLEASE_CONTACT_ADMIN'))
  }

  const onSubmit = async () => {
    const parentPTArr = PT.find(x => x.username == ptModal.parent_id)

    let parentPT
    if(parentPTArr) {
      parentPT = parentPTArr.positionTaking.find(x => x.game_code == ptData.game_code)
    } else {
      const positionTaking = position_taking[0] == '[' ? JSON.parse(position_taking) : position_taking
      // console.log(positionTaking)
      parentPT = positionTaking.find(x => x.game_code == ptData.game_code)
    }
    if(!parentPT) parentPT = {
      pt: 0
    }
    console.log(parentPT, ptData)
    if (ptData.pt > parentPT.pt * 1) {
      return updatePTModal({
        ...ptModal,
        error: t('PT_CANNOT_GREATE_THAN')
      })
    }
    const { positionTaking } = PT.find(({ user_id }) => user_id === ptData.user_id)
    const findPT = positionTaking.find(item => item.game_code === ptData.game_code)
    let cleanedPT

    if(findPT) {
      cleanedPT = positionTaking.map((item) => {
          if (item.game_code === ptData.game_code) {
            return {
              game_code: ptData.game_code,
              game_name: ptData.game_name,
              ptshare: ptData.ptshare || 'freeshare',
              ptmin: ptData.ptmin || 0,
              pt: ptData.pt,
            }
          }
          return item
        }).filter(({ pt }) => pt !== 0)
      // console.log(cleanedPT)
    } else {
      cleanedPT = positionTaking.push(ptData)
    }

    try {
      await withLoading(() => http.put({
        path: `admin/config/${ptModal.user_id}/position-taking`, payload: {
          percen: cleanedPT,
          before: ptData.game_name + ' - ptshare: ' + findPT.ptshare + ' - pt: ' + findPT.pt,
          after: ptData.game_name + ' - ptshare: ' + ptData.ptshare + ' - pt: ' + ptData.pt
        }
      }))
      updatePTModal({})
      initialPT(ptModal.parent_id, 1);
    } catch ({ message: { msg } }) {
      // console.log(msg)
      updatePTModal({
        ...ptModal,
        error: msg
      })
    }

  }
  const updatePTData = (e) => {
    setPtData({...ptData, [e.target.name]: e.target.value })
    // console.log(e.target)
  }
  useEffect(() => {
    initialPT()
  }, [])
  // console.log(PT)
  return (
    <div className="animated fadeIn">
      {loading && <LoadingUi/>}
      <Row>
        <Col md="4" lg="2">
          <Card>
            <CardHeader>
              <LoadChild root={user.username} docs={treedocs} initialUsers={initialUsers}/>
            </CardHeader>
          </Card>
        </Col>
        <Col md="8" lg="10">
          <Card>
            <CardHeader>
              <h6>
                {t('ACCOUNT')}: {user.username}
              </h6>
            </CardHeader>
            <CardBody>
              {docs.length
                ? <Table striped responsive hover style={{ textAlign: 'center' }}>
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: 150 }}>{t('PARTNER')}</th>
                      <th scope="col" style={{ width: '15%' }}>{t('ROLE')}</th>
                      {docs[0].positionTaking.map(({ game_code, game_name}) => <th key={game_code} scope="col" >{game_name}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {docs.map((item, index) =>
                      <User key={index} {...item} toggleTree={toggleTree} showModalPT={showModalPT} showModalPT1={showModalPT1} setPTDATA={setPTDATA} />
                    )}
                  </tbody>
                </Table>
                : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Dialog open={!isEmpty(ptModal)} onClose={() => updatePTModal({})} aria-labelledby="form-dialog-title" maxWidth="md">
        <DialogTitle id="form-dialog-title">{t('UPDATE_PERCEN')} - {ptData.game_code}</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">

            <RadioGroup row aria-label="position" name="ptshare" value={ptData.ptshare} onChange={updatePTData}>

              <FormControlLabel value="freeshare" control={<Radio color="primary" />} label={t('FREE_SHARE')}/>
              <FormControlLabel value="limitshare" control={<Radio color="primary" />} label={t('LIMIT_SHARE')} />
              <FormLabel style={{
                lineHeight: '40px',
                marginRight: 20
              }} component="label">	{'( Min: ' + (ptData.ptmin ? ptData.ptmin : 0) + '% - Max: ' + ptData.pt + '%	)'}</FormLabel>
              <FormControlLabel value="lockshare" control={<Radio color="primary" />} label={t('LOCK_SHARE')} />
            </RadioGroup>
          </FormControl>
          <div id="withdraw-form">
            {ptData.ptshare === 'limitshare' && <TextField style={{ marginRight: 15 }}
                                                           value={ptData.ptmin}
                                                           onChange={(e) => setPtData({...ptData, 'ptmin': e.target.value})}
                                                           type={'number'} required id="standard-required" label={t('PERCEN_MIN')} defaultValue={ptData.ptmin} />}
            <TextField style={{ marginRight: 15 }} type={'number'} required id="standard-required" label={t('PERCEN_MAX')}
                       onChange={(e) => setPtData({...ptData, 'pt': e.target.value})}
                       value={ptData.pt} />
            <Button type="btn" onClick={onSubmit} variant="contained" color="info" style={{
              marginTop: 10
            }}>{t('UPDATE')}</Button>
          </div>
          {ptModal.error
            ? <p style={{ color: 'red' }}>{ptModal.error}</p>
            : null}
        </DialogContent>
      </Dialog>
      <ErroDialog />
    </div>
  )
}

export default Users;
