import React, { Component } from 'react';
import { Button, Card, CardBody, CardFooter, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

import { useDialog, TextInput, useLoading } from 'feature/Component'
import { useTranslation } from 'react-i18next'
import Joi from '@hapi/joi'
import http from 'service/http'
import { useForm } from 'react-final-form-hooks'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'

function Register({ history }) {
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading()
  const [Dialog, showDialogWithMessage] = useDialog({
    title: t('CREATE_PARTNER_SUCCESS')
  })
  const [DialogError, showDialogErrorWithMessage] = useDialog({
    title: t('CREATE_PARTNER_ERROR'),
    type: 'error'
  })

  const onSubmit = async payload => {
    try {
      const submit = {
        ...payload,
        user_level: 'PARTNER'
      }
      await withLoading(() => http.post({ path: `admin/partner`, payload: submit }))
      showDialogWithMessage(t('YOU_REQUEST_SUBMITED_SUCCESS'))
      history.push('/agents')
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }

  const schema = Joi.object().keys({
    // email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } }).required(),
    password: Joi.string().required()
      .regex(/^[a-zA-Z0-9]{3,30}$/),
    fullname: Joi.string().required(),
    username: Joi.string().required(),
    mobile_phone: Joi.string().required(),
  })

  const { form, handleSubmit } = useForm({
    onSubmit,
    validate: validate(schema)
  })

  const [username, password, fullname, mobile_phone] = formCreateInputs(['username', 'password', 'fullname', 'mobile_phone'], form)

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <Card className="mx-4">
              <CardBody className="p-4">
                <h1>Register</h1>
                <p className="text-muted">Create your account</p>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="icon-user"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" placeholder="Username" />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>@</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" placeholder="Email" />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="icon-lock"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input type="password" placeholder="Password" />
                </InputGroup>
                <InputGroup className="mb-4">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="icon-lock"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input type="password" placeholder="Repeat password" />
                </InputGroup>
                <Button color="success" block>Create Account</Button>
              </CardBody>
              <CardFooter className="p-4">
                <Row>
                  <Col xs="12" sm="6">
                    <Button className="btn-facebook" block><span>facebook</span></Button>
                  </Col>
                  <Col xs="12" sm="6">
                    <Button className="btn-twitter" block><span>twitter</span></Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Register;
