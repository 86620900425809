import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import store from 'store';
import {
  AppBreadcrumb,
  AppHeader,
  AppFooter,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// routes config
import routes from '../../routes';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';

import useAudio from 'feature/Component/Sound'
// sidebar nav config
import navigation from '_nav';
import http from 'service/http';
import socket from 'service/socket'
import { useTranslation } from 'react-i18next'

function DefaultLayout(props) {
  // const toggleSound = useAudio(process.env.PUBLIC_URL + '/assets/sounds/biz.pm3');
  const { t } = useTranslation()
  const auth = store.get('token');
  const user = store.get('user');
  // console.log(user)
  if(!auth || !user) return (
    <Redirect from="/" to="/login" />
  )
  const { user_level, permissions = '[]' } = user;
  const pers = permissions ? JSON.parse(permissions) : []
  http.setJwtToken(auth);
  // socket.on('messages', payload => {
  //   toggleSound()
  // })
  const playAudio = () => {
    const vid = document.getElementById('myAudio')
    vid.volume = 0.5
    vid.play()
  }
  socket.on('messages', payload => {
    playAudio()

  })

  function signOut(e) {
    e.preventDefault()
    props.history.push('/login')
  }
  function gotoProfile(e) {
    e.preventDefault()
    props.history.push('/profile')
  }
  return (
    <div className="app">
      <audio controls loop id={'audioWD'}>
        <source src="/assets/audio/iphone-epex-sms.mp3" type="audio/mpeg"/>
      </audio>
      <audio id="myAudio" loop>
        <source src={process.env.PUBLIC_URL + '/assets/sounds/biz.mp3'} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <AppHeader fixed>
          <Suspense>
            <DefaultHeader t={t} onLogout={e=>signOut(e)} gotoProfile={e=>gotoProfile(e)}/>
            {/*<DefaultHeader onLogout={() => props.history.push('/login')}/>*/}
          </Suspense>
        </AppHeader>
      <div className="app-body">
        <AppSidebar fixed display="sx">
          <AppSidebarHeader />
          <AppSidebarForm />
          <AppSidebarNav navConfig={navigation(pers, user_level, t)} {...props} />
          <AppSidebarFooter />
          <AppSidebarMinimizer />
        </AppSidebar>
        <main className="main">
          <AppBreadcrumb appRoutes={routes}/>
          <Container fluid>
            <Switch>
              {routes.map((route, idx) => {
                  return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                      <route.component {...props} {...user} />
                    )} />)
                    : (null);
                },
              )}
            </Switch>
          </Container>
        </main>
      </div>
      <AppFooter>
        <DefaultFooter />
      </AppFooter>
    </div>
  );
}

export default DefaultLayout;
