import React from 'react';
import {
  Badge,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import config from 'config'
import navigation from 'menu';
import { AppNavbarBrand, AppSidebarToggler, AppHeaderDropdown, AppAsideToggler } from '@coreui/react';
import logo from '../../assets/img/logo.png'
import sygnet from '../../assets/img/brand/sygnet.svg'
import store from 'store'

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};
const changeLang = (lang) => {
  store.set('lang', lang)
  window.location.reload()
}
const currentLang = store.get('lang') || 'en'

const styles = {
  myStyleClassName: {
    background: 'none',
    '& button:hover': {
      border: 'none',
    },
  }
};
function DefaultHeader(props) {
  const { t } = props
  const user = store.get('user');
  const { user_level, permissions = '[]' } = user;
  const pers = permissions ? JSON.parse(permissions) : []
  // console.log(navigation(pers, user_level, t))
  return (
    <React.Fragment>
      {/*<AppSidebarToggler className="d-lg-none" display="md" mobile />*/}
      <AppNavbarBrand
        full={{ src: logo, width: 25, alt: 'HB Analytics Logo' }}
        minimized={{ src: sygnet, width: 30, height: 30, alt: 'HB Analytics Logo' }}
        id="logo"
      >
        {/* {config.title} */}
        Kiss Vip Lotto
      </AppNavbarBrand>

      <Nav className="" navbar>

        {navigation(pers, user_level, t).items.map(item => {
            if(!item.children) return <NavItem key={item.url} className="px-2">
                <Link to={item.url} className="nav-link">{item.name}</Link>
              </NavItem>
            return <AppHeaderDropdown direction="down" className="px-2">
              <DropdownToggle nav>
                <NavItem style={{float: 'left', marginRight: 5}}>{item.name}</NavItem><i style={{float: 'right', paddingTop: 5}} className="fa fa-caret-down"></i>
              </DropdownToggle>
              <DropdownMenu>
              {item.children.map(child => (<DropdownItem tag="div" className="text-center">
                      <Link to={child.url} className="nav-link"><i className={child.icon}></i>{child.name}</Link>
                    </DropdownItem>))

              }
              </DropdownMenu>
            </AppHeaderDropdown>
          }
        )}

      </Nav>
      <Nav className="d-md-down" navbar>
        <AppHeaderDropdown direction="down">
          <DropdownToggle nav>
            <img src={'../../assets/img/avatars/6.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com" />
          </DropdownToggle>
          <DropdownMenu right style={{ right: 'auto' }}>
            {/*<DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>*/}
            {/*<DropdownItem><i className="fa fa-bell-o"></i> Updates<Badge color="info">42</Badge></DropdownItem>*/}
            {/*<DropdownItem><i className="fa fa-envelope-o"></i> Messages<Badge color="success">42</Badge></DropdownItem>*/}
            {/*<DropdownItem><i className="fa fa-tasks"></i> Tasks<Badge color="danger">42</Badge></DropdownItem>*/}
            {/*<DropdownItem><i className="fa fa-comments"></i> Comments<Badge color="warning">42</Badge></DropdownItem>*/}
            <DropdownItem header tag="div" className="text-center"><strong>{t('SETTINGS')}</strong></DropdownItem>
            <DropdownItem onClick={e => props.gotoProfile(e)}><i className="fa fa-user"></i> {t('PROFILE')}</DropdownItem>
            {/*<DropdownItem><i className="fa fa-wrench"></i> Settings</DropdownItem>*/}
            {/*<DropdownItem><i className="fa fa-usd"></i> Payments<Badge color="secondary">42</Badge></DropdownItem>*/}
            {/*<DropdownItem><i className="fa fa-file"></i> Projects<Badge color="primary">42</Badge></DropdownItem>*/}
            <DropdownItem divider />
            {/*<DropdownItem><i className="fa fa-shield"></i> Lock Account</DropdownItem>*/}
            <DropdownItem onClick={e => props.onLogout(e)}><i className="fa fa-lock"></i> {t('LOGOUT')}</DropdownItem>
          </DropdownMenu>
        </AppHeaderDropdown>
        <AppHeaderDropdown direction="down">
          <DropdownToggle nav>
            <img src={'../../assets/img/lang/' + currentLang + '.png'} className="img-avatar" />
          </DropdownToggle>
          <DropdownMenu right style={{ right: 'auto', minWidth:'50px', width: '50px', textAlign: 'center' }}>
            <DropdownItem divider />
            <img onClick={() => changeLang('en')} style={{cursor: 'pointer', width: '35px'}} src={'../../assets/img/lang/en.png'} className="img-avatar" />
            <DropdownItem divider />
            <img onClick={() => changeLang('idn')} style={{cursor: 'pointer', width: '35px'}} src={'../../assets/img/lang/idn.png'} className="img-avatar" />
            <DropdownItem divider />
            <img onClick={() => changeLang('cn')} style={{cursor: 'pointer', width: '35px'}} src={'../../assets/img/lang/cn.png'} className="img-avatar" />
            <DropdownItem divider />
            <img onClick={() => changeLang('ma')} style={{cursor: 'pointer', width: '35px'}} src={'../../assets/img/lang/ma.png'} className="img-avatar" />
            <DropdownItem divider />
            <img onClick={() => changeLang('my')} style={{cursor: 'pointer', width: '35px'}} src={'../../assets/img/lang/my.png'} className="img-avatar" />
            <DropdownItem divider />
            <img onClick={() => changeLang('th')} style={{cursor: 'pointer', width: '35px'}} src={'../../assets/img/lang/th.png'} className="img-avatar" />
            <DropdownItem divider />
          </DropdownMenu>
        </AppHeaderDropdown>
        <AppAsideToggler className="d-md-down-none" />
      </Nav>

      {/*<AppAsideToggler className="d-lg-none" mobile />*/}
    </React.Fragment>
  )
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;
export default DefaultHeader;
