import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, Col, Row, Table, Button } from 'reactstrap'
import http from 'service/http'
import { useForm } from 'react-final-form-hooks'
import { TextInput, useLoading, SelectInput } from 'feature/Component'
import formCreateInputs from 'service/form/create'
import numeral from 'numeral'
import store from 'store'
import './style.scss'
import { FormControl } from '@material-ui/core'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import LoadingUi from 'feature/Component/Loading/ui'
const DEFAULT_FROM_DATE = moment().format('YYYY-MM-DD')
const DEFAULT_TO_DATE = moment().format('YYYY-MM-DD')
let user = store.get('user') || { level: '' }

const correctNumber = amount => numeral(amount).format('0,0.000')

function HistoryRow({ account, date, banker }) {
  // console.log(account.winlose.parentTotal, account.winlose.uplineWinlose)
  const downlineReciever = account.role == 'PLAYER' ? 0 : account.winlose.uplineWinlose * -1
  const parentTotal = account.winlose.parentTotal
  const uplineWinlose = account.role == 'PLAYER' ? account.winlose.parentTotal * -1 : (account.winlose.uplineWinlose + parentTotal) * -1
  return (
    <tr>
      <td style={{textAlign: 'left'}}>
        <a href={account.role == 'AGENT' ? '/report-payable/agent/' + account.username + '?fromDate=' + date.fromDate + '&toDate=' + date.toDate + '&banker=' + account.lotto97Banker :
          (account.role == 'PLAYER' ? '/report-payable/player/' + account.username + '?fromDate=' + date.fromDate + '&toDate=' + date.toDate + '&banker=' + banker :
          '/report-payable/partner/' + account.username + '?fromDate=' + date.fromDate + '&toDate=' + date.toDate + '&banker=' + account.lotto97Banker)}>
          <b>{account.username}</b>
        </a>
      </td>
      <td className="downline">{correctNumber(account.winlose.totalTurnover)}</td>
      <td className="downline" style={{
        color: account.winlose.playerComm >= 0 ? 'green' : 'red'
      }}>{correctNumber(account.winlose.playerComm)}</td>
      <td className="downline" style={{
        color: account.winlose.playerWinlose >= 0 ? 'green' : 'red'
      }}>{correctNumber(account.winlose.playerWinlose)}</td>
      <td className="downline" style={{
        color: account.winlose.playerTotal >= 0 ? 'green' : 'red'
      }}>{correctNumber(account.winlose.playerTotal)}</td>
      <td className="receivable" style={{
        color: downlineReciever >= 0 ? 'green' : 'red'
      }}>{correctNumber(downlineReciever)}</td>

      <td className="upline" style={{
        color: account.winlose.parentSale >= 0 ? 'green' : 'red'
      }}>{correctNumber(account.winlose.parentSale)}</td>
      <td className="upline" style={{
        color: account.winlose.earnComm >= 0 ? 'green' : 'red'
      }}>{correctNumber(account.winlose.earnComm)}</td>
      <td className="upline" style={{
        color: account.winlose.payComm >= 0 ? 'green' : 'red'}}>
        {correctNumber(account.winlose.payComm)}
      </td>
      <td className="upline" style={{
        color: account.winlose.parentWinlose >= 0 ? 'green' : 'red'
      }}>{correctNumber(account.winlose.parentWinlose)}</td>
      <td className="upline" style={{
        color: parentTotal >= 0 ? 'green' : 'red'
      }}>{correctNumber(parentTotal)}</td>

      <td className="company" style={{
        color: uplineWinlose >= 0 ? 'green' : 'red'
      }}>{correctNumber(uplineWinlose)}</td>
    </tr>
  )
}

function History(props) {
  const { t } = useTranslation()
  let total1 = {
    downlineReciever: 0,
    uplineWinlose: 0,
    playerWinlose: 0,
    playerComm: 0,
    playerTotal: 0,
    turnover: 0,
    earnComm: 0,
    payComm: 0,
    downline: {
      sale: 0,
      comm: 0,
      winlose: 0,
      total: 0,
    },
    partner: {
      sale: 0,
      comm: 0,
      winlose: 0,
      total: 0,
    },
    companyTotal: 0,
    totalNet: 0,
  }
  user = props
  const query = new URLSearchParams(props.location.search);
  const [loading, withLoading] = useLoading()
  const [ accounts, updateAccounts] = useState([])
  const [ total, updateTotal] = useState(total1)
  const { lotto97Banker } = store.get('user')
  const [initialInputs, updateInitialInputs] = useState({})
  const onSubmit = async (params) => {
    // console.log(params)
    loadReposrt({...params, banker: query.get('banker')})

  }
  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: initialInputs
  })
  const [fromDate, toDate] = formCreateInputs(['fromDate', 'toDate'], form)

  useEffect(() => {

    loadReposrt({
      banker: query.get('banker'),
      fromDate: query.get('fromDate'),
      toDate: query.get('toDate')
    })
  }, [])

  const updateTotalWL = (arr) => {
    arr.map(rs => {
      // console.log(rs)
      const downlineReciever = rs.role == 'PLAYER' ? 0 : rs.winlose.uplineWinlose * -1
      const parentTotal = rs.winlose.parentTotal
      const uplineWinlose = rs.role == 'PLAYER' ? rs.winlose.parentTotal * -1 : (rs.winlose.uplineWinlose + parentTotal) * -1

      total1 = {
        uplineWinlose: total1.uplineWinlose + uplineWinlose,
        downlineReciever: total1.downlineReciever + downlineReciever,
        playerWinlose: total1.playerWinlose + rs.winlose.playerWinlose,
        playerTotal: total1.playerTotal + rs.winlose.playerTotal,
        playerComm: total1.playerComm + rs.winlose.playerComm,
        turnover: total1.turnover + rs.winlose.totalTurnover,
        earnComm: total1.earnComm + rs.winlose.earnComm,
        payComm: total1.payComm + rs.winlose.payComm,
        downline: {
          sale: total1.downline.sale + rs.winlose.downlineSale,
          comm: total1.downline.comm + rs.winlose.totalComm,
          winlose: total1.downline.winlose + rs.winlose.downlineWinlose,
          total: total1.downline.total + rs.winlose.total,
        },
        partner: {
          sale: total1.partner.sale + rs.winlose.parentSale,
          comm: total1.partner.comm + rs.winlose.totalParentComm,
          winlose: total1.partner.winlose + rs.winlose.parentWinlose,
          total: total1.partner.total + parentTotal,
        },
        companyTotal: total1.companyTotal + rs.winlose.companyTotal,
        totalNet: 0,
      }
      updateTotal(total1)
    })
  }
  const getWLByCurrentAcc = (acc, date) => {
    updateInitialInputs({
      fromDate: date.fromDate,
      toDate: date.toDate,
    })
    const playerquery = {
      path: `admin/report/agent/` + props.match.params.id,
      params: {...date}}
    http.get(playerquery).then(players => {
      // console.log(players)
      const newacc = players.winlose.map(player => {
        return {
          lotto97Banker: '',
          role: 'PLAYER',
          username: player._id.username,
          winlose: {
            uplineWinlose: player.uplineWinlose,
            playerWinlose: player.winlose,
            playerComm: player.totalComm,
            playerTotal: player.total,
            companyTotal: player.companyTotal,
            downlineSale: player.totalTurnover,
            downlineWinlose: player.totalWinLose,
            parentSale: player.agentSale,
            parentTotal: player.agentTotal,
            total: player.total,
            parentWinlose: player.agentWinlose,
            totalBetAmount: player.totalBetAmount,
            totalComm: player.totalComm,
            totalParentComm: player.totalAgentComm,
            totalTurnover: player.totalTurnover,
            earnComm: player.earnComm || 0,
            payComm: player.payComm || 0,
            totalWinLose: player.totalWinLose,
          }
        }
      })
      acc = acc.concat(newacc)
      updateAccounts(acc)
      updateTotalWL(newacc)
    })
  }
  const loadReposrt = (date) => {
    http.get({ path: `admin/partner/` + props.match.params.id + '/agents'}).then(acc => {
      updateAccounts(acc);
      // console.log(1, accounts)
      const newAcc = [...acc]
      if(acc.length > 0) withLoading(() => Promise.all(acc.map( (a, i) => {
        // console.log(a)
        const query = a.role == 'AGENT' ? {
          path: `admin/report/partner/` + props.match.params.id,
          params: {
            agent: a.username,
            fromDate: date.fromDate,
            toDate: date.toDate,
          }
        } : {
          path: `admin/report/partner/` + a.username,
          params: {
            fromDate: date.fromDate,
            toDate: date.toDate,
          }
        }
        http.get(query).then(rs => {
          newAcc[i].winlose = rs.winlose
          updateTotalWL([rs])

          // }
        })
        if(i == acc.length - 1) {
          getWLByCurrentAcc(acc, date)

        }
      })))
      else getWLByCurrentAcc(acc, date)
    })
  }

  return (
    <div className="animated fadeIn">
      {loading && <LoadingUi/>}
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <form onSubmit={handleSubmit}>
                <TextInput style={{ marginLeft: 15, width: 200 }} input={fromDate} label={t('FROM')} type='date'
                           InputLabelProps={{
                             shrink: true
                           }}/>
                <TextInput style={{ marginLeft: 15, width: 200 }} input={toDate} label={t('TO')} type='date'
                           InputLabelProps={{
                             shrink: true
                           }}/>
                <FormControl style={{ marginTop: 30, marginLeft: 15 }}>
                  {loading ? t('LOADING') + ' ...' : <Button type='submit' color="info" className="px-4">{t('SEARCH')}</Button>}
                </FormControl>
              </form>
            </CardHeader>
            <CardBody>

              <React.Fragment>
                <Table responsive hover className="fullReportTable">
                  <thead>

                  <tr style={{ background: 'rgb(5 48 130)', color: 'white' }}>

                    <th scope="col" rowSpan="2" className="rowSpan">{t('DOWNLINE_ACCOUNT')}</th>
                    <th scope="col" rowSpan="2" className="rowSpan">{t('DOWNLINE_TURNOVER')}</th>
                    <th scope="col" rowSpan="2" className="rowSpan">{t('PLAYER_COMM')}</th>
                    <th scope="col" rowSpan="2" className="rowSpan">{t('PLAYER_WINLOSE')}</th>
                    <th scope="col" rowSpan="2" className="rowSpan">{t('PLAYER_TOTAL')}</th>
                    <th scope="col" rowSpan="2" className="rowSpan">{t('DOWNLINE_RECEIIVABLE')}</th>
                    <th scope="col" colSpan="5" className="colSpan">{t('PARTNER')} ({props.match.params.id})</th>
                    <th rowSpan="2" className="rowSpan">{t('UPLINE_PAYABLE')}</th>
                  </tr>
                  <tr>
                    <th>{t('SALE')}</th>
                    <th>{t('EARN_COMM')}</th>
                    <th>{t('PAY_COMM')}</th>
                    <th>{t('WINLOSE')}</th>
                    <th>{t('TOTAL')}</th>
                  </tr>
                  </thead>
                  {accounts.length || total.turnover > 0?
                    <tbody>
                    {accounts.map((account, i) =>
                      account.winlose.totalTurnover > 0 && <HistoryRow account={account} date={initialInputs} index={i++} key={i} banker={lotto97Banker}/>
                    )}
                    <tr style={{ fontWeight: 'bold', background: '#8f9ba68a' }}>
                      <td>Total</td>
                      <td>{correctNumber(total.turnover)}</td>

                      <td style={{
                        color: total.playerComm >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.playerComm)}</td>
                      <td style={{
                        color: total.playerWinlose >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.playerWinlose)}</td>
                      <td style={{
                        color: total.playerTotal >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.playerTotal)}</td>
                      <td style={{
                        color: total.downlineReciever >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.downlineReciever)}</td>
                      <td style={{
                        color: total.partner.sale >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.partner.sale)}</td>
                      <td style={{
                        color: total.earnComm >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.earnComm)}</td>
                      <td style={{
                        color: total.payComm >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.payComm)}</td>
                      <td style={{
                        color: total.partner.winlose >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.partner.winlose)}</td>
                      <td style={{
                        color: total.partner.total >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.partner.total)}</td>
                      <td style={{
                        color: total.uplineWinlose >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.uplineWinlose)}</td>
                    </tr>

                    </tbody>
                    : <tbody>
                    <tr>
                      <td>{t('NO_DATA')}</td>
                    </tr>
                    </tbody>
                  }
                </Table>
              </React.Fragment>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </div>
  )
}

export default History