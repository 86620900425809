import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardGroup, Col, Container, InputGroup, Row, CardHeader, TabContent, TabPane, Nav, NavItem, NavLink, CardTitle, CardText,
  Form, FormGroup, Label, Input} from 'reactstrap'
import classnames from 'classnames';
import moment from 'moment';
import http from 'service/http'
import { useTranslation } from 'react-i18next'
import './style.css'
import SlotGame from './slotGame'
import LoadingUi from 'feature/Component/Loading/ui'
import { useDialog, useLoading } from 'feature/Component'

function EditUser(props) {
  const { t } = useTranslation()
  const {
    match: {
      params: { id }
    }
  } = props
  const [user, setUser] = React.useState({
    user: null,
    parent: null
  } )
  const [games, setGames] = React.useState([] )
  const [promotions, setPromotions] = useState([])
  const [loading, withLoading] = useLoading()
  const initialAsyncForm = async () => {
    const [gamess, rs, data] = await withLoading(() => Promise.all([
      http.get({ path: `games` }).then(rs => rs.filter(x => x.isActive == 1)),
      http.get({ path: `admin/users/${id}` }),
      http.get({path: `admin/promotion-new`})
    ]))
    setUser(rs)
    setGames(gamess)
    setPromotions(data.filter(x => x.active == 1))
  }
  useEffect(() => {
    initialAsyncForm()
  }, [])
  return user.user && games.length > 0 ? <FormAsync promotions={promotions} t={t} {...props} promotion={user.promotion} user={user.user} currentBonus={user.currentBonus} parent={user.parent} games={games} loadingp={loading} /> : t('LOADING') + ' ...'
}
const groups = [{
  name: 'Slot Game',
  items: ['918kiss', 'mega', 'evo888', 'ace333', 'spadegaming', 'joker', 'live22', 'xe88', 'play8oy']
},{
  name: 'casino',
  items: ['evolution', 'playtech', 'asiagaming']
},{
  name: 'sportsbook',
  items: ['m8', 'sbo', 'cmd']
},{
  name: 'lottery',
  items: ['lottery', 'lottery_thai']
}]
const bankOnline =  [
  {
    value: "BKKB",
    title: "Bangkok Bank"
  },
  {
    value: "BAAC",
    title: "Bank for Agriculture and Agricultural Cooperatives"
  },
  {
    value: "KSAB",
    title: "Bank of Ayudhya (Krungsri)"
  },
  {
    value: "CIMT",
    title: "CIMB Thai Bank"
  },
  {
    value: "GSBA",
    title: "Government Savings Bank"
  },
  {
    value: "KSKB",
    title: "Kasikorn Bank"
  },
  {
    value: "Kiatnakin Bank",
    title: "Kiatnakin Bank"
  },
  {
    value: "KTBA",
    title: "Krung Thai Bank"
  },
  {
    value: "SCBB",
    title: "Siam Commercial Bank"
  },
  {
    value: "SCBT",
    title: "Standard Chartered Bank (Thai)"
  }
]
function FormAsync({
                     user_level,
                     promotions,
                     currentBonus,
  commissions, parent, promotion,
                     games,
                     loadingp,
                     user, match: {
    params: { id }
  }, t
                   }) {
  const [tab, setTab] = React.useState({ activeTab: '1' })
  const [ptError, setPtError] = React.useState('')
  const bank = bankOnline.find((x => x.title == user.bankName))
  const [userUpdate, setUserInfo] = React.useState({
    bankName: user.bankName != '' ? user.bankName : undefined,
    bankCode: bank ? bank.value : undefined,
    bankAccount: user.bankAccount != '' && user.bankAccount != null ? user.bankAccount : undefined,
    bankAccountNumber: user.bankAccountNumber != '' && user.bankAccountNumber != null ? user.bankAccountNumber : undefined,
    pt: user.pt,
  })
  const [depositAmount, setDepositAmount] = React.useState(user.user_money ? Number(user.user_money).toFixed(2) : "0.00")
  const [loading, withLoading] = useLoading(loadingp)
  const [Dialog, showDialogWithMessage] = useDialog({
    title: t('SUCCESS')
  })
  const [DialogError, showDialogErrorWithMessage] = useDialog({
    title: t('ERROR'),
    type: 'error'
  })
  const toggle = (newTab) => {
    if (tab.activeTab != newTab) {
      setTab({
        activeTab: newTab
      });
    }
  }
  // console.log(userUpdate)
  const updatePt = (percent) => {
    percent = Number(percent)
    // setPt(percent)
    if(percent < 0 || percent > parent.pt) setPtError('minimum 0 and maximum ' + parent.pt)
    else {
      setPtError('')

      const newPT = games.map(g => {
        return {
          game_code: g.game_code,
          label: g.label ? g.label : g.name,
          pt: percent,
        }
      })
      setUserInfo({ ...userUpdate, pt: percent })
      // console.log(newPT)
    }
  }
  const save = async () => {
    try {
      const payload = {...userUpdate}
      const e = document.getElementById('password')
      if(e.value !='') payload.password = e.value
      await withLoading(() => http.put({ path: `admin/users/${user.user_id}`, payload }))
      showDialogWithMessage(t('YOU_REQUEST_SUBMITED_SUCCESS'))
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }
  const deposit = async () => {
    const amount = Number(depositAmount)
    if(amount <= 0) showDialogErrorWithMessage('Invalid amount')
    await withLoading(() => http.post({ path: `admin/deposits`, payload: {
        user_id: user.user_id,
        username: user.user_account_no,
        amount: amount,
      } }).then(() => {

      return http.get({ path: `admin/users/${id}` }).then(rs => setDepositAmount(rs.user.user_money ? Number(rs.user.user_money).toFixed(2) : "0.00"))
    }).catch(err => {
      // console.log(err)
      showDialogWithMessage(err.message)
    }))
  }
  return (
    <div className="animated fadeIn">
      {loading && <LoadingUi/>}
      <Row>
          <Col lg="12">
            <Card>
              <CardHeader>
                Edit member <span style={{color: 'red'}}>{user.username || user.user_account_no}</span>
              </CardHeader>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: tab.activeTab === '1' })}
                    onClick={() => { toggle(('1')) }}
                  >
                    Information
                  </NavLink>
                </NavItem>
                {groups.map((g, i) => (
                  <NavItem key={g.name}>
                    <NavLink
                      className={classnames({ active: tab.activeTab === (i+2).toString() })}
                      onClick={() => { toggle((i+2).toString()) }}
                    >
                      {g.name}
                    </NavLink>
                  </NavItem>
                ))}

              </Nav>
              <TabContent activeTab={tab.activeTab}>
                <TabPane tabId="1">
                  <Form className="form">
                    <FormGroup row>
                      <Col sm={6}>
                        <FormGroup row>
                          <Label for="username" sm={3} size="lg">Account</Label>
                          <Col sm={8}>
                            <span size="md">{user.username || user.user_account_no}</span>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup row>
                          <Label for="password" sm={3} size="lg">Password</Label>
                          <Col sm={8}>
                            <Input type="password" name="password" id="password" placeholder="Leave blank if no changes to password" bsSize="md" />
                          </Col>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={6}>
                        <FormGroup row>
                          <Label for="name" sm={3} size="lg">Name</Label>
                          <Col sm={8}>
                            <span size="md">{user.fullname}</span>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup row>
                          <Label for="contact" sm={3} size="lg">Contact</Label>
                          <Col sm={8}>
                            <Input value={user.mobile_phone} type="text" name="contact" id="contact" placeholder="lg" bsSize="md" />
                          </Col>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={6}>
                        <FormGroup row>
                          <Label for="name" sm={3} size="lg">Member Type</Label>
                          <Col sm={8}>
                            <span>Cash</span>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup row>
                          <Label for="status" sm={3} size="lg">Status</Label>
                          <Col sm={8}>
                            <FormGroup check>
                              <Label check>
                                <Input checked={user.user_inactive==0} type="radio" name="status" />Active
                              </Label>
                              <Label check>
                                <Input type="radio" name="status" />Suspend
                              </Label>
                              <Label check>
                                <Input type="radio" name="status" />Block
                              </Label>
                            </FormGroup>
                          </Col>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={6}>
                        <FormGroup row>
                          <Label for="name" sm={3} size="lg">Currency</Label>
                          <Col sm={8}>
                            <span>{user.user_currency}</span>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        {user.user_level == 'PLAYER' && <FormGroup row>
                          <Label for="status" sm={3} size="lg">Promotions Packages</Label>
                          <Col sm={8}>
                            <Input type="select" name="select" id="exampleSelect" value={userUpdate.promotionId} onChange={(e) => setUserInfo({...userUpdate, promotionId: e.target.value})}>
                              <option value={-1}>Reset</option>
                              {promotions.map(p => <option value={p.id} key={p.id} selected={currentBonus == p.id}>{p.appear.title}</option>)}
                            </Input>
                          </Col>
                        </FormGroup>}
                        {user.user_level !== 'PLAYER' && <FormGroup row>
                          <Label for="status" sm={3} size="lg">PT</Label>
                          <Col sm={8}>
                            <Input value={userUpdate.pt} onChange={(e) => updatePt(e.target.value)} type="number" min={0} max={parent.pt} name="contact" id="contact" placeholder="pt" bsSize="md" />
                            <span style={{color: 'red'}}>{ptError}</span>
                          </Col>
                        </FormGroup>}
                      </Col>
                    </FormGroup>
                    {user.user_level == 'PLAYER' && <FormGroup row>
                      <Col sm={6}>
                        <FormGroup row>
                          <Label for="cashbalance" sm={3} size="lg">Cash Balance</Label>
                          <Col sm={8}>
                              <Input type="number" onChange={(e) => setDepositAmount(e.target.value)} value={depositAmount} name="cashbalance" id="cashbalance" bsSize="md" />
                            <Button onClick={deposit} style={{float: 'right'}} color="danger">Deposit</Button>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup row>
                          <Label for="status" sm={3} size="lg">Progress/Target</Label>
                          <Col sm={8}>
                            <div className="progress" style={{margin: '5px 0'}}>
                              <div className="progress-bar progress-bar-striped bg-success" role="progressbar"
                                 style={{width: promotion.current / promotion.target + '%'}}  aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                              Target: {promotion.type} ({promotion.current} / {promotion.target})
                            </div>
                            <div className="progress">
                              <div className="progress-bar progress-bar-striped bg-success" role="progressbar"
                                   style={{width: promotion.currentTurnover / promotion.targetTurnover + '%'}}  aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                              Target: Turnover ({promotion.currentTurnover} / {promotion.currentTurnover})
                            </div>
                          </Col>
                        </FormGroup>
                      </Col>
                    </FormGroup>}
                    {user.user_level == 'PLAYER' && user_level == 'ADMIN' && <FormGroup row>
                      <Col sm={6}>
                        <FormGroup row>

                          <Label for="username" sm={3} size="lg">Bank Name</Label>
                          <Col sm={8}>
                            <Input type="select" name="select" id="exampleSelect" value={userUpdate.bankName} onChange={(e) => {
                              const bankSelect = bankOnline.find(x => x.title == e.target.value)
                              setUserInfo({...userUpdate, bankName: e.target.value, bankCode: bankSelect.value})
                            }}>
                              <option value='0'></option>
                              {bankOnline.map(b => <option value={b.title} key={b.title}>{b.title}</option>)}
                            </Input>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup row>
                          <Label for="bankaccount" sm={3} size="lg">Bank Account Name</Label>
                          <Col sm={8}>
                            <Input type="text" value={userUpdate.bankAccount} name="bankaccount" id="bankaccount" bsSize="md" placeholder="bank account" onChange={(e) => {
                              setUserInfo({...userUpdate, bankAccount: e.target.value.replace(/[^\u0E00-\u0E7Fa-zA-Z]+/, '')})
                            }} />
                          </Col>
                        </FormGroup>
                      </Col>
                    </FormGroup>}
                    {user.user_level == 'PLAYER' && user_level == 'ADMIN' && <FormGroup row>
                      <Col sm={6}>
                        <FormGroup row>
                          <Label for="username" sm={3} size="lg">Bank Account Number</Label>
                          <Col sm={8}>
                            <Input type="number" value={userUpdate.bankAccountNumber} name="username" id="username" placeholder="Bank account number" bsSize="md" onChange={(e) => {
                              if(Number(e.target.value) >= 0) setUserInfo({...userUpdate, bankAccountNumber: e.target.value})
                            }} />
                          </Col>
                        </FormGroup>
                      </Col>
                    </FormGroup>}
                    {user.user_level == 'PLAYER' && <FormGroup row>
                      <Col sm={12}>
                          <FormGroup row>
                            <Label for="remake" style={{marginRight: 65}} sm={1} size="lg">Remakes</Label>
                            <Col sm={10}>
                              <Input type="textarea" name="username" id="username" placeholder="Remake" bsSize="md" />
                            </Col>
                          </FormGroup>
                      </Col>
                    </FormGroup>}
                    <FormGroup row>
                      <Col sm={12} style={{textAlign: 'center', padding: 20}}>
                        <Button disabled={loading || ptError != ''} onClick={save} color="success">Save</Button>
                      </Col>
                    </FormGroup>
                  </Form>
                  <CardHeader>
                    <Form className="form">
                      <FormGroup row>
                        <Col sm={6}>
                          <FormGroup row>
                            <Label for="username" sm={3} size="lg">Date/Time</Label>
                            <Col sm={8}>
                              <span>{moment(user.created_at).format('DD/MM/YYYY HH:mm')}</span>
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm={6}></Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col sm={6}>
                          <FormGroup row>
                            <Label for="username" sm={3} size="lg">Last IP</Label>
                            <Col sm={8}>

                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm={6}></Col>
                      </FormGroup>
                    </Form>
                  </CardHeader>
                </TabPane>
                <TabPane tabId="2">
                  <SlotGame gamess={games} games={groups[0]} gameType={'Slot'} user={user} parentpt={parent.pt} commissions={commissions} user_level={user_level}/>
                </TabPane>
                <TabPane tabId="3">
                  <SlotGame gamess={games} games={groups[0]} gameType={'Casino'} user={user} parentpt={parent.pt} commissions={commissions} user_level={user_level}/>
                </TabPane>
                <TabPane tabId="4">
                  <SlotGame gamess={games} games={groups[0]} gameType={'Sportsbook'} user={user} parentpt={parent.pt} commissions={commissions} user_level={user_level}/>
                </TabPane>
                <TabPane tabId="5">
                  <SlotGame gamess={games} games={groups[0]} gameType={'Lottery'} user={user} parentpt={parent.pt} commissions={commissions} user_level={user_level}/>
                </TabPane>
              </TabContent>

            </Card>
          </Col>
        </Row>
      <Dialog/>
      <DialogError/>
    </div>
  )
}

export default EditUser
