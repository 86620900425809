import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, Col, Row, Table, Button, Modal, Input,
  ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import http from 'service/http'
import { useForm } from 'react-final-form-hooks'
import { TextInput, useLoading, SelectInput } from 'feature/Component'
import { FormControl } from '@material-ui/core'
import formCreateInputs from 'service/form/create'
import numeral from 'numeral'
import store from 'store'
import './style.scss'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import LoadingUi from 'feature/Component/Loading/ui'

const DEFAULT_FROM_DATE = moment().startOf('month').format('YYYY-MM-DD')
const DEFAULT_TO_DATE = moment().format('YYYY-MM-DD')
let user = store.get('user') || { level: '' }

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function History(props) {
  const { t } = useTranslation()
  user = props
  const query = new URLSearchParams(props.location.search);
  const [loading, withLoading] = useLoading()
  const [ expenses, setExpenses] = useState([])
  const [initialInputs, updateInitialInputs] = useState({})
  const [modal, setModel] = useState(false)
  const [canAdd, setCanAdd] = useState(true)
  const [exp, setExp] = React.useState({
    amount: ''
  });

  const toggle = () => {
    setModel(!modal)
  }
  const onSubmit = async (params) => {
    const wl = await Promise.all(expenses.map(async g => {
      const winlose = await fetchExpenses(params, g.game_code)
      return {...g, winlose: winlose.totals}
    }))
    setExpenses(wl)

  }
  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: initialInputs
  })
  const [fromDate, toDate] = formCreateInputs(['fromDate', 'toDate'], form)

  const saveExp = () => {

    if(exp.amount != '' && !isNaN(Number(exp.amount)) && exp.amount >=0) {
      withLoading(() => http.post({
        path: `admin/expenses`, payload: exp
      }).then(fetchExpenses({
        fromDate: fromDate.input.value != '' ? fromDate.input.value : DEFAULT_FROM_DATE,
        toDate: toDate.input.value != '' ? toDate.input.value : DEFAULT_TO_DATE,
      })))
      toggle()
      setExp({amount: ''})
    }

  }
  const updateExp = async () => {

    if(exp.amount != '' && !isNaN(Number(exp.amount)) && exp.amount >=0) {
      await withLoading(() => http.put({
        path: `admin/expenses`, payload: exp
      }))
      toggle()
      setExp({amount: ''})
      fetchExpenses({
        fromDate: fromDate.input.value != '' ? fromDate.input.value : DEFAULT_FROM_DATE,
        toDate: toDate.input.value != '' ? toDate.input.value : DEFAULT_TO_DATE,
      })
    }
  }
  useEffect(() => {
    // console.log(moment('2022-08-23T16:00:00.000Z').add(7, 'h').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
    withLoading(() => fetchExpenses({
      fromDate: query.get('fromDate') || DEFAULT_FROM_DATE,
      toDate: query.get('toDate') || DEFAULT_TO_DATE,
    })).then(rs => {
      rs.map(r => {
        if(moment(r.date).add(7, 'h').format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) setCanAdd(false)
      })
    })
  }, [])
  const fetchExpenses = (date) => {
    return http.get({ path: `admin/expenses`, params: {
        ...date
      }
    }).then(rs => {
      setExpenses(rs)
      return rs
    })
  }

  return (
    <div className="animated fadeIn">
      {loading && <LoadingUi/>}
      <Row>
        <Col xl={12}>
          <Card>

            <CardHeader>
              <form onSubmit={handleSubmit}>
                <TextInput style={{ marginLeft: 15, width: 200 }} input={fromDate} label={t('FROM')} type='date'
                           InputLabelProps={{
                             shrink: true
                           }}/>
                <TextInput style={{ marginLeft: 15, width: 200 }} input={toDate} label={t('TO')} type='date'
                           InputLabelProps={{
                             shrink: true
                           }}/>
                <FormControl style={{ marginTop: 30, marginLeft: 15, width: 200, display: 'inline-block' }}>
                  {loading ? t('LOADING') + ' ...' : <>
                  <Button style={{width: '47%', float: 'left'}} type='submit' color="info" className="px-4">{t('SEARCH')}</Button>
                  <Button style={{width: '47%', float: 'right'}} disabled={!canAdd} onClick={() => toggle()} color="success">{t('ADD')}</Button>
                      <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle}>Add Expenses</ModalHeader>
                        <ModalBody>
                          <Input value={exp.amount} onChange={(e) => setExp({amount: e.target.value})} placeholder="Amount" />
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" onClick={saveExp}>Save</Button>{' '}
                          <Button color="secondary" onClick={toggle}>Cancel</Button>
                        </ModalFooter>
                      </Modal>
                  </>}
                </FormControl>
              </form>
            </CardHeader>
            <CardBody>

              <React.Fragment>
                <Table responsive hover className="fullReportTable" bordered>
                  <thead>

                  <tr style={{ background: 'rgb(5 48 130)', color: 'white' }}>

                    <th scope="col">{t('DATE')}</th>
                    <th scope="col">{t('AMOUNT')}</th>
                    <th scope="col">{t('CREATED_BY')}</th>
                    <th scope="col">{t('UPDATED_BY')}</th>
                    <th scope="col">{t('UPDATED_AT')}</th>
                    <th scope="col">{t('ACTIVE')}</th>
                  </tr>

                  </thead>
                  <tbody>
                  {expenses.map(g => {
                    return (<tr key={g.created_at} style={{ fontWeight: 'bold', background: '#8f9ba68a' }}>
                      <td>{moment(g.date).add(7, 'h').format('YYYY-MM-DD')}</td>
                      <td>{g.amount}</td>
                      <td>{g.created_by}</td>
                      <td>{g.updated_by}</td>
                      <td>{g.updated_at != '' && moment(g.updated_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                      <td>{
                        moment(g.date).add(7, 'h').format('YYYY-MM-DD') == moment().format('YYYY-MM-DD') &&
                        <>
                          <Button color={'success'} onClick={() => toggle()} size="small" >Update</Button>
                            <Modal isOpen={modal} toggle={toggle}>
                            <ModalHeader toggle={toggle}>Update Expenses</ModalHeader>
                            <ModalBody>
                            <Input value={exp.amount} onChange={(e) => setExp({amount: e.target.value})} placeholder="Amount" />
                            </ModalBody>
                            <ModalFooter>
                            <Button color="primary" onClick={updateExp}>Save</Button>{' '}
                            <Button color="secondary" onClick={toggle}>Cancel</Button>
                            </ModalFooter>
                          </Modal>
                        </>
                      }</td>
                    </tr>)
                  })}
                  </tbody>
                </Table>
              </React.Fragment>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </div>
  )
}

export default History