import React, { useEffect, useState } from "react";
import Joi from "@hapi/joi";
import validate from "service/form/validation";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Button,
} from "reactstrap";
import Pagination from "react-js-pagination";
import http from "service/http";
import { useForm } from "react-final-form-hooks";
import formCreateInputs from "service/form/create";
import moment from "moment";
import { isEmpty } from "ramda";
import queryString from "query-string";
import {
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import ReactJson from "react-json-view";
import numeral from "numeral";
import store from "store";
import { TextInput, useLoading, SelectInput } from "feature/Component";
import { useTranslation } from "react-i18next";

const correctNumber = (amount) => numeral(amount).format("0,0.000");

const DEFAULT_FROM_DATE = moment().startOf("week").format("YYYY-MM-DD");
const DEFAULT_TO_DATE = moment().format("YYYY-MM-DD");

function sumObjectsByKey(...objs) {
  return objs.reduce((a, b) => {
    for (let k in b) {
      if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k];
    }
    return a;
  }, {});
}

const selectColor = (value) => {
  if (value > 0) {
    return "#14805E";
  }
  if (value < 0) {
    return "red";
  }
  return "inherit";
};
function HistoryRow({ history, game, index, showDetails }) {
  const {
    market,
    betid,
    uuid,
    agent,
    username,
    winlose,
    id,
    orderId,
    rowid,
    bet_id,
    transactionId,
    status,
    time,
    created,
    betAmount,
    winAmount,
    jackpotModule,
    RealTime,
    orderTimeBj,
    WinLoseDate8,
    CreateTime,
    jumlah,
    bayar,
    result,
    platformTxId,
    winlossstatus,
    Result,
    date,
  } = history;
  const _win = game.input.value === "Toto588" ? jumlah : winAmount;
  console.log("🚀 ~ HistoryRow ~ _win:", _win);
  const _lose = game.input.value === "Toto588" ? bayar * -1 : winlose;
  const _winLose =
    game.input.value === "Toto588"
      ? status === "menang"
        ? _win
        : _lose
      : winlose;
  const _status =
    game.input.value === "Toto588"
      ? status === ""
        ? "pending"
        : status === "menang"
        ? "menang"
        : "kalah"
      : status;
  console.log("🚀 ~ HistoryRow ~ _status:", _status);
  return (
    <tr key={index} onClick={() => showDetails(history)}>
      <th scope="row">{index + 1}</th>
      <td>{agent}</td>

      <td>{username}</td>
      <td>
        {uuid ? uuid : id}
        {betid}
        {bet_id}
        {rowid}
        {transactionId}
        {orderId}
        {platformTxId}
      </td>
      <td>{betAmount}</td>
      {_status === "menang" ? (
        <td style={{ color: selectColor(0) }}>0</td>
      ) : (
        <td style={{ color: selectColor(_lose) }}>{correctNumber(_lose)}</td>
      )}
      {_status !== "" && _status !== "menang" ? (
        <td style={{ color: selectColor(_win) }}>{correctNumber(_win)}</td>
      ) : (
        <td style={{ color: selectColor(_win) }}>{correctNumber(_win)}</td>
      )}
      {/* <td>{winAmount ? correctNumber(winAmount) : ''}{Win ? correctNumber(Win) : ''}{win}</td> */}
      <td>
        {jackpotModule}
        {market}
      </td>
      {rowid && _status ? (
        <td style={{ color: selectColor(_winLose) }}>
          {_status == 3
            ? "VOID"
            : _status == 4
            ? "PENDING"
            : _winLose > 0
            ? "WIN"
            : _winLose < 0
            ? "LOSE"
            : result == "N"
            ? "PENDING"
            : "DRAW"}
        </td>
      ) : _status == "pending" ? (
        <td style={{ color: selectColor(0) }}>PENDING</td>
      ) : (
        <td style={{ color: selectColor(_winLose) }}>
          {_winLose == 0 && Result != 0
            ? "DRAW"
            : _winLose > 0
            ? "WIN"
            : _winLose < 0
            ? "LOSE"
            : result == "N" || winlossstatus == null
            ? "PENDING"
            : "DRAW"}
        </td>
      )}
      <td>
        {time ? time : created}
        {CreateTime ? CreateTime : RealTime}
        {orderTimeBj}
        {WinLoseDate8}
        {date}
      </td>
    </tr>
  );
}

function History(props) {
  const { t } = useTranslation();
  const { location } = props;
  const itemsCountPerPage = 20;
  const [activePage, updateActivePage] = useState(1);
  const [docs, updateDocs] = useState([]);
  const [histories, updateHistories] = useState([]);
  const [totals, updateTotals] = useState({});
  const [loading, withLoading] = useLoading();
  const [games, setGames] = React.useState([]);
  const [initial, updateInitial] = React.useState({});
  const [shownJson, updateShownJson] = React.useState(false);
  const [agents, updateAgents] = useState([]);
  const [bettingDetail, updateBettingDetail] = React.useState({});
  const { user_level: role, email } = store.get("user");

  const showDetails = (payload) => {
    updateBettingDetail(payload);
    updateShownJson(true);
  };

  const initialGames = async () => {
    const gamesResp = await http.get({ path: "games" }).then((rs) => {
      return rs.filter((x) => x.isActive == 1);
    });
    // console.log(gamesResp)
    const initialValues = queryString.parse(location.search);
    updateInitial({
      ...initial,
      ...initialValues,
    });
    const initialGames = [
      ...gamesResp.map(({ name, game_code, label, providerCode }) => {
        let title = label ? label : name;
        let value = providerCode ? providerCode : game_code;
        if (title == "maxbet") title = "M-Sport";
        return { title, value, providerCode, game_code };
      }),
    ];
    // const findSexy = gamesResp.find(x => x.id == 23)
    // if(findSexy) {
    //   const sexyProvider = JSON.parse(findSexy.game_ui_url)
    //   sexyProvider.map(s => {
    //     // console.log(s)
    //     initialGames.push({title: s.label, value: s.providerCode})
    //   })
    //   setSexyProviders(sexyProvider)
    // }

    setGames(initialGames);
    !isEmpty(initialValues) && onSubmit(initialValues);
  };

  const onSubmit = async (params) => {
    try {
      let { fromDate, toDate, username, game, agent, site } = params;
      const query = {
        fromDate: fromDate
          ? moment(fromDate).format("YYYY-MM-DD 00:00:00")
          : undefined,
        toDate: toDate
          ? moment(toDate).format("YYYY-MM-DD 23:59:59")
          : undefined,
        username: username && username !== "" ? username : undefined,
      };
      if (agent) query.agent = agent;
      if (site) query.site = site;

      if (game == "ssport") query.site = "ssport";
      if (game == "maxbet") query.site = "msport";
      if (!game) game = props.match.params.id;
      console.log(game, games);
      const sexy = games.find((x) => x.providerCode == game);
      if (sexy) {
        query.site = game;
        game = sexy.game_code;
      }
      const gameCodes = [game];

      let tasks = [];
      console.log(query, game);
      gameCodes.forEach((code) =>
        tasks.push(
          http.get({ path: `admin/report/${code}/betlist`, params: query })
        )
      );
      const bets = await withLoading(() =>
        Promise.all(tasks.map((p) => p.catch((e) => e)))
      );
      const betValids = bets.filter(({ totals }) => totals);
      updateHistories(betValids.map(({ bets }) => bets).flat());
      updateTotals(sumObjectsByKey(...betValids.map(({ totals }) => totals)));
    } catch (error) {
      console.log(error);
    }
  };

  const initialAgent = async () => {
    const correctAgents = ({ username }) => ({
      title: username,
      value: username,
    });
    const agentsResp = await withLoading(() =>
      http.get({
        path: "admin/users",
        params: {
          user_level: "AGENT",
        },
      })
    );
    const cleanedAgents = agentsResp.map(correctAgents);
    const [{ value: defaultAgent }] = cleanedAgents;
    updateAgents(cleanedAgents);
    updateInitial({
      ...initial,
      agent: defaultAgent,
    });
  };

  const loadDocs = () => {
    if (!histories.length) {
      updateDocs([]);
      return;
    }
    const from = (activePage - 1) * itemsCountPerPage;
    const to = from + itemsCountPerPage;
    const docs = histories.slice(from, to);
    updateDocs(docs);
  };

  useEffect(() => {
    initialGames();
    if (role === "PARTNER") {
      initialAgent();
    }
  }, []);

  useEffect(() => {
    loadDocs();
  }, [histories, activePage]);

  const schema = Joi.object().keys({
    username: Joi.string().allow("", null),
    fromDate: Joi.string(),
    toDate: Joi.string(),
    game: Joi.string(),
    agent: Joi.string(),
  });
  const query = new URLSearchParams(props.location.search);
  const { form, handleSubmit } = useForm({
    onSubmit,
    validate: validate(schema),
    initialValues: {
      fromDate: query.get("fromDate")
        ? query.get("fromDate")
        : DEFAULT_FROM_DATE,
      toDate: query.get("toDate") ? query.get("toDate") : DEFAULT_TO_DATE,
      username: query.get("username") ? query.get("username") : "",
      game: props.match.params.id
        ? props.match.params.id
        : games.length
        ? games[0].value
        : undefined,
      ...initial,
    },
  });

  const [username, fromDate, toDate, game, agent] = formCreateInputs(
    ["username", "fromDate", "toDate", "game", "agent"],
    form
  );

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <form onSubmit={handleSubmit} novalidate>
                {role === "PARTNER" && (
                  <SelectInput
                    style={{ width: 200, marginRight: 15 }}
                    options={agents}
                    input={agent}
                    label={t("SELECT_AGENT")}
                  />
                )}
                <SelectInput
                  style={{ marginRight: 15, width: 200, marginTop: 16 }}
                  input={game}
                  options={games}
                  label={t("SELECT_GAME")}
                />
                <TextInput input={username} label={t("USERNAME")} />
                <TextInput
                  style={{ marginLeft: 15, width: 200 }}
                  input={fromDate}
                  label={t("FROM")}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextInput
                  style={{ marginLeft: 15, width: 200 }}
                  input={toDate}
                  label={t("TO")}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormControl style={{ marginTop: 30, marginLeft: 15 }}>
                  {loading ? (
                    t("LOADING") + " ..."
                  ) : (
                    <Button type="submit" color="info" className="px-4">
                      {t("SEARCH")}
                    </Button>
                  )}
                </FormControl>
              </form>
            </CardHeader>
            {loading ? (
              t("LOADING") + " ..."
            ) : (
              <CardBody>
                <React.Fragment>
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t("NO.")}</th>
                        <th scope="col">{t("AGENT")}</th>
                        <th scope="col">{t("USERNAME")}</th>
                        <th scope="col">{t("BETID")}</th>
                        <th scope="col">{t("BET")}</th>
                        <th scope="col">{t("WINLOSE")}</th>
                        <th scope="col">{t("WINAMOUNT")}</th>
                        <th scope="col">{t("JACKPOT")}</th>

                        <th scope="col">{t("STATUS")}</th>
                        <th scope="col">{t("CREATED")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {docs.map((history, index) => (
                        <HistoryRow
                          key={index}
                          game={game}
                          history={history}
                          index={index++}
                          showDetails={showDetails}
                        />
                      ))}
                      <tr style={{ fontWeight: "bold", background: "#acb5bc" }}>
                        <td colSpan="7"></td>
                        <td colSpan="2">{t("TOTAL_BET")}</td>
                        <td
                          style={{ color: selectColor(totals.totalBetAmount) }}
                        >
                          {totals.totalBetAmount
                            ? correctNumber(totals.totalBetAmount)
                            : 0}
                        </td>
                      </tr>
                      <tr style={{ fontWeight: "bold", background: "#acb5bc" }}>
                        <td colSpan="7"></td>
                        <td colSpan="2">{t("TOTAL_WINLOSE")}</td>
                        <td style={{ color: selectColor(totals.totalWinLose) }}>
                          {totals.totalWinLose
                            ? correctNumber(totals.totalWinLose)
                            : 0}
                        </td>
                      </tr>
                      <tr style={{ fontWeight: "bold", background: "#acb5bc" }}>
                        <td colSpan="7"></td>
                        <td colSpan="2">{t("TOTAL_TURNOVER")}</td>
                        <td
                          style={{ color: selectColor(totals.totalTurnover) }}
                        >
                          {totals.totalTurnover
                            ? correctNumber(totals.totalTurnover)
                            : 0}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div>
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={histories.length}
                      pageRangeDisplayed={5}
                      onChange={updateActivePage}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </React.Fragment>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      <Dialog
        open={shownJson}
        onClose={() => updateShownJson(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t("BETTING_DETAIL")}
        </DialogTitle>
        <DialogContent>
          <ReactJson src={bettingDetail} displayDataTypes={false} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default History;
