import React from 'react';
import Loadable from 'react-loadable'
import BetingHistories from 'feature/BettingHistories';
import PromotionDetail from 'feature/Promotion/detail';
import Rabat from 'feature/Rabat';
import TransactionHistory from 'feature/TransactionHistory';
import Winlose from 'feature/Winlose'
import FullReport from 'feature/FullReport'
import FullReportPayable from 'feature/ReportPayable/FullReport'
import ReportNew from 'feature/ReportNew/FullReport'
import Expense from 'feature/ReportBarrel/Expenses'
import ReportBarrel from 'feature/ReportBarrel/FullReport'
import ReportBarrelNew from 'feature/ReportBarrel/FullReport/new'
import ReportBarrelConfigShare from 'feature/ReportBarrel/share'
import ReportNewPartner from 'feature/ReportNew/FullReportPartner'
import ReportNewAgent from 'feature/ReportNew/FullReportAgent'
import FullReportPartner from 'feature/FullReportPartner'
import FullReportPartnerPayable from 'feature/ReportPayable/FullReportPartner'
import FullReportAgent from 'feature/FullReportAgent'
import FullReportAgentPayable from 'feature/ReportPayable/FullReportAgent'
import FullReportPlayer from 'feature/FullReportPlayer'
import ReportNewPlayer from 'feature/ReportNew/FullReportPlayer'
import FullReportPlayerPayable from 'feature/ReportPayable/FullReportPlayer'
import EditUser from 'feature/EditUser'
import EditUserShare from 'feature/EditUser/share'
import TransferPoint from 'feature/EditUser/transferPoint'
import CreateAgent from 'feature/CreateAgent'
import CreatePartner from 'feature/CreatePartner'
import CreatePlayer from 'feature/CreatePlayer'
import CreateStaff from 'feature/CreateStaff'
import DefaultLayout from './containers/DefaultLayout';
import BonusView from 'feature/Bonus'
import Banking from 'feature/Banking'
import Loyalty from 'feature/Loyalty'
import Game from 'feature/Game'
import PositionTaking from 'feature/PositionTaking'
import PositionTakingGroup from 'feature/PositionTaking/group'
import Commissions from 'feature/Commissions'
import CommissionsNew from 'feature/Commissions/new'
import Comm from 'feature/Commissions/comm'
import BonusDeposit from 'feature/BonusDeposit'
import Profile from 'feature/Profile'
import ACL from 'feature/ACL'
import Games from 'feature/Game'

function Loading() {
  return <div>Loading...</div>;
}
const Users = Loadable({
  loader: () => import('feature/Users'),
  loading: Loading,
});
const Agent = Loadable({
  loader: () => import('feature/Users/agent'),
  loading: Loading,
});
const Player = Loadable({
  loader: () => import('feature/Users/player'),
  loading: Loading,
});
const Deposits = Loadable({
  loader: () => import('feature/Transactions/Deposits/Deposits'),
  loading: Loading,
});
const Withdraws = Loadable({
  loader: () => import('feature/Transactions/Withdraw'),
  loading: Loading,
});
const Transfers = Loadable({
  loader: () => import('feature/Transactions/Transfers/Transfers'),
  loading: Loading,
});
const TransfersPlayer = Loadable({
  loader: () => import('feature/Transactions/TransfersPlayer'),
  loading: Loading,
});
const Announcement = Loadable({
  loader: () => import('feature/Announcement'),
  loading: Loading,
});
const Promotion = Loadable({
  loader: () => import('feature/Promotion'),
  loading: Loading,
});

const PromotionAdd = Loadable({
  loader: () => import('feature/Promotion/add'),
  loading: Loading,
});
const PromotionPending = Loadable({
  loader: () => import('feature/Promotion/pending'),
  loading: Loading,
});
const PromotionSetting = Loadable({
  loader: () => import('feature/Promotion/setting'),
  loading: Loading,
});
const UserAction = Loadable({
  loader: () => import('feature/UserAction'),
  loading: Loading,
});

const routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/users', exact: true, name: 'Users', component: Users },
  { path: '/agents', exact: true, name: 'Agents', component: Agent },
  { path: '/players', exact: true, name: 'Players', component: Player },
  { path: '/users-action', exact: true, name: 'UsersManagement', component: UserAction },
  { path: '/announcement', exact: true, name: 'Announcement', component: Announcement },
  { path: '/promotion', exact: true, name: 'Promotion', component: Promotion },
  { path: '/promotion/:id', exact: true, name: 'Promotion Detail', component: PromotionDetail },
  { path: '/promotion-add', exact: true, name: 'PromotionAdd', component: PromotionAdd },
  { path: '/bonus-pending', exact: true, name: 'PromotionPending', component: PromotionPending },
  { path: '/promotion-setting', exact: true, name: 'PromotionSetting', component: PromotionSetting },
  { path: '/games', exact: true, name: 'Games', component: Games },
  { path: '/position-taking', name: 'PositionTaking', component: PositionTaking },
  { path: '/position-taking-group', name: 'PositionTakingGroup', component: PositionTakingGroup },
  { path: '/commissions', name: 'Commissions', component: Commissions },
  { path: '/commissions-new', name: 'CommissionsNew', component: CommissionsNew },
  { path: '/comm', name: 'Commissions', component: Comm },
  { path: '/transaction/deposits', name: 'Deposits', component: Deposits },
  { path: '/transaction/withdraws', name: 'Withdraws', component: Withdraws },
  { path: '/transaction/transfers', name: 'Transfers', component: Transfers },
  { path: '/transaction/transfers-player', name: 'Transfers', component: TransfersPlayer },
  { path: '/betting-histories', name: 'Beting Histories', component: BetingHistories },
  { path: '/rabat', name: 'Rabat', component: Rabat },
  { path: '/transaction-history', name: 'TransactionHistory', component: TransactionHistory },
  { path: '/report-winlose', name: 'Report Winlose', component: Winlose },
  { path: '/reports', name: 'Full Report', component: FullReport },
  { path: '/reports-payable', name: 'Full Report Payable', component: FullReportPayable },
  { path: '/reports-new', name: 'Full Report New', component: ReportNew },
  { path: '/barrel', name: 'Barrel Report', component: ReportBarrel },
  { path: '/barrels', name: 'Barrel Report', component: ReportBarrelNew },
  { path: '/barrel-config-share', name: 'Barrel Report Config Share', component: ReportBarrelConfigShare },
  { path: '/expenses', name: 'Expenses', component: Expense },
  { path: '/reports-new-partner', name: 'Full Report New partner', component: ReportNewPartner },
  { path: '/reports-new-agent', name: 'Full Report New Agent', component: ReportNewAgent },
  { path: '/report/partner/:id', name: 'Report Partner', component: FullReportPartner },
  { path: '/report-new/partner/:id', name: 'Report Partner', component: ReportNewPartner },
  { path: '/report-payable/partner/:id', name: 'Report Partner', component: FullReportPartnerPayable },
  { path: '/report/agent/:id', name: 'Report Agent', component: FullReportAgent },
  { path: '/report-new/agent/:id', name: 'Report Agent', component: ReportNewAgent },
  { path: '/report-payable/agent/:id', name: 'Report Agent', component: FullReportAgentPayable },
  { path: '/report/player/:id', name: 'Report Player', component: FullReportPlayer },
  { path: '/report-payable/player/:id', name: 'Report Player', component: FullReportPlayerPayable },
  { path: '/report-new/player/:id', name: 'Report Player', component: ReportNewPlayer },
  { path: '/betlist/game/:id', name: 'bet list Player', component: BetingHistories },
  { path: '/users/edit-user/:id', name: 'Edit User', component: EditUser },
  { path: '/users/share/:id', name: 'Share User', component: EditUserShare },
  { path: '/users/point/:id', name: 'Transfer Point', component: TransferPoint },
  { path: '/create-agent', name: 'Create Agent', component: CreateAgent },
  { path: '/create-partner', name: 'Create Partner', component: CreatePartner },
  { path: '/create-staff', name: 'Create Staff', component: CreateStaff },
  { path: '/create-player', name: 'Create Player', component: CreatePlayer },
  { path: '/bonus', name: 'Bonus', component: BonusView },
  { path: '/banking', name: 'Banking', component: Banking },
  { path: '/game', name: 'Game', component: Game },
  { path: '/loyalty', name: 'Loyalty', component: Loyalty },
  { path: '/profile', name: 'Profile', component: Profile },
  { path: '/deposit-bonus', name: 'Deposit Bonus', component: BonusDeposit },
  { path: '/acl-setting/:id', name: 'ACL', component: ACL },
];

export default routes;

