import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, Col, Row, Table, Button } from 'reactstrap'
import http from 'service/http'
import { useForm } from 'react-final-form-hooks'
import { TextInput, useLoading, SelectInput } from 'feature/Component'
import formCreateInputs from 'service/form/create'
import numeral from 'numeral'
import store from 'store'
import './style.scss'
import { FormControl } from '@material-ui/core'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import LoadingUi from 'feature/Component/Loading/ui'
const DEFAULT_FROM_DATE = moment().format('YYYY-MM-DD')
const DEFAULT_TO_DATE = moment().format('YYYY-MM-DD')
let user = store.get('user') || { level: '' }

const correctNumber = amount => numeral(amount).format('0,0.000')

function HistoryRow({ account, date, banker }) {
// console.log(account)
  return (
    <tr>
      <td style={{textAlign: 'left'}}>
        <a href={account.role == 'AGENT' ? '/report/agent/' + account.username + '?fromDate=' + date.fromDate + '&toDate=' + date.toDate + '&banker=' + account.lotto97Banker :
          (account.role == 'PLAYER' ? '/report/player/' + account.username + '?fromDate=' + date.fromDate + '&toDate=' + date.toDate + '&banker=' + banker :
          '/report/partner/' + account.username + '?fromDate=' + date.fromDate + '&toDate=' + date.toDate + '&banker=' + account.lotto97Banker)}>
          <b>{account.username}</b>
        </a>
      </td>
      <td>{correctNumber(account.winlose.totalTurnover)}</td>
      <td className="downline">{correctNumber(account.winlose.downlineSale)}</td>
      <td className="downline">{correctNumber(account.winlose.totalComm)}</td>
      <td className="downline" style={{
        color: account.winlose.downlineWinlose >= 0 ? 'green' : 'red'
      }}>{correctNumber(account.winlose.downlineWinlose)}</td>
      <td className="downline" style={{
        color: account.winlose.total >= 0 ? 'green' : 'red'
      }}>{correctNumber(account.winlose.total)}</td>
      <td className="upline" style={{
        color: account.winlose.parentSale >= 0 ? 'green' : 'red'
      }}>{correctNumber(account.winlose.parentSale)}</td>
      <td className="upline">{correctNumber(account.winlose.totalParentComm)}</td>
      <td className="upline" style={{
        color: account.winlose.parentWinlose >= 0 ? 'green' : 'red'
      }}>{correctNumber(account.winlose.parentWinlose)}</td>
      <td className="upline" style={{
        color: account.winlose.parentTotal >= 0 ? 'green' : 'red'
      }}>{correctNumber(account.winlose.parentTotal)}</td>

      <td className="company" style={{
        color: account.winlose.companyTotal >= 0 ? 'green' : 'red'
      }}>{correctNumber(account.winlose.companyTotal)}</td>
      <td>0</td>
      <td>0</td>
    </tr>
  )
}

const totalInit = {
  turnover: 0,
  downline: {
    sale: 0,
    comm: 0,
    winlose: 0,
    total: 0,
  },
  partner: {
    sale: 0,
    comm: 0,
    winlose: 0,
    total: 0,
  },
  companyTotal: 0,
  totalNet: 0,
}
let total1 = {
  turnover: 0,
  downline: {
    sale: 0,
    comm: 0,
    winlose: 0,
    total: 0,
  },
  partner: {
    sale: 0,
    comm: 0,
    winlose: 0,
    total: 0,
  },
  companyTotal: 0,
  totalNet: 0,
}
function History(props) {
  const { t } = useTranslation()

  user = props
  const query = new URLSearchParams(props.location.search);
  const [loading, withLoading] = useLoading()
  const [ accounts, updateAccounts] = useState([])
  const [ total, updateTotal] = useState(total1)
  const { lotto97Banker } = store.get('user')
  const [ isSync, setIsSync] = useState(false)
  const [initialInputs, updateInitialInputs] = useState({})
  const onSubmit = async (params) => {
    // console.log(params)
    loadReposrt({...params, banker: query.get('banker')})

  }
  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: initialInputs
  })
  const [fromDate, toDate] = formCreateInputs(['fromDate', 'toDate'], form)

  useEffect(() => {

    loadReposrt({
      banker: query.get('banker'),
      fromDate: query.get('fromDate'),
      toDate: query.get('toDate')
    })
  }, [])

  const updateTotalWL = (arr) => {

    arr.map(rs => {
// console.log(rs.winlose.companyTotal)
      total1 = {
        turnover: total1.turnover + rs.winlose.totalTurnover,
        downline: {
          sale: total1.downline.sale + rs.winlose.downlineSale,
          comm: total1.downline.comm + rs.winlose.totalComm,
          winlose: total1.downline.winlose + rs.winlose.downlineWinlose,
          total: total1.downline.total + rs.winlose.total,
        },
        partner: {
          sale: total1.partner.sale + rs.winlose.parentSale,
          comm: total1.partner.comm + rs.winlose.totalParentComm,
          winlose: total1.partner.winlose + rs.winlose.parentWinlose,
          total: total1.partner.total + rs.winlose.parentTotal,
        },
        companyTotal: total1.companyTotal + rs.winlose.companyTotal,
        totalNet: 0,
      }
      updateTotal(total1)
    })
  }
  const sync97Lotto = () => {
    setIsSync(true)
    const date = {...initialInputs, banker: query.get('banker')}
    withLoading(() => http.get({ path: `admin/97lotto/full-winlose`, params: date }).then(rs1 => {
      // console.log(rs1)
      const acc = [...accounts]
      rs1.map((p, i) => {
        const checkNewPn = acc.find(x => x.lotto97Banker == p.banker)
        if(checkNewPn) {
          const newPn = acc.map(x => {
            // console.log(x.lotto97Banker, p.banker)
            if(x.lotto97Banker == p.banker) {
              x.winlose = {
                totalTurnover: x.winlose.totalTurnover + p.winlose.totalTurnover,
                downlineSale: x.winlose.downlineSale + p.winlose.downlineSale,
                downlineWinlose: x.winlose.downlineWinlose + p.winlose.downlineWinlose,
                parentSale: x.winlose.parentSale + p.winlose.parentSale,
                parentTotal: x.winlose.parentTotal + p.winlose.parentTotal,
                totalComm: x.winlose.totalComm + p.winlose.totalComm,

                total: x.winlose.total + p.winlose.total,

                totalParentComm: x.winlose.totalParentComm + p.winlose.totalParentComm,
                parentWinlose: x.winlose.parentWinlose + p.winlose.parentWinlose,

                companyTotal: x.winlose.companyTotal + p.winlose.companyTotal,
                totalNet: 0,
              }
            }
            updateTotalWL([x])
            return x
          })
          updateAccounts(newPn);
        } else {
          // console.log(acc[0])
          acc.push({
            role: p.role,
            username: p.username,
            winlose: {
              companyTotal: p.winlose.companyTotal,
              downlineSale: p.winlose.downlineSale,
              downlineWinlose: p.winlose.downlineWinlose,
              parentSale: p.winlose.parentSale,
              parentTotal: p.winlose.parentTotal,
              parentWinlose: p.winlose.parentWinlose,
              totalBetAmount: p.winlose.totalBetAmount,
              totalComm: p.winlose.totalComm,
              totalParentComm: p.winlose.totalParentComm,
              totalTurnover: p.winlose.totalTurnover,
              totalWinLose: p.winlose.totalWinLose,
            }

          })
          updateTotalWL([p])

          updateAccounts(acc);
        }
        // console.log(newPn)


      })

    }))
  }
  const getWLByCurrentAcc = (acc, date) => {
    updateInitialInputs({
      fromDate: date.fromDate,
      toDate: date.toDate,
    })
    const playerquery = {
      path: `admin/report/agent/` + props.match.params.id,
      params: {...date}}
    http.get(playerquery).then(players => {
      // console.log(players)
      const newacc = players.winlose.map(player => {
        return {
          lotto97Banker: '',
          role: 'PLAYER',
          username: player._id.username,
          winlose: {
            companyTotal: player.companyTotal,
            downlineSale: player.totalTurnover,
            downlineWinlose: player.totalWinLose,
            parentSale: player.agentSale,
            parentTotal: player.agentTotal,
            total: player.total,
            parentWinlose: player.agentWinlose,
            totalBetAmount: player.totalBetAmount,
            totalComm: player.totalComm,
            totalParentComm: player.totalAgentComm,
            totalTurnover: player.totalTurnover,
            totalWinLose: player.totalWinLose,
          }
        }
      })
      acc = acc.concat(newacc)
      updateAccounts(acc)
      updateTotalWL(newacc)

      // console.log(2, date)
      // withLoading(() => http.get({ path: `admin/97lotto/full-winlose`, params: date }).then(rs1 => {
      //   // console.log(rs1)
      //
      //   rs1.map((p, i) => {
      //     const checkNewPn = acc.find(x => x.lotto97Banker == p.banker)
      //     if(checkNewPn) {
      //       const newPn = acc.map(x => {
      //         // console.log(x.lotto97Banker, p.banker)
      //         if(x.lotto97Banker == p.banker) {
      //           x.winlose = {
      //             totalTurnover: x.winlose.totalTurnover + p.winlose.totalTurnover,
      //             downlineSale: x.winlose.downlineSale + p.winlose.downlineSale,
      //             downlineWinlose: x.winlose.downlineWinlose + p.winlose.downlineWinlose,
      //             parentSale: x.winlose.parentSale + p.winlose.parentSale,
      //             parentTotal: x.winlose.parentTotal + p.winlose.parentTotal,
      //             totalComm: x.winlose.totalComm + p.winlose.totalComm,
      //
      //             total: x.winlose.total + p.winlose.total,
      //
      //             totalParentComm: x.winlose.totalParentComm + p.winlose.totalParentComm,
      //             parentWinlose: x.winlose.parentWinlose + p.winlose.parentWinlose,
      //
      //             companyTotal: x.winlose.companyTotal + p.winlose.companyTotal,
      //             totalNet: 0,
      //           }
      //         }
      //         updateTotalWL([x])
      //         return x
      //       })
      //       updateAccounts(newPn);
      //     } else {
      //       console.log(acc[0])
      //       acc.push({
      //         role: p.role,
      //         username: p.username,
      //         winlose: {
      //           companyTotal: p.winlose.companyTotal,
      //           downlineSale: p.winlose.downlineSale,
      //           downlineWinlose: p.winlose.downlineWinlose,
      //           parentSale: p.winlose.parentSale,
      //           parentTotal: p.winlose.parentTotal,
      //           parentWinlose: p.winlose.parentWinlose,
      //           totalBetAmount: p.winlose.totalBetAmount,
      //           totalComm: p.winlose.totalComm,
      //           totalParentComm: p.winlose.totalParentComm,
      //           totalTurnover: p.winlose.totalTurnover,
      //           totalWinLose: p.winlose.totalWinLose,
      //         }
      //
      //       })
      //       updateTotalWL([p])
      //
      //       updateAccounts(acc);
      //     }
      //     // console.log(newPn)
      //
      //
      //   })
      //
      // }))
    })
  }
  const loadReposrt = (date) => {
    total1 = {...totalInit}
    setIsSync(false)
    http.get({ path: `admin/partner/` + props.match.params.id + '/agents'}).then(acc => {
      updateAccounts(acc);
      // console.log(1, accounts)
      const newAcc = [...acc]
      if(acc.length > 0) acc.map( (a, i) => {
        // console.log(a)
        const query = a.role == 'AGENT' ? {
          path: `admin/report/partner/` + props.match.params.id,
          params: {
            agent: a.username,
            fromDate: date.fromDate,
            toDate: date.toDate,
          }
        } : {
          path: `admin/report/partner/` + a.username,
          params: {
            fromDate: date.fromDate,
            toDate: date.toDate,
          }
        }
        http.get(query).then(rs => {
          newAcc[i].winlose = rs.winlose
          updateTotalWL([rs])

          // }
        })
        if(i == acc.length - 1) {
          getWLByCurrentAcc(acc, date)

        }
      })
      else getWLByCurrentAcc(acc, date)
    })
  }

  return (
    <div className="animated fadeIn">
      {loading && <LoadingUi/>}
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <form onSubmit={handleSubmit} style={{float: 'left'}}>
                <TextInput style={{ marginLeft: 15, width: 200 }} input={fromDate} label={t('FROM')} type='date'
                           InputLabelProps={{
                             shrink: true
                           }}/>
                <TextInput style={{ marginLeft: 15, width: 200 }} input={toDate} label={t('TO')} type='date'
                           InputLabelProps={{
                             shrink: true
                           }}/>
                <FormControl style={{ marginTop: 30, marginLeft: 15 }}>
                  {loading ? t('LOADING') + ' ...' : <Button type='submit' color="info" className="px-4">{t('SEARCH')}</Button>}
                </FormControl>
              </form>
              {/*<FormControl style={{ marginTop: 30, marginLeft: 15, float: 'left' }}>*/}
                {/*<Button type='btn' color="primary" onClick={sync97Lotto} disabled={isSync} className="px-4">{t('SYNC_REAL_REPORT')}</Button>*/}

              {/*</FormControl>*/}
            </CardHeader>
            <CardBody>

              <React.Fragment>
                <Table responsive hover className="fullReportTable">
                  <thead>

                  <tr style={{ background: 'rgb(5 48 130)', color: 'white' }}>

                    <th scope="col" rowSpan="2" className="rowSpan">{t('DOWNLINE_ACCOUNT')}</th>
                    <th scope="col" rowSpan="2" className="rowSpan">{t('DOWNLINE_TURNOVER')}</th>
                    <th scope="col" colSpan="4" className="colSpan">{t('DOWNLINE')}</th>
                    <th scope="col" colSpan="4" className="colSpan">{t('PARTNER')}</th>
                    <th rowSpan="2" className="rowSpan">{t('COMPANY_TOTAL')}</th>
                    <th scope="col" rowSpan="2" className="rowSpan">{t('SHARE_PERCEN')}</th>
                    <th scope="col" rowSpan="2" className="rowSpan">{t('TOTAL_NET')}</th>
                  </tr>
                  <tr>
                    <th>{t('SALE')}</th>
                    <th>{t('COMMISSIONS')}</th>
                    <th>{t('WINLOSE')}</th>
                    <th>{t('TOTAL')}</th>
                    <th>{t('SALE')}</th>
                    <th>{t('COMMISSIONS')}</th>
                    <th>{t('WINLOSE')}</th>
                    <th>{t('TOTAL')}</th>
                  </tr>
                  </thead>
                  {accounts.length || total.turnover > 0?
                    <tbody>
                    {accounts.map((account, i) =>
                      account.winlose.totalTurnover > 0 && <HistoryRow account={account} date={initialInputs} index={i++} key={i} banker={lotto97Banker}/>
                    )}
                    <tr style={{ fontWeight: 'bold', background: '#8f9ba68a' }}>
                      <td>Total</td>
                      <td>{correctNumber(total.turnover)}</td>
                      <td>{correctNumber(total.downline.sale)}</td>
                      <td>{correctNumber(total.downline.comm)}</td>
                      <td style={{
                        color: total.downline.winlose >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.downline.winlose)}</td>
                      <td style={{
                        color: total.downline.total >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.downline.total)}</td>
                       <td style={{
                        color: total.partner.sale >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.partner.sale)}</td>
                      <td>{correctNumber(total.partner.comm)}</td>
                      <td style={{
                        color: total.partner.winlose >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.partner.winlose)}</td>
                      <td style={{
                        color: total.partner.total >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.partner.total)}</td>
                      <td style={{
                        color: total.companyTotal >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.companyTotal)}</td>
                      <td>0</td>
                      <td>{total.totalNet}</td>
                    </tr>

                    </tbody>
                    : <tbody>
                    <tr>
                      <td>{t('NO_DATA')}</td>
                    </tr>
                    </tbody>
                  }
                </Table>
              </React.Fragment>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </div>
  )
}

export default History