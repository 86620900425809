import React, { useState, useEffect } from 'react';
import { Tooltip, Button, TextField, Dialog, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import http from 'service/http';
import store from 'store';
import validate from 'service/form/validation'
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import formCreateInputs from 'service/form/create'
import { useDialog, TextInput, useLoading } from 'feature/Component'
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next'
import TreeView from '../Users/treeView'
import LoadingUi from 'feature/Component/Loading/ui'

let user = store.get('user') || { level: '' };

function User({games, username, parent_id, user_account_no, show, commissions, email, level, index, toggleTree, user_id, showModalPT, showModalPT1, user_level, setCommDATA }) {
  commissions = JSON.parse(commissions)

  return (
    <tr>
      <td style={{ textAlign: 'left', width: 150, color: 'blue' }}>
        {user_level != 'PLAYER' ? <b>{username}</b> :
          <b>{user_account_no}</b>
        }
      </td>

      <td>{user_level}</td>
      {games.map(({ game_code, label }) => {
        let isGame = commissions.find(x => x.game_code == game_code)
          if(!isGame) isGame = {game_code,comm:0}
        return <td key={game_code + label}>
          <Tooltip title={"Edit " + game_code}>
            <Button onClick={() => showModalPT({ parent_id, game_code, comm: isGame.comm, user_id, commissions })} size="small" >
              {isGame.comm} %
            </Button>
            {/*{user.username !== 'admin' ? (<Button onClick={() => showModalPT1(t)} size="small" >*/}
                {/*{comm} %*/}
              {/*</Button>) :*/}
              {/*(<Button onClick={() => showModalPT({ game_code, comm, user_id })} size="small" >*/}
                {/*{comm} %*/}
              {/*</Button>)}*/}
          </Tooltip>
        </td>})}
    </tr>
  )
}
const LoadChild = ({ root, docs, initialUsers}) => {
  return <TreeView parent={root} children={docs} initialUsers={initialUsers}/>
}
function Users(props) {
  const { t } = useTranslation()
  user = {...props}
  const schema = Joi.object().keys({
    comm: Joi.number().min(0).max(100).required()
  })
  // const schemaDefault = Joi.object().keys({
  //   commDefault: Joi.number().min(0).max(1).required()
  // })
  const [PT, updatePT] = useState([])
  const [ptModal, updatePTModal] = useState({})
  const [defaultModal, updateDefaultModal] = useState({})
  const [loading, withLoading] = useLoading()
  const [docs, updateDocs] = useState([])
  const [users, updateUsers] = useState([])
  const itemsCountPerPage = 20
  const [activePage, updateActivePage] = useState(1)
  const [treedocs, updateTreeDocs] = useState([])
  const [respGames, setSespGames] = useState([])
  const [defaulComm, setDefaultComm] = useState([])
  const [commUpdate, setCommUpdate] = useState(0)

  const setCommDATA = (parent_id) => {
    const ptdata = PT.filter(x => x.parent_id == parent_id)
    // console.log(ptdata)
    updateDocs(ptdata)
  }

  const toggleTree = (index, level) => {
    try {
      let ableToUpdate = true
      const newTable = PT.map((item, i) => {
        if (!ableToUpdate) {
          return item
        }
        if (i > index && item.level <= level) {
          ableToUpdate = false
          return item
        }

        if (ableToUpdate) {
          if (i > index && (item.level === level + 1 || item.show)) {
            return {
              ...item,
              show: !item.show
            }
          }
        }
        return item
      })
      // console.log(newTable)
      updatePT(newTable)
    } catch (error) {
      console.log(error)
    }
  }
  const [ErroDialog, showErrorDialogWithMessage] = useDialog({
    title: 'Error',
    type: 'error'
  })
  // const [Dialog, showDialogWithMessage] = useDialog({
  //   title: t('UPDATE_USER_SUCCESS')
  // })
  const showModalDefault = data => {
    setCommUpdate(data.comm)
    updateDefaultModal(data)
  }
  const showModalPT = data => {
    console.log(ptModal, data)
    updatePTModal(data)
    console.log(ptModal)
  }
  const showModalPT1 = (t) => {
    showErrorDialogWithMessage(t('PLEASE_CONTACT_ADMIN'))
  }
  const onSubmit = async ({comm}) => {
    const currentPT = JSON.parse(user.commissions)

    const selectedPT = currentPT.find(({ game_code }) => game_code === ptModal.game_code)
    const cloneSelectedPt = { ...selectedPT };
    if (!'ADMIN, ADMIN_CS'.includes(user.user_level) && ((comm > 0 && (currentPT.length == 0 || !cloneSelectedPt.comm)) || cloneSelectedPt.comm < comm * 1)) {
      // console.log(commissions.commissions)
      return updatePTModal({
        ...ptModal,
        error: t('COMM_CANNOT_GREATE_THAN')
      })
    }
    // console.log(ptModal)
    const commissions = ptModal.commissions.map(c => {
      if(c.game_code == ptModal.game_code) c.comm = comm
      return c
    })
    try {
      await http.put({ path: `admin/users/${ptModal.user_id}`, payload: {commissions: JSON.stringify(commissions)} })
      // showDialogWithMessage(t('YOU_REQUEST_SUBMITED_SUCCESS'))
      window.location.reload()
    } catch (error) {
      throw error
    }

  }
  const run = async () => {
    const games = await http.get({ path: 'games' }).then(rs => rs.filter(x => x.isActive == 1))
      setSespGames(games)
  }
  const loadDocs = () => {
    if(!users.length) {
      return updateDocs([])
    }
    const from = (activePage - 1) * itemsCountPerPage
    const to = from + itemsCountPerPage
    const docs = users.slice(from, to)
    updateDocs(docs)

  }
  const initialUsers = async (params = {}, level = false) => {
    updateActivePage(1)
    const users = await await withLoading(() => http.get({path: 'admin/users', params}))

    // console.log(level)
    if(level) {
      updateTreeDocs(treedocs.map((tr, i) => {
        if(tr.username == params.parent_id) tr.users = users
        return tr
      }))
    } else updateTreeDocs(users)
    updateUsers(users)
// console.log(treedocs)
  }
  useEffect(() => {
    run()
    initialUsers({parent_id: props.user_level.includes('CS') ? props.parent_id : props.username})
  }, [])

  useEffect(() => {
    loadDocs()
  }, [users, activePage])

  const { form, handleSubmit } = useForm({
    validate: validate(schema),
    onSubmit,
    initialValues: {
      comm: ptModal.comm
    }
  })
  const [comm] = formCreateInputs(['comm'], form)
  const onSubmitDefault = async () => {
    updateDefaultModal({})
    const comm = commUpdate
    // console.log(defaultModal)

    const currentPT = JSON.parse(user.commissions)
    const selectedPT = currentPT.find(({ game_code }) => game_code === defaultModal.game_code)
    const cloneSelectedPt = { ...selectedPT };
    if (!'ADMIN, ADMIN_CS'.includes(user.user_level)  && ((comm > 0 && (currentPT.length == 0 || !cloneSelectedPt.comm)) || cloneSelectedPt.comm < comm * 1)) {
      return updatePTModal({
        ...ptModal,
        error: t('COMM_CANNOT_GREATE_THAN')
      })
    }
    const { commissions } = defaulComm[0]
    const cleanedPT = JSON.parse(commissions).map(c => {
      if(c.game_code === defaultModal.game_code) c.comm = comm
      return c
    })
    const found = cleanedPT.find(({ game_code }) => game_code === defaultModal.game_code)
    if(!found) cleanedPT.push({game_code: defaultModal.game_code, comm: comm})
    cleanedPT.map((item) => {

      if (item.game_code === ptModal.game_code) item.comm = comm
      return item
    })
      .filter(({ comm }) => comm !== 0)

    try {
      await withLoading(() => http.put({
        path: `admin/default/commissions`, payload: {
          percen: cleanedPT
        }
      }))
      // updatePTModal({})
      run();
    } catch ({ message: { msg } }) {
      // console.log(msg)
      updatePTModal({
        ...ptModal,
        error: msg
      })
    }

  }

  return (
    <div className="animated fadeIn">
      {loading && <LoadingUi/>}
      <Row>
        <Col md="4" lg="2">
          <Card>
            <CardHeader>
              <LoadChild root={user.username} docs={treedocs} initialUsers={initialUsers}/>
            </CardHeader>
          </Card>
        </Col>
        <Col md="8" lg="10">
          <Card>
            <CardHeader>
              <h6>
                Account: {user.username}
              </h6>
            </CardHeader>
            <CardBody>
              <Table striped responsive hover style={{ textAlign: 'center' }}>
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: '15%' }}>{t('PARTNER')}</th>
                      <th scope="col" style={{ width: '15%' }}>{t('ROLE')}</th>
                      {respGames.map(({ game_code, name, label}) => <th key={game_code + label} scope="col" >{label ? label : name}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {docs.map((item, index) =>
                      <User key={index} {...item} toggleTree={toggleTree} showModalPT={showModalPT} t={t} games={respGames}  showModalPT1={showModalPT1} setCommDATA={setCommDATA} />
                    )}
                  </tbody>
                </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Dialog open={!isEmpty(ptModal)} onClose={() => updatePTModal({})} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('UPDATE_PERCEN')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {ptModal.game_code}
          </DialogContentText>
          <form id="withdraw-form" onSubmit={handleSubmit} noValidate>
            <TextInput style={{ marginRight: 15 }} input={comm} type="number" max="100" label='Percentage' />
            <Button type="submit" variant="contained" color="info">{t('UPDATE')}</Button>
          </form>
          {ptModal.error
            ? <p style={{ color: 'red' }}>{ptModal.error}</p>
            : null}
        </DialogContent>
      </Dialog>
      <ErroDialog/>
    </div>
  )
}

export default Users;
