import React from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, InputGroup, Row } from 'reactstrap';
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import http from 'service/http'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'
import { useDialog, TextInput } from 'feature/Component'
import store from 'store'
import { useTranslation } from 'react-i18next'

function Login({ history }) {
  const { t } = useTranslation()
  store.clearAll()
  const [ErroDialog, showErrorDialogWithMessage] = useDialog({
    title: t('LOGIN_ERROR'),
    type: 'error'
  })
  const onSubmit = async payload => {
    try {
      const { id_token: token } = await http.post({ path: 'admin/authenticate', payload })
      http.setJwtToken(token)
      store.set('token', token)
      const user = await http.get({ path: 'me' })
      store.set('user', user)
      if(user.user_level === 'COMPANY_CS') {
        const aclResp = await http.get({ path: `config/permissions` })
        store.set('userAcl', aclResp)
      }
      history.push('profile')
      // user.user_level === 'COMPANY_CS' || user.user_level === 'PARTNER'
      //   ? history.push('transaction/profile')
      //   : history.push('transaction/transfers')
    } catch (error) {
      showErrorDialogWithMessage(error.message)
    }
  }

  const schema = Joi.object().keys({
    email: Joi.string().required(),
    password: Joi.string().required(),
  })

  const { form, handleSubmit } = useForm({
    onSubmit,
    validate: validate(schema)
  })

  const [email, password] = formCreateInputs(['email', 'password'], form)

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="4">
            <form onSubmit={handleSubmit}>
              <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <h1>Login</h1>
                  <InputGroup className="mb-3">
                    <TextInput input={email} label={t('ENTER_USERNAME')}/>
                  </InputGroup>
                  <InputGroup className="mb-4">
                    <TextInput input={password} type="password" label={t('ENTER_PASS')}/>
                  </InputGroup>
                  <Row>
                    <Col xs="6">
                      <Button type='submit' color="primary" className="px-4" >{t('LOGIN')}</Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardGroup>
            </form>
          </Col>
        </Row>
      </Container>
      <ErroDialog/>
    </div>
  )
}

export default Login
