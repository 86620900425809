import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, Col, Row, Table, Button } from 'reactstrap'
import http from 'service/http'
import { useForm } from 'react-final-form-hooks'
import formCreateInputs from 'service/form/create'
import moment from 'moment'
import { FormControl } from '@material-ui/core'
import { TextInput, useLoading, SelectInput } from 'feature/Component'
import queryString from 'query-string'
import numeral from 'numeral'
import store from 'store'

let user = store.get('user') || { level: '' }
const DEFAULT_FROM_DATE = moment().startOf('month').format('YYYY-MM-DD')
const DEFAULT_TO_DATE = moment().format('YYYY-MM-DD')

const correctNumber = amount => numeral(amount).format('0,0.00')
let totalNetProfit = 0
const selectColor = value => {
  if (value > 0) {
    return '#14805E'
  }
  if (value < 0) {
    return 'red'
  }
  return 'inherit'
}

function HistoryRow({ history, index, showDetail }) {
  const {
    game, game_code: gamecode, totalBetAmount,
    totalTurnover, totalWinLose, loyaltyBonus = 0,
    detail, childPT, netProfit, pt, comm
  } = history
  const totalAmount = totalWinLose + loyaltyBonus

  return user.user_level != 'AGENT' ? (
    <tr onClick={() => showDetail(detail)} key={index} style={{
      background: (game === 'Grand Total') ? '#e4e5e6' : 'inherit',
      fontWeight: (game === 'Grand Total') ? 'bold' : 'initial',
      cursor: 'pointer'
    }}>
      <td style={{ fontWeight: 'bold' }}>{game}</td>
      <td style={{ color: selectColor(totalBetAmount) }}>{numeral(totalBetAmount).format('0,0.00')}</td>
      <td style={{ color: selectColor(totalTurnover) }}>{numeral(totalTurnover).format('0,0.00')}</td>
      <td style={{ color: selectColor(totalWinLose) }}>{numeral(totalWinLose).format('0,0.00')}</td>
      <td style={{ color: selectColor(loyaltyBonus) }}>{numeral(loyaltyBonus).format('0,0.00')}</td>
      <td style={{ color: selectColor(totalAmount) }}>{numeral(totalAmount).format('0,0.00')}</td>
      <td style={{ color: selectColor(netProfit) }}>{pt} %</td>
      <td style={{ color: selectColor(netProfit) }}>{childPT} %</td>
      <td style={{ color: selectColor(netProfit) }}>{comm} %</td>
      <td
        style={{ color: selectColor(netProfit) }}>{numeral(gamecode == 'grand_total' ? totalNetProfit : netProfit).format('0,0.00')}</td>
    </tr>
  ) : (
    <tr onClick={() => showDetail(detail)} key={index} style={{
      background: (game === 'Grand Total') ? '#e4e5e6' : 'inherit',
      fontWeight: (game === 'Grand Total') ? 'bold' : 'initial',
      cursor: 'pointer'
    }}>
      <td style={{ fontWeight: 'bold' }}>{game}</td>
      <td style={{ color: selectColor(totalBetAmount) }}>{numeral(totalBetAmount).format('0,0.00')}</td>
      <td style={{ color: selectColor(totalTurnover) }}>{numeral(totalTurnover).format('0,0.00')}</td>
      <td style={{ color: selectColor(totalWinLose) }}>{numeral(totalWinLose).format('0,0.00')}</td>
      <td style={{ color: selectColor(loyaltyBonus) }}>{numeral(loyaltyBonus).format('0,0.00')}</td>
      <td style={{ color: selectColor(totalAmount) }}>{numeral(totalAmount).format('0,0.00')}</td>
      <td style={{ color: selectColor(netProfit) }}>{pt} %</td>
      <td style={{ color: selectColor(netProfit) }}>{comm} %</td>
      <td
        style={{ color: selectColor(netProfit) }}>{numeral(gamecode == 'grand_total' ? totalNetProfit : netProfit).format('0,0.00')}</td>
    </tr>
  )
}

function WinloseDetailRow({ history, index, showHistories }) {
  const { username, totalBetAmount, totalTurnover, totalWinLose, loyaltyBonus = 0 } = history
  const totalAmount = totalWinLose + loyaltyBonus
  const netProfit = -totalAmount
  return (
    <tr onClick={() => showHistories(username)} key={index} style={{
      background: (username === 'Grand Total') ? '#e4e5e6' : 'inherit',
      fontWeight: (username === 'Grand Total') ? 'bold' : 'initial',
      cursor: 'pointer'
    }}>
      <td style={{ fontWeight: 'bold' }}>{username}</td>
      <td style={{ color: selectColor(totalBetAmount) }}>{numeral(totalBetAmount).format('0,0.00')}</td>
      <td style={{ color: selectColor(totalTurnover) }}>{numeral(totalTurnover).format('0,0.00')}</td>
      <td style={{ color: selectColor(totalWinLose) }}>{numeral(totalWinLose).format('0,0.00')}</td>
      <td style={{ color: selectColor(loyaltyBonus) }}>{numeral(loyaltyBonus).format('0,0.00')}</td>
      <td style={{ color: selectColor(totalAmount) }}>{numeral(totalAmount).format('0,0.00')}</td>
      <td style={{ color: selectColor(netProfit) }}>{numeral(netProfit).format('0,0.00')}</td>
    </tr>
  )
}

function sumObjectsByKey(...objs) {
  return objs.reduce((a, b) => {
    for (let k in b) {
      if (b.hasOwnProperty(k))
        a[k] = (a[k] || 0) + b[k]
    }
    return a
  }, {})
}

function History(props) {
  // console.log(history)
  user = props
  const { history } = props
  const [loading, withLoading] = useLoading()
  const [winloses, updateWinloses] = useState([])
  const [winlosesDetail, updateWinlosesDetail] = useState([])
  const [grandTotal, updateGrandTotal] = useState({})
  const [selectedGame, updateSelectedGame] = useState('')
  const [bonus, updateBonus] = useState({})
  const [agents, updateAgents] = useState([])
  const { user_level: role, email, username: agentUsername } = store.get('user')
  const [initialInputs, updateInitialInputs] = useState({})
  const [games, updateGames] = useState([])

  const checkGameIsActive = (gameCode) => !!games.find(({ game_code, isActive }) => isActive && game_code === gameCode)
  const fakeRequest = new Promise((resolve) => resolve({ totals: {} }))

  const onSubmit = async (params) => {
    try {

      const {
        fromDate, toDate, username, agent
      } = params
      const query = {
        fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD 00:00:00') : undefined,
        toDate: toDate ? moment(toDate).format('YYYY-MM-DD 23:59:59') : undefined,
        username,
        agent: agent || agentUsername
      }
      // console.log(agentUsername)
      const PT = JSON.parse(user.position_taking)
      totalNetProfit = 0
      const [ace333Resp, play8oy, allbet, maxbet, playtech, spadegaming, joker, newwin, lottoThai, grandBonus, childPTs] = await withLoading(() => Promise.all([
        checkGameIsActive('ace333') ? http.get({ path: `admin/report/ace333/winlose`, params: query }) : fakeRequest,
        checkGameIsActive('play8oy') ? http.get({ path: `admin/report/Play8oy/winlose`, params: query }) : fakeRequest,
        checkGameIsActive('allbet') ? http.get({ path: `admin/report/allbet/winlose`, params: query }) : fakeRequest,
        checkGameIsActive('maxbet') ? http.get({ path: `admin/report/maxbet/winlose`, params: query }) : fakeRequest,
        checkGameIsActive('playtech') ? http.get({
          path: `admin/report/playtech/winlose`,
          params: query
        }) : fakeRequest,
        checkGameIsActive('spadegaming') ? http.get({
          path: `admin/report/spadegaming/winlose`,
          params: query
        }) : fakeRequest,
        checkGameIsActive('joker') ? http.get({ path: `admin/report/joker/winlose`, params: query }) : fakeRequest,
        checkGameIsActive('lottery') ? http.get({ path: `admin/report/lottery/winlose`, params: query }) : fakeRequest,
        checkGameIsActive('lottery_thai') ? http.get({
          path: `admin/report/lottery_thai/winlose`,
          params: query
        }) : fakeRequest,
        http.get({ path: `admin/report/bonus`, params: query }),
        agent ? http.get({ path: 'admin/config/' + agent + '/position-taking', params: query }) : []
      ]))
      const foundPTChild = childPTs.find(({ level }) => level === user.level + 1)
      // console.log(foundPTChild)
      const childPT = foundPTChild ? JSON.parse(foundPTChild.position_taking) : []
      const grand = sumObjectsByKey(ace333Resp.totals, play8oy.totals, maxbet.totals, allbet.totals, playtech.totals, spadegaming.totals, joker.totals, lottoThai.totals, newwin.totals)
      updateGrandTotal(grand)
      updateBonus(grandBonus)
      const winloseWithBonus = [
        {
          game: 'ACE333',
          game_code: 'ace333',
          ...ace333Resp.totals,
          detail: 'admin/report/ace333/winlose-detail?agent=' + query.agent
        }, {
          game: 'Play8oy',
          game_code: 'play8oy',
          ...play8oy.totals,
          detail: 'admin/report/Play8oy/winlose-detail?agent=' + query.agent
        }, {
          game: 'Allbet',
          game_code: 'allbet',
          ...allbet.totals,
          detail: 'admin/report/allbet/winlose-detail?agent=' + query.agent
        }, {
          game: 'Maxbet',
          game_code: 'maxbet',
          ...maxbet.totals,
          detail: 'admin/report/maxbet/winlose-detail?agent=' + query.agent
        }, {
          game: 'Playtech',
          game_code: 'playtech',
          ...playtech.totals,
          detail: 'admin/report/playtech/winlose-detail?agent=' + query.agent
        }, {
          game: 'Spadegaming',
          game_code: 'spadegaming',
          ...spadegaming.totals,
          detail: 'admin/report/spadegaming/winlose-detail?agent=' + query.agent
        }, {
          game: 'Joker',
          game_code: 'joker',
          ...joker.totals,
          detail: 'admin/report/joker/winlose-detail?agent=' + query.agent
        }, {
          game: 'Newwin',
          game_code: 'lottery',
          ...newwin.totals,
          detail: 'admin/report/lottery/winlose-detail?agent=' + query.agent
        }, {
          game: '97lotto',
          game_code: 'lottery_thai',
          ...lottoThai.totals,
          detail: 'admin/report/lottery_thai/winlose-detail?agent=' + query.agent
        },
        {
          game: 'Grand Total',
          game_code: 'grand_total',
          ...grand
        }
      ].map(item => {
        const foundPTGameChild = childPT.find(({ game_code }) => game_code === item.game_code)
        // console.log(foundPTGameChild)
        item.childPT = foundPTGameChild ? foundPTGameChild.pt : 0
        const foundCurrentPT = PT.find(({ game_code }) => game_code === item.game_code)

        const pt = foundCurrentPT ? foundCurrentPT.pt : 100
        item.pt = pt

        let c = 0
        if (user.level > 0) {
          const C = JSON.parse(user.commissions)
          const foundC = C.find(({ game_code }) => game_code === item.game_code)
          c = foundC ? foundC.comm : 0
        }
        item.comm = c
        item.netProfit = -item.totalWinLose * (pt - item.childPT) / 100 + (item.totalBetAmount * item.comm / 100)
        // console.log(totalNetProfit, item.netProfit)
        if (item.game_code != 'grand_total') totalNetProfit += item.netProfit
        // if(item.game_code == 'lottery_thai') console.log(item, item.totalWinLose, pt, item.childPT)
        const found = grandBonus.loyalty.find(({ game }) => game === item.game.toLowerCase())
        if (!found) return item
        return {
          ...item,
          loyaltyBonus: found.amount
        }
      })
      // console.log(winloseWithBonus)
      updateWinloses(winloseWithBonus)
    } catch (error) {
      console.log(error)
    }
  }

  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: initialInputs
  })

  const [username, fromDate, toDate, agent] = formCreateInputs(['username', 'fromDate', 'toDate', 'agent'], form)

  const showDetail = async path => {
    if (!path || path === '') return updateWinlosesDetail([])
    updateSelectedGame(path.split('/')[2])
    const query = {
      fromDate: fromDate.input.value ? moment(fromDate.input.value).format('YYYY-MM-DD 00:00:00') : undefined,
      toDate: toDate.input.value ? moment(toDate.input.value).format('YYYY-MM-DD 23:59:59') : undefined
    }

    const [winloseDetail] = await withLoading(() => Promise.all([
      http.get({ path, params: query })
    ]))
    const grandDetail = sumObjectsByKey(...winloseDetail)
    updateWinlosesDetail([...winloseDetail, { ...grandDetail, username: 'Grand Total' }])
  }

  const showHistories = (username) => {
    const query = {
      fromDate: fromDate.input.value ? moment(fromDate.input.value).format('YYYY-MM-DD') : undefined,
      toDate: toDate.input.value ? moment(toDate.input.value).format('YYYY-MM-DD') : undefined,
      game: selectedGame.toLowerCase(),
      username
    }
    history.push(`/betting-histories?${queryString.stringify(query)}`)
  }

  const initialAgent = async () => {
    const correctAgents = ({ username }) => ({
      title: username, value: username
    })
    const agentsResp = await withLoading(() => http.get({
      path: 'admin/agents'
    }))
    const cleanedAgents = agentsResp.map(correctAgents)
    const [{ value: defaultAgent }] = cleanedAgents
    updateAgents(cleanedAgents)

    updateInitialInputs({
      fromDate: DEFAULT_FROM_DATE,
      toDate: DEFAULT_TO_DATE,
      agent: defaultAgent
    })
  }

  const getGames = async () => {
    try {
      const response = await withLoading(() => http.get({ path: 'games' }))
      const gamesActive = response.filter(({ isActive }) => !!isActive)
      updateGames(gamesActive)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    (role === 'ADMIN' || role === 'PARTNER') ? initialAgent() : updateInitialInputs({
      fromDate: DEFAULT_FROM_DATE,
      toDate: DEFAULT_TO_DATE
    })
    getGames()
  }, [])

  const realProfit = totalNetProfit - bonus.deposit

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <form onSubmit={handleSubmit}>
                {(role === 'ADMIN' || role === 'PARTNER') &&
                <SelectInput style={{ width: 200, marginRight: 15 }} options={agents} input={agent}
                             label='Select Agent'/>}
                {/*<TextInput input={username} label='Username' />*/}
                <TextInput style={{ marginLeft: 15, width: 200 }} input={fromDate} label='From Date' type='date'
                           InputLabelProps={{
                             shrink: true
                           }}/>
                <TextInput style={{ marginLeft: 15, width: 200 }} input={toDate} label='To Date' type='date'
                           InputLabelProps={{
                             shrink: true
                           }}/>
                <FormControl style={{ marginTop: 30, marginLeft: 15 }}>
                  {loading ? 'Loading...' : <Button type='submit' color="primary" className="px-4">Search</Button>}
                </FormControl>
              </form>
            </CardHeader>
            <CardBody>
              {winloses.length ?
                <React.Fragment>
                  <Table responsive hover>
                    <thead>
                    {user.user_level != 'AGENT' ? (
                        <tr style={{ background: 'rgb(5 48 130)', color: 'white' }}>

                          <th scope="col">Game Name</th>
                          <th scope="col">Total Bet Amount</th>
                          <th scope="col">Total Turnover</th>
                          <th scope="col">Total WinLose</th>
                          <th scope="col">Loyalty Bonus</th>
                          <th scope="col">Total Amount</th>
                          <th scope="col">Position Taking</th>
                          <th scope="col">Child PT</th>
                          <th scope="col">Commissions</th>
                          <th scope="col">Net Profit</th>
                        </tr>
                      ) :
                      (
                        <tr style={{ background: 'rgb(5 48 130)', color: 'white' }}>

                          <th scope="col">Game Name</th>
                          <th scope="col">Total Bet Amount</th>
                          <th scope="col">Total Turnover</th>
                          <th scope="col">Total WinLose</th>
                          <th scope="col">Loyalty Bonus</th>
                          <th scope="col">Total Amount</th>
                          <th scope="col">Position Taking</th>
                          <th scope="col">Commissions</th>
                          <th scope="col">Net Profit</th>
                        </tr>
                      )}

                    </thead>
                    <tbody>
                    {winloses.map((history, index) =>
                      <HistoryRow showDetail={showDetail} key={index} history={history} index={index++}/>
                    )}
                    {/*<tr style={{ fontWeight: 'bold', background: '#8f9ba6' }}>*/}
                      {/*<td colSpan={user.user_level != 'AGENT' ? 7 : 6}></td>*/}
                      {/*<td colSpan="2">Deposit Bonus:</td>*/}
                      {/*<td>{correctNumber(bonus.deposit)}</td>*/}
                    {/*</tr>*/}
                    <tr style={{ fontWeight: 'bold', background: '#8f9ba6' }}>
                      <td colSpan={user.user_level != 'AGENT' ? 7 : 6}></td>
                      <td colSpan="2">Real net profit</td>
                      <td
                        style={{ color: selectColor(realProfit) }}> {realProfit ? numeral(realProfit).format('0,0.00') : 0}</td>
                    </tr>
                    </tbody>
                  </Table>
                </React.Fragment>
                : <div>
                  No data is available...
                </div>
              }
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <strong>{selectedGame.toUpperCase()} Winlose Detail</strong>
            </CardHeader>
            <CardBody>
              {winlosesDetail.length ?
                <React.Fragment>
                  <Table responsive hover>
                    <thead>
                    <tr style={{ background: 'rgb(5 48 130)', color: 'white' }}>
                      <th scope="col">Player</th>
                      <th scope="col">Total Bet Amount</th>
                      <th scope="col">Total Turnover</th>
                      <th scope="col">Total WinLose</th>
                      <th scope="col">Loyalty bonus</th>
                      <th scope="col">Total Amount</th>
                      <th scope="col">Net Profit</th>
                    </tr>
                    </thead>
                    <tbody>
                    {winlosesDetail.map((history, index) =>
                      <WinloseDetailRow showHistories={showHistories} key={index} history={history} index={index++}/>
                    )}
                    </tbody>
                  </Table>
                </React.Fragment>
                : <div>
                  No data is available...
                </div>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default History