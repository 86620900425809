import React, { useState } from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'


const Input = ({options, classes, input: { input, meta }, label, ...rest }) => {
  const [openGameSelector, setOpenGameSelector] = useState(false)
  function handleCloseGameSelector() {
    setOpenGameSelector(false)
  }

  function handleOpenGameSelector() {
    setOpenGameSelector(true)
  }

  return (
    <FormControl margin="normal" required fullWidth={rest.fullWidth}>
      <InputLabel htmlFor="demo-controlled-open-select">{label}</InputLabel>
      <Select
        open={openGameSelector}
        onClose={handleCloseGameSelector}
        onOpen={handleOpenGameSelector}
        {...input}
        {...rest}
      >
        {options.map(({title, value}) => <MenuItem key={title} value={value}>{title}</MenuItem>)}
      </Select>
      {meta.touched && meta.error && (
        <div style={{ color: '#dc3545', marginTop: 5 }}>{meta.error}</div>
      )}
    </FormControl>
  )
}

export default Input