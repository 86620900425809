import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  InputGroup,
  Row,
} from "reactstrap";
import { useForm } from "react-final-form-hooks";
import Joi from "@hapi/joi";
import http from "service/http";
import validate from "service/form/validation";
import formCreateInputs from "service/form/create";
import { TextInput, useDialog } from "feature/Component";
import { useTranslation } from "react-i18next";

function EditUser(props) {
  const { t } = useTranslation();
  const {
    match: {
      params: { id },
    },
  } = props;
  const [user, setUser] = React.useState({ undefined });
  const [initialValues, updateInitialForm] = React.useState(undefined);
  const [point_parent, setPoint_parent] = useState(0);
  // console.log('point', point)
  const initialAsyncForm = async () => {
    const rs = await http.get({ path: `admin/users/${id}` });
    const parent = await http.get({ path: "me" });
    // const parentId = rs.user.parent_id
    // if(rs.user.parent_id !== 'admin'){
    //   const rs_listParents = await http.get({path:`admin/users`,params:{username:parentId}})
    //   const trueParent = rs_listParents.find(e=>e.username === parentId)
    //   setPoint_parent(trueParent.point)
    // }else{
    setPoint_parent(parent ? Number(parent.point) : 0);
    // }
    // const { point } = rs.user
    setUser(rs.user);
    updateInitialForm({ sharePoint: parent ? Number(parent.point) : undefined });
  };

  useEffect(() => {
    initialAsyncForm();
  }, []);
  return initialValues ? (
    <FormAsync
      initialAsyncForm={initialAsyncForm}
      point_parent={point_parent}
      t={t}
      {...props}
      user={user}
      initialValues={initialValues}
    />
  ) : (
    t("LOADING") + " ..."
  );
}

function FormAsync({
  initialValues,
  initialAsyncForm,
  point_parent,
  user,
  match: {
    params: { id },
  },
  t,
}) {
  const [Dialog, showDialogWithMessage] = useDialog({
    title: t("UPDATE_USER_SUCCESS"),
  });
  const [DialogError, showDialogErrorWithMessage] = useDialog({
    title: t("ERROR"),
    type: "error",
  });
  const onSubmit = async (payload) => {
    console.log(payload);
    try {
      await http.put({
        path: `users/transfer/point`,
        payload: {
          toId: Number(id),
          amount: payload.sharePoint,
        },
      });
      initialAsyncForm();
      showDialogWithMessage(t("YOU_REQUEST_SUBMITED_SUCCESS"));
    } catch (error) {
      showDialogErrorWithMessage(error.message);
    }
  };

  const schema = Joi.object().keys({
    sharePoint: Joi.number().min(0).max(point_parent).required(),
  });

  const { form, handleSubmit } = useForm({
    onSubmit,
    validate: validate(schema),
    initialValues,
  });

  const [sharePoint] = formCreateInputs(["sharePoint"], form);

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" md="6" lg="5">
            <form onSubmit={handleSubmit}>
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h1>
                      {t("TRANSFER_POINT")} to {user.username}
                    </h1>{" "}
                    <h3>Your Point: {point_parent}</h3>
                    <InputGroup className="mb-3"></InputGroup>
                    <InputGroup className="mb-4">
                      <TextInput
                        input={sharePoint}
                        type="text"
                        label={t("ENTER_POINT")}
                        fullWidth
                      />
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button type="submit" color="info" className="px-4">
                          {t("UPDATE")}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </form>
          </Col>
        </Row>
      </Container>
      <Dialog />
      <DialogError />
    </div>
  );
}

export default EditUser;
