import React, { useEffect, useState } from 'react';
import { Badge, Card, CardBody, CardHeader, Col, Row, Table, Button } from 'reactstrap';
import Pagination from "react-js-pagination";
import http from 'service/http';
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import { cloneDeep } from 'lodash'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'
import { FormControl, Fab } from '@material-ui/core'
import { Done, Clear } from '@material-ui/icons'
import { TextInput, useLoading, SelectInput, CheckInput, useDialog } from 'feature/Component'
import moment from 'moment';

const last5Weeks = moment().subtract(5, 'weeks').startOf('week').add(1, 'days');
const periods = []
for (let index = 1; index <= 5; index++) {
  const toMoment = cloneDeep(last5Weeks).add(index, 'weeks')
  const to = toMoment.format('YYYY-MM-DD hh:mm:ss')
  const from = toMoment.subtract(1, 'weeks').format('YYYY-MM-DD hh:mm:ss')
  periods.push({
    title: `${from} - ${to}`,
    value: {
      from, to
    }
  })
}

function DepositRow({deposit, updateDepositBonus}) {
  const {id, bonus_amount, updated_at, new_turnover, process_by, rolling_amount, status, turnover, username, transaction_id} = deposit;
  const getBadge = (status) => {
    return status === 'success' ? 'success' : 'warning';
  }
  return (

    <tr key={id}>
        <td>{id}</td>
        <td>{transaction_id}</td>
        <td>{username}</td>
        <td>{bonus_amount}</td>
        <td>{moment(updated_at).format('YY-MM-DD hh:mm')}</td>
        <td>{turnover}</td>
        <td>{new_turnover}</td>
        <td>{rolling_amount}</td>
        <td><Badge color={getBadge(status)}>{status}</Badge></td>
        <td>
        {status === 'pending' &&
          <React.Fragment>
            <Fab style={{height: 40, width: 40, marginRight: 5}} color="primary" aria-label="Edit" onClick={() => updateDepositBonus(id, 'accept')}>
              <Done />
            </Fab>
            <Fab style={{height: 40, width: 40}} color="secondary" aria-label="Edit" onClick={() => updateDepositBonus(id, 'reject')}>
              <Clear />
            </Fab>
          </React.Fragment>
        }
        </td>
        <td>{process_by}</td>
    </tr>
  )
}


function Deposit () {
  const itemsCountPerPage = 20
  const [activePage, updateActivePage] = useState(1)
  const [deposits, updateDeposits] = useState([])
  const [docs, updateDocs] = useState([])
  const [ loading, withLoading ] = useLoading()
  const [ErroDialog, showErrorDialogWithMessage] = useDialog({
    title: 'Deposit Bonus Error',
    type: 'error'
  })

  const loadDocs = () => {
    if(!deposits.length) return
    const from = (activePage - 1) * itemsCountPerPage
    const to = from + itemsCountPerPage
    const docs = deposits.slice(from, to)
    updateDocs(docs)
  }

  const onSubmit = async(params) => {
    const {
      period: { from: fromDate, to: toDate }, username, invalid, success, reject
    } = params;

    const status = [{ title: 'invalid', value: invalid}, { title: 'success', value: success}, { title: 'reject', value: reject}]
      .filter(({value}) => value)
      .map(({title}) => title)
    const query = {
      fromDate,
      toDate,
      username,
      status: status.toString()
    }

    const response = await withLoading(() => http.get({path: 'admin/deposits/bonus', params: query}))
    updateDeposits(response)
  }

  const reloadPage = () => document.getElementById("deposit-form") &&
    document.getElementById("deposit-form").dispatchEvent(new Event("submit", { cancelable: true }))

  const updateDepositBonus = async(id, status) => {
    try {
      await http.put({path: `admin/deposits/bonus/${id}`, payload: {status}});
      reloadPage()
    } catch (error) {
      showErrorDialogWithMessage(error.message)
    }
  }


  useEffect(() => {
    loadDocs()
  }, [deposits, activePage])

  const schema = Joi.object().keys({
    username: Joi.number(),
    period: Joi.object().required(),
    invalid: Joi.bool(),
    success: Joi.bool(),
    reject: Joi.bool()
  })

  const { form, handleSubmit } = useForm({
    onSubmit,
    validate: validate(schema),
    initialValues: {
      invalid: true
    }
  })

  const [username, period, invalid, success, reject] = formCreateInputs(['username', 'period', 'invalid', 'success', 'reject'], form)

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <form onSubmit={handleSubmit} noValidate id="deposit-form">
                <TextInput style={{marginRight: 15}} input={username} label='Username' />
                <SelectInput options={periods} input={period} style={{width: 400}} label='Select Period' />
                <div style={{marginTop: 20, marginLeft: 15, display: 'inline-block'}} >
                  <CheckInput input={invalid} label='Waiting' />
                  <CheckInput input={success} label='Success' />
                  <CheckInput input={reject} label='Reject' />
                </div>
                <FormControl style={{ marginTop: 30, marginLeft: 15}}>
                  {loading ? 'Loading ...' : <Button type='submit' color="info" className="px-4" >Search</Button>}
                </FormControl>
              </form>
            </CardHeader>
            { loading ? 'Loading ...'
              : <CardBody>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">TrxId</th>
                      <th scope="col">Username</th>
                      <th scope="col">Bonus</th>
                      <th scope="col">Updated at</th>
                      <th scope="col">Turnover</th>
                      <th scope="col">New turnover</th>
                      <th scope="col">Rolling amount</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                      <th scope="col">Process by</th>
                    </tr>
                  </thead>
                  <tbody>
                    {docs.map((deposit, index) =>
                      <DepositRow key={index} deposit={deposit} updateDepositBonus={updateDepositBonus}/>
                    )}
                  </tbody>
                </Table>
                <div>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={deposits.length}
                    pageRangeDisplayed={5}
                    onChange={updateActivePage}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </CardBody>
            }
          </Card>
        </Col>
      </Row>
      <ErroDialog/>
    </div>
  )
};

export default Deposit;