import React, { useState, useEffect } from 'react';
import { Tooltip, Button, TextField, Dialog, DialogContent, DialogTitle, DialogContentText, OutlinedInput,InputAdornment } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import http from 'service/http';
import store from 'store';
import validate from 'service/form/validation'
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import formCreateInputs from 'service/form/create'
import { useDialog, TextInput, useLoading } from 'feature/Component'
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next'
import LoadingUi from 'feature/Component/Loading/ui'
let user = store.get('user') || { level: '' };

function User({name, label, game_code, commissions, showModalPT, onChangeCommission }) {
  let isGame1 = commissions.level1.find(x => x.label == label)
  if(!isGame1) isGame1 = {game_code,comm:0, label}
  let isGame2 = commissions.level2.find(x => x.label == label)
  if(!isGame2) isGame2 = {game_code,comm:0, label}
  let isGame3 = commissions.level3.find(x => x.label == label)
  if(!isGame3) isGame3 = {game_code,comm:0, label}
  const [isInput, setIsInput] = useState({game1:false,game2:false,game3:false})

  return (
    <tr key={game_code + label + name}>
      <td><b>{label || name}</b></td>
      <td>
          <Tooltip title={"Edit " + game_code}>
            {/* <Button onClick={() => showModalPT({ level: 'level1', label: label || name, game_code, comm: isGame1.comm, commissions })} size="small" >
              {isGame1.comm} %
            </Button> */}
            {!isInput.game1?
            <Button onClick={() => setIsInput({...isInput,game1:true})} size="small" >
              {isGame1.comm} %
            </Button>:
             <TextField 
             defaultValue={`${isGame1.comm}`} 
             InputProps={{endAdornment: <InputAdornment position="start">%</InputAdornment>}}
             style={{width:'100px'}}
             autoFocus
             onChange={(e)=>onChangeCommission({ valueChange:e.target.value, level: 'level1', label: label || name, game_code, comm: isGame2.comm, commissions })}
             onBlur={()=>setIsInput({...isInput,game1:false})}
             />
            }
           
          </Tooltip>
        </td><td>
          <Tooltip title={"Edit " + game_code}>
            {/* <Button onClick={() => showModalPT({ level: 'level2', label: label || name, game_code, comm: isGame2.comm, commissions })} size="small" >
              {isGame2.comm} %
            </Button> */}
             {!isInput.game2?
            <Button onClick={() => setIsInput({...isInput,game2:true})} size="small" >
              {isGame2.comm} %
            </Button>:
             <TextField 
             defaultValue={`${isGame2.comm}`} 
             InputProps={{endAdornment: <InputAdornment position="start">%</InputAdornment>}}
             style={{width:'100px'}}
             autoFocus
             onChange={(e)=>onChangeCommission({ valueChange:e.target.value, level: 'level2', label: label || name, game_code, comm: isGame2.comm, commissions })}
             onBlur={()=>setIsInput({...isInput,game2:false})}
             />
            }
          </Tooltip>
        </td><td>
          <Tooltip title={"Edit " + game_code}>
            {/* <Button onClick={() => showModalPT({ level: 'level3', label: label || name, game_code, comm: isGame3.comm, commissions })} size="small" >
              {isGame3.comm} %
            </Button> */}
            {!isInput.game3?
            <Button onClick={() => setIsInput({...isInput,game3:true})} size="small" >
              {isGame3.comm} %
            </Button>:
             <TextField 
             defaultValue={`${isGame3.comm}`} 
             InputProps={{endAdornment: <InputAdornment position="start">%</InputAdornment>}}
             style={{width:'100px'}}
             autoFocus
             onChange={(e)=>onChangeCommission({ valueChange:e.target.value, level: 'level3', label: label || name, game_code, comm: isGame3.comm, commissions })}
             onBlur={()=>setIsInput({...isInput,game3:false})}
             />
            }
          </Tooltip>
        </td>
    </tr>
  )
}

function Users(props) {
  const { t } = useTranslation()
  user = {...props}
  const schema = Joi.object().keys({
    comm: Joi.number().min(0).max(100).required()
  })

  const [ptModal, updatePTModal] = useState({})
  const [defaultModal, updateDefaultModal] = useState({})
  const [loading, withLoading] = useLoading()
  const [respGames, setSespGames] = useState([])
  const [commUpdate, setCommUpdate] = useState(0)
  const [commissions, setCommissions] = useState({
    level1: [],
    level2: [],
    level3: [],
  })
  // console.log(commissions)
  const [ErroDialog, showErrorDialogWithMessage] = useDialog({
    title: 'Error',
    type: 'error'
  })
  const [DialogMSG, showDialogWithMessage] = useDialog({
    title: t('UPDATE_SUCCESS')
  })
  const showModalDefault = data => {
    setCommUpdate(data.comm)
    updateDefaultModal(data)
  }
  const showModalPT = data => {
    // console.log(ptModal, data)
    updatePTModal(data)
    // console.log(ptModal)
  }
  const showModalPT1 = (t) => {
    showErrorDialogWithMessage(t('PLEASE_CONTACT_ADMIN'))
  }
  const onSubmit = async ({comm}) => {
    const newComm = {...commissions}
    const isExist = newComm[ptModal.level].find(x => x.label == ptModal.label)
    if(!isExist) newComm[ptModal.level].push({game_code: ptModal.game_code, label: ptModal.label, comm: Number(comm)})
    else newComm[ptModal.level] = newComm[ptModal.level].map(i => {
      if(i.label == ptModal.label) i.comm = Number(comm)
      return i
    })
    setCommissions(newComm)
    // console.log(newComm, comm, ptModal)

  }

  const onChangeCommission = async ({valueChange,comm,level,game_code,label,commissions}) => {
    const isContain = commissions[level].find(e=>e.label === label)
    // console.log(valueChange, label, isContain)
    if(!isContain){
      const copyCommissions = JSON.parse(JSON.stringify(commissions))
      copyCommissions[level].push({
        game_code,
        label,
        comm: Number(valueChange)
      })
      return setCommissions(copyCommissions)
    }
    const arrayChange = commissions[level].map(e=>{
      if(e.label === label) {
        return {
          ...e,
          comm: Number(valueChange)
        }
      }
      return e
    })
    setCommissions({
      ...commissions,
      [level]: arrayChange
    })
  }

  const saveComm = async () => {
    await withLoading(() => http.put({
      path: `admin/default/commissions`, payload: {
        percen: JSON.stringify(commissions)
      }
    }).then(showDialogWithMessage(t('YOU_REQUEST_SUBMITED_SUCCESS'))))

  }
  const run = async () => {
    const [games, configs] = await Promise.all([http.get({ path: 'games' }).then(rs => rs.filter(x => x.isActive == 1)),http.get({ path: 'config' })])
    setSespGames(games.map(g => {
      if(!g.label) g.label = g.name
      return g
    }))
    setCommissions(JSON.parse(configs.commissions))
  }

  useEffect(() => {
    run()
  }, [])

  const { form, handleSubmit } = useForm({
    validate: validate(schema),
    onSubmit,
    initialValues: {
      comm: ptModal.comm
    }
  })
  const [comm] = formCreateInputs(['comm'], form)

  return (
    <div className="animated fadeIn">
      {loading && <LoadingUi/>}
      <Row>
        <Col md="12" lg="12">
          <Card>
            <CardHeader>
              <h6>
                Account: {user.username}
              </h6>
              <div style={{float: 'right'}}>
                <Button className="success" onClick={saveComm} variant="contained">{t('SAVE')}</Button>
              </div>
            </CardHeader>
            <CardBody>
              <Table striped responsive hover style={{ textAlign: 'center' }}>
                <thead>
                <tr>
                  <th scope="col" style={{ width: '15%' }}>{t('GAMES')}</th>
                  <th scope="col" style={{ width: '15%' }}>{t('LEVEL')} 1</th>
                  <th scope="col" style={{ width: '15%' }}>{t('LEVEL')} 2</th>
                  <th scope="col" style={{ width: '15%' }}>{t('LEVEL')} 3</th>
                </tr>
                </thead>
                <tbody>
                {respGames.map((item, index) =>
                  <User key={item.game_code + item.label + item.name} commissions={commissions} {...item} showModalPT={showModalPT} t={t} showModalPT1={showModalPT1} onChangeCommission={onChangeCommission}/>
                )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Dialog open={!isEmpty(ptModal)} onClose={() => updatePTModal({})} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('UPDATE_PERCEN')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b style={{color: 'green'}}>{ptModal.label}</b>
          </DialogContentText>
          <form id="withdraw-form" onSubmit={handleSubmit} noValidate>
            <TextInput style={{ marginRight: 15 }} input={comm} type="number" max="100" label='Percentage' />
            <Button type="submit" variant="contained">{t('UPDATE')}</Button>
          </form>
          {ptModal.error
            ? <p style={{ color: 'red' }}>{ptModal.error}</p>
            : null}
        </DialogContent>
      </Dialog>
      <ErroDialog/>
      <DialogMSG/>
    </div>
  )
}

export default Users;
