import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, Col, Row, Table, Button } from 'reactstrap'
import http from 'service/http'
import { useForm } from 'react-final-form-hooks'
import { TextInput, useLoading, SelectInput } from 'feature/Component'
import numeral from 'numeral'
import store from 'store'
import './style.scss'
import { FormControl } from '@material-ui/core'
import moment from 'moment'
import formCreateInputs from 'service/form/create'
import { useTranslation } from 'react-i18next'

const DEFAULT_FROM_DATE = moment().format('YYYY-MM-DD')
const DEFAULT_TO_DATE = moment().format('YYYY-MM-DD')
let user = store.get('user') || { level: '' }

const correctNumber = amount => numeral(amount).format('0,0.000')

function HistoryRow({ account, date, banker }) {

  return (
    <tr>
      <td style={{textAlign: 'left'}}><a href={'/report/player/' + account._id.username + '?fromDate=' + date.fromDate + '&toDate=' + date.toDate + '&banker=' + banker}><b>{account._id.username}</b></a></td>
      <td className="downline">{correctNumber(account.totalTurnover)}</td>
      <td className="downline">{correctNumber(account.totalComm)}</td>
      <td className="downline" style={{
        color: account.totalWinLose >= 0 ? 'green' : 'red'
      }}>{correctNumber(account.totalWinLose)}</td>
      <td className="downline" style={{
        color: account.total >= 0 ? 'green' : 'red'
      }}>{correctNumber(account.total)}</td>
      <td className="upline">{correctNumber(account.agentSale)}</td>
      <td className="upline">{correctNumber(account.totalAgentComm)}</td>
      <td className="upline" style={{
        color: account.agentWinlose >= 0 ? 'green' : 'red'
      }}>{correctNumber(account.agentWinlose)}</td>
      <td className="upline" style={{
        color: account.agentTotal >= 0 ? 'green' : 'red'
      }}>{correctNumber(account.agentTotal)}</td>
      <td className="company" style={{
        color: account.companyTotal >= 0 ? 'green' : 'red'
      }}>{correctNumber(account.companyTotal)}</td>
      <td>0</td>
      <td>0</td>
    </tr>
  )
}

function History(props) {
  const { t } = useTranslation()
  let total1 = {
    turnover: 0,
    downline: {
      sale: 0,
      comm: 0,
      winlose: 0,
      total: 0,
    },
    agent: {
      sale: 0,
      comm: 0,
      winlose: 0,
      total: 0,
    },
    companyTotal: 0,
    totalNet: 0,
  }
  user = props
  const [initialInputs, updateInitialInputs] = useState({})
  const onSubmit = async (params) => {
    // console.log(params)
    loadReposrt(params)

  }
  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: initialInputs
  })
  const [loading] = useLoading()
  const [fromDate, toDate] = formCreateInputs(['fromDate', 'toDate'], form)
  const [ accounts, updateAccounts] = useState({})
  const [ total, updateTotal] = useState(total1)
  const { user_level: role, email, username: agentUsername } = store.get('user')
  const query = new URLSearchParams(props.location.search);


  useEffect(() => {
    loadReposrt({
      fromDate: query.get('fromDate'),
      toDate: query.get('toDate'),

    })
  }, [])
  const loadReposrt = (date) => {
    http.get({ path: `admin/report/agent/` + props.match.params.id, params: {...date, banker: query.get('banker')}}).then(rs => {
      // console.log(rs)
      const newAcc = rs
      newAcc.winlose.map(a => {
        total1 = {
          turnover: total1.turnover + a.totalTurnover,
          downline: {
            sale: total1.downline.sale + a.totalTurnover,
            comm: total1.downline.comm + a.totalComm,
            winlose: total1.downline.winlose + a.totalWinLose,
            total: total1.downline.total + a.totalComm + a.totalWinLose,
          },
          agent: {
            sale: total1.agent.sale + a.agentSale,
            comm: total1.agent.comm + a.totalAgentComm,
            winlose: total1.agent.winlose + a.agentWinlose,
            total: total1.agent.total + a.agentTotal,
          },
          companyTotal: total1.companyTotal + a.companyTotal,
          totalNet: 0,
        }
      })
      updateAccounts(newAcc);
      updateTotal(total1)
      updateInitialInputs({
        fromDate: date.fromDate,
        toDate: date.toDate,
      })
    })
  }

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <form onSubmit={handleSubmit}>
                <TextInput style={{ marginLeft: 15, width: 200 }} input={fromDate} label={t('FROM')} type='date'
                           InputLabelProps={{
                             shrink: true
                           }}/>
                <TextInput style={{ marginLeft: 15, width: 200 }} input={toDate} label={t('TO')} type='date'
                           InputLabelProps={{
                             shrink: true
                           }}/>
                <FormControl style={{ marginTop: 30, marginLeft: 15 }}>
                  {loading ? t('LOADING') + ' ...' : <Button type='submit' color="info" className="px-4">{t('SEARCH')}</Button>}
                </FormControl>
              </form>
            </CardHeader>
            <CardBody>

              <React.Fragment>
                <Table responsive hover className="fullReportTable">
                  <thead>

                  <tr style={{ background: 'rgb(5 48 130)', color: 'white' }}>

                    <th scope="col" rowSpan="2" className="rowSpan">{props.match.params.id}</th>
                    <th scope="col" colSpan="4" className="colSpan">{t('PLAYER')}</th>
                    <th scope="col" colSpan="4" className="colSpan">{t('AGENT')}</th>
                    <th rowSpan="2" className="rowSpan">{t('COMPANY_TOTAL')}</th>
                    <th scope="col" rowSpan="2" className="rowSpan">{t('SHARE_PERCEN')}</th>
                    <th scope="col" rowSpan="2" className="rowSpan">{t('TOTAL_NET')}</th>
                  </tr>
                  <tr>
                    <th>{t('TURNOVER')}</th>
                    <th>{t('COMMISSIONS')}</th>
                    <th>{t('WINLOSE')}</th>
                    <th>{t('TOTAL')}</th>
                    <th>{t('SALE')}</th>
                    <th>{t('COMMISSIONS')}</th>
                    <th>{t('WINLOSE')}</th>
                    <th>{t('TOTAL')}</th>
                  </tr>
                  </thead>
                  {accounts.agent ?
                    <tbody>
                    {accounts.winlose.map((account, i) =>
                      <HistoryRow account={account} date={initialInputs} index={i++} key={i} banker={query.get('banker')}/>
                    )}
                    <tr style={{ fontWeight: 'bold', background: '#8f9ba68a' }}>
                      <td>Total</td>
                      <td>{correctNumber(total.turnover)}</td>
                      <td>{correctNumber(total.downline.comm)}</td>
                      <td style={{
                        color: total.downline.winlose >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.downline.winlose)}</td>
                      <td style={{
                        color: total.downline.total >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.downline.total)}</td>
                      <td>{correctNumber(total.agent.sale)}</td>
                      <td>{correctNumber(total.agent.comm)}</td>
                      <td style={{
                        color: total.agent.winlose >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.agent.winlose)}</td>
                      <td style={{
                        color: total.agent.total >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.agent.total)}</td>
                      <td style={{
                        color: total.companyTotal >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.companyTotal)}</td>
                      <td>0</td>
                      <td>{total.totalNet}</td>
                    </tr>

                    </tbody>
                    : <tbody>
                    <tr>
                      <td>{t('NO_DATA')}</td>
                    </tr>
                    </tbody>
                  }
                </Table>
              </React.Fragment>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </div>
  )
}

export default History