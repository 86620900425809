import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table, Button } from 'reactstrap';
import http from 'service/http';
import { useForm } from 'react-final-form-hooks'
import formCreateInputs from 'service/form/create'
import moment from 'moment';
import { FormControl } from '@material-ui/core'
import { TextInput, useLoading } from 'feature/Component'

const DEFAULT_FROM_DATE = moment().startOf('month').format('YYYY-MM-DD')

function HistoryRow({history, index}) {
  const { game, username, bonus_amount, turnover, new_turnover, rolling_amount, status } = history
  return (
    <tr key={index} style={{background: (game === 'Grand Total') ? '#e4e5e6' : 'inherit', fontWeight: (game === 'Grand Total') ? 'bold' : 'initial', cursor: 'pointer' }}>
      <td style={{fontWeight: 'bold'}}>{game}</td>
      <td>{username}</td>
      <td>{bonus_amount}</td>
      <td>{turnover}</td>
      <td>{new_turnover}</td>
      <td>{rolling_amount}</td>
      <td>{status}</td>
    </tr>
  )
}

function History ({history}) {
  const [loading, withLoading] = useLoading()
  const [bonus, updateBonus] = useState({})

  const onSubmit = async(params) => {
    try {
      const {
        fromDate, toDate, username
      } = params;
      const query = {
        fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD 00:00:00') : undefined,
        toDate: toDate ? moment(toDate).format('YYYY-MM-DD 23:59:59') : undefined,
        username
      }

      const loyalties = await withLoading(() => http.get({path: `admin/report/bonus/loyalty`, params: query}))
      updateBonus(loyalties)
    } catch (error) {
      console.log(error)
    }
  }

  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: {
      fromDate: DEFAULT_FROM_DATE
    }
  })

  const [username, fromDate, toDate] = formCreateInputs(['username', 'fromDate', 'toDate'], form)

  useEffect(() => {
    onSubmit({
      fromDate: DEFAULT_FROM_DATE
    })
  }, [])

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <form onSubmit={handleSubmit}>
                <TextInput input={username} label='Username' />
                <TextInput style={{marginLeft: 15, width: 200}} input={fromDate} label='From Date' type='date' InputLabelProps={{
                    shrink: true,
                }}/>
                <TextInput style={{marginLeft: 15, width: 200}} input={toDate} label='To Date' type='date' InputLabelProps={{
                    shrink: true,
                }}/>
                <FormControl style={{ marginTop: 30, marginLeft: 15}}>
                  {loading ? 'Loading...' : <Button type='submit' color="primary" className="px-4" >Search</Button>}
                </FormControl>
              </form>
            </CardHeader>
            <CardBody>
              {bonus.length ?
                <React.Fragment>
                  <Table responsive hover>
                    <thead>
                      <tr style={{ background: 'rgb(5 48 130)', color: 'white'}}>
                        <th scope="col">Game Name</th>
                        <th scope="col">Username</th>
                        <th scope="col">Bonus Amount</th>
                        <th scope="col">Turnover</th>
                        <th scope="col">New Turnover</th>
                        <th scope="col">Rolling Amount</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bonus.map((history, index) =>
                        <HistoryRow key={index} history={history} index={index ++}/>
                      )}
                    </tbody>
                  </Table>
                </React.Fragment>
                : <div>
                  No data is available...
                </div>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
};

export default History;