import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Button,
} from "reactstrap";
import http from "service/http";
import { useForm } from "react-final-form-hooks";
import { TextInput, useLoading, SelectInput } from "feature/Component";
import { FormControl } from "@material-ui/core";
import formCreateInputs from "service/form/create";
import numeral from "numeral";
import store from "store";
import "./style.scss";
import moment from "moment";
import { useTranslation } from "react-i18next";
import LoadingUi from "feature/Component/Loading/ui";

// const DEFAULT_FROM_DATE = moment().startOf('month').format('YYYY-MM-DD')
const DEFAULT_FROM_DATE = moment().format("YYYY-MM-DD");
const DEFAULT_TO_DATE = moment().format("YYYY-MM-DD");
let user = store.get("user") || { level: "" };

const correctNumber = (amount) => numeral(amount).format("0,0.0000");
const calculatorSelfProfit = (partner) => {
  const { report } = partner;
  let selfProfit = 0;
  if (partner.role !== "PLAYER" && report.winlose) {
    selfProfit = report.winlose.parentTotal + report.winlose.commNewReport;
  } else if (partner.role === "PLAYER" && report.winlose) {
    selfProfit = report.winlose.agentWinlose + report.winlose.totalComm;
  }
  const downlineRecieverNewReport =
    partner.role !== "PLAYER" ? report.winlose.downlineRecieverNewReport : 0;
  const uplinePayableNewReport =
    partner.role !== "PLAYER" ? report.winlose.uplinePayableNewReport : 0;
  return {
    selfProfit,
    downlineRecieverNewReport,
    uplinePayableNewReport,
  };
};
function HistoryRow({ partner, date, banker }) {
  // console.log(partner)
  const { report } = partner;
  const { selfProfit, downlineRecieverNewReport, uplinePayableNewReport } =
    calculatorSelfProfit(partner);
  const playerTotal = partner.role === "PLAYER" ? 0 : report.winlose.downlineWl;
  const playerWinlose =
    partner.role === "PLAYER"
      ? report.winlose.downlineWl
      : report.winlose.winlose;
  const playerComm =
    partner.role === "PLAYER"
      ? report.winlose.downlineComm
      : report.winlose.comm;
  // console.log(downlineReciever)
  return (
    <tr>
      <td style={{ textAlign: "left" }}>
        {partner.role != "PLAYER" && (
          <a
            href={
              "/reports-new-partner?parent=" +
              partner.username +
              "&fromDate=" +
              date.fromDate +
              "&toDate=" +
              date.toDate +
              "&banker=" +
              partner.lotto97Banker
            }
          >
            <b>{partner.username}</b>
          </a>
        )}
        {partner.role == "PLAYER" && (
          <a
            href={
              "/report-new/player/" +
              partner.username +
              "?fromDate=" +
              date.fromDate +
              "&toDate=" +
              date.toDate +
              "&banker=" +
              banker
            }
          >
            <b>{partner.username}</b>
          </a>
        )}
      </td>
      <td style={{ color: "green" }}>{correctNumber(report.depositAmount)}</td>
      <td style={{ color: "red" }}>{correctNumber(report.withdrawAmount)}</td>
      <td>{correctNumber(report.amountRabat)}</td>
      <td>{correctNumber(report.winlose.totalBetAmount)}</td>
      <td>{correctNumber(report.winlose.totalTurnover)}</td>

      <td>{correctNumber(playerWinlose)}</td>
      <td>{correctNumber(playerComm)}</td>
      <td>{correctNumber(playerTotal)}</td>
      <td>{correctNumber(downlineRecieverNewReport)}</td>
      <td>
        {correctNumber(
          report.winlose.parentWinlose || report.winlose.agentWinlose
        )}
      </td>
      <td>
        {correctNumber(
          report.winlose.commNewReport ||
            (report.winlose.uplineWinlose
              ? report.winlose.uplineWinlose.commNewReport
              : 0)
        )}
      </td>
      <td>{correctNumber(playerWinlose)}</td>
      <td>{correctNumber(uplinePayableNewReport)}</td>
    </tr>
  );
}

function History(props) {
  const { t } = useTranslation();

  user = props;
  const query = new URLSearchParams(props.location.search);
  const [loading, withLoading] = useLoading();
  const [partners, updatePartners] = useState([]);
  const [total, updateTotal] = useState(null);
  const { user_level: role, email, lotto97Banker } = store.get("user");
  const [initialInputs, updateInitialInputs] = useState({});
  const onSubmit = async (params) => {
    // console.log(params)
    loadReposrt(params);
  };
  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: initialInputs,
  });
  const [fromDate, toDate] = formCreateInputs(["fromDate", "toDate"], form);

  useEffect(() => {
    loadReposrt({
      fromDate: query.get("fromDate") || DEFAULT_FROM_DATE,
      toDate: query.get("toDate") || DEFAULT_TO_DATE,
    });
  }, []);
  const calTotal = (rs) => {
    return rs
      .map((r) => {
        const {
          selfProfit,
          downlineRecieverNewReport,
          uplinePayableNewReport,
        } = calculatorSelfProfit(r);
        r.report.winloseAmount = Number(r.report.winlose.winlose || 0);
        r.report.comm = Number(r.report.winlose.comm || 0);
        r.report.downlineWl = Number(r.report.winlose.downlineWl || 0);
        r.report.downlineComm = Number(r.report.winlose.downlineComm || 0);
        r.report.selfProfit = selfProfit;
        r.report.downlineRecieverNewReport = downlineRecieverNewReport;
        r.report.uplinePayableNewReport = uplinePayableNewReport;
        r.report.winlose.playerComm =
          r.report.winlose.playerComm || r.report.winlose.totalComm;
        r.report.winlose.playerTotal =
          r.report.winlose.playerTotal || r.report.winlose.total;
        r.report.winlose.parentWinlose =
          r.report.winlose.parentWinlose || r.report.winlose.agentWinlose;
        r.report.winlose.commNewReport =
          r.report.winlose.commNewReport ||
          (r.report.winlose.uplineWinlose
            ? r.report.winlose.uplineWinlose.commNewReport
            : 0);
        return r;
      })
      .reduce((r, s) => {
        return {
          report: {
            amountRabat: r.report.amountRabat + s.report.amountRabat,
            depositAmount: r.report.depositAmount + s.report.depositAmount,
            withdrawAmount: r.report.withdrawAmount + s.report.withdrawAmount,
            selfProfit: r.report.selfProfit + s.report.selfProfit,
            winloseAmount: Number(r.report.winlose.winlose || 0) + Number(s.report.winlose.winlose || 0),
            comm: Number(r.report.winlose.comm || 0) + Number(s.report.winlose.comm || 0),
            downlineWl: Number(r.report.winlose.downlineWl || 0) + Number(s.report.winlose.downlineWl || 0),
            downlineComm: Number(r.report.winlose.downlineComm || 0) + Number(s.report.winlose.downlineComm || 0),
            uplinePayableNewReport:
              r.report.uplinePayableNewReport + s.report.uplinePayableNewReport,
            downlineRecieverNewReport:
              r.report.downlineRecieverNewReport +
              s.report.downlineRecieverNewReport,
            winlose: {
              totalBetAmount:
                r.report.winlose.totalBetAmount +
                s.report.winlose.totalBetAmount,
              totalTurnover:
                r.report.winlose.totalTurnover + s.report.winlose.totalTurnover,
              playerWinlose:
                r.report.winlose.playerWinlose +
                (s.report.winlose.playerWinlose || s.report.winlose.winlose),
              playerComm:
                r.report.winlose.playerComm + s.report.winlose.playerComm,
              playerTotal:
                r.report.winlose.playerTotal + s.report.winlose.playerTotal,
              parentWinlose:
                r.report.winlose.parentWinlose + s.report.winlose.parentWinlose,
              commNewReport:
                r.report.winlose.commNewReport + s.report.winlose.commNewReport,
            },
          },
        };
      });
  };
  const loadReposrt = (date) => {
    // console.log(date)
    withLoading(() =>
      http
        .get({
          path: `admin/new-report/`,
          params: {
            ...date,
            banker: query.get("banker") || user.lotto97Banker || undefined,
            parent: query.get("parent") || undefined,
          },
        })
        .then((rs) => {
          if (rs[0]) {
            updateTotal(calTotal(rs));
            updatePartners(rs);
          }
          updateInitialInputs({
            fromDate: date.fromDate,
            toDate: date.toDate,
          });
        })
    );
  };

  return (
    <div className="animated fadeIn">
      {loading && <LoadingUi />}
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <form onSubmit={handleSubmit}>
                <TextInput
                  style={{ marginLeft: 15, width: 200 }}
                  input={fromDate}
                  label={t("FROM")}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextInput
                  style={{ marginLeft: 15, width: 200 }}
                  input={toDate}
                  label={t("TO")}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormControl style={{ marginTop: 30, marginLeft: 15 }}>
                  {loading ? (
                    t("LOADING") + " ..."
                  ) : (
                    <Button type="submit" color="primary" className="px-4">
                      {t("SEARCH")}
                    </Button>
                  )}
                </FormControl>
              </form>
            </CardHeader>
            <CardBody>
              <React.Fragment>
                <Table responsive hover className="fullReportTable">
                  <thead>
                    <tr style={{ background: "rgb(5 48 130)", color: "white" }}>
                      <th scope="col">{t("USERNAME")}</th>
                      <th scope="col">{t("DEPOSITS")}</th>
                      <th scope="col">{t("WITHDRAW")}</th>
                      <th scope="col">{t("RABAT_BONUS")}</th>
                      <th scope="col">{t("BET_AMOUNT")}</th>
                      <th scope="col">{t("TURNOVER")}</th>
                      <th scope="col">{t("WINLOSE")}</th>
                      <th>{t("COMM")}</th>
                      <th>{t("PLAYER_PROFIT")}</th>
                      <th>{t("DOWNLINE_RECEIIVABLE")}</th>
                      <th>{t("SHARE_WINLOSE")}</th>
                      <th>{t("SHARE_COMM")}</th>
                      <th>{t("SELF_WINLOSE")}</th>
                      <th>{t("UPLINE_PAYABLE")}</th>
                    </tr>
                  </thead>
                  {partners.length ? (
                    <tbody>
                      {partners.map((partner, i) => {
                        // console.log(partner)
                        return (
                          <HistoryRow
                            partner={partner}
                            date={initialInputs}
                            index={i++}
                            key={i}
                            banker={query.get("banker")}
                          />
                        );
                      })}
                      {total && (
                        <tr
                          style={{
                            fontWeight: "bold",
                            background: "#8f9ba68a",
                          }}
                        >
                          <td>Total</td>
                          <td>{correctNumber(total.report.depositAmount)}</td>
                          <td>{correctNumber(total.report.withdrawAmount)}</td>
                          <td>{correctNumber(total.report.amountRabat)}</td>
                          <td>
                            {correctNumber(total.report.winlose.totalBetAmount)}
                          </td>
                          <td>
                            {correctNumber(total.report.winlose.totalTurnover)}
                          </td>
                          <td>
                            {correctNumber(partners.length > 0 && partners[0].role === "PLAYER" ? total.report.downlineWl : total.report.winloseAmount)}
                          </td>
                          <td>
                            {correctNumber(partners.length > 0 && partners[0].role === "PLAYER" ? total.report.downlineComm : total.report.comm)}
                          </td>
                          <td>
                            {correctNumber(
                              partners.length > 0 && partners[0].role === "PLAYER"
                                ? 0
                                : total.report.downlineWl
                            )}
                          </td>
                          <td>
                            {correctNumber(
                              total.report.downlineRecieverNewReport
                            )}
                          </td>
                          <td>
                            {correctNumber(total.report.winlose.parentWinlose)}
                          </td>
                          <td>
                            {correctNumber(total.report.winlose.commNewReport)}
                          </td>
                          <td>{correctNumber(partners.length > 0 && partners[0].role === "PLAYER" ? total.report.downlineWl : total.report.winloseAmount)}</td>
                          <td>
                            {correctNumber(total.report.uplinePayableNewReport)}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td>{t("NO_DATA")}</td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </React.Fragment>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default History;
