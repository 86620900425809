import React from 'react';
import { FormGroup, FormControlLabel, Checkbox, DialogTitle, DialogContent, Dialog } from '@material-ui/core'
import { Card, CardBody, CardGroup, Col, Container, Row, Table } from 'reactstrap'
import http from 'service/http'
import { useDialog } from 'feature/Component'
import { useTranslation } from 'react-i18next'
import ReactJson from 'react-json-view'
import config from '../../config'

function CreateAgent(props) {
  const { t } = useTranslation()
  const [games, updateGames] = React.useState([])
  const [shownJson, updateShownJson] = React.useState(false)
  const [gameDetail, updateGameDetail] = React.useState({})
  const [DialogError, showDialogErrorWithMessage] = useDialog({
    title: 'Games Setting Error',
    type: 'error'
  })

  const handleChange = code => async event => {
    try {
      await http.put({ path: `admin/config/game/${code}`, payload: {
        isActive: Number(event.target.checked)
      }})
      initialGames()
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }

  const initialGames = async () => {
    try {
      const gameRsp = await http.get({ path: `games` })
      const unique = [...new Map(gameRsp.map(item => [item.name, item])).values()]
      unique.map(i => {
        if(i.id==23) i.name='AWC'
        return i
      })
      updateGames(unique)
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }
  const showDetails = payload => {
    return;
    updateGameDetail(payload)
    updateShownJson(true)
  }
  React.useEffect(() => {
    initialGames()
  }, [])

  return (
    <div className="app flex-row">
      <Container>
        <Row>
          <Col md="12">
            <CardBody>

              <React.Fragment>
                <Table responsive hover style={{textAlign: 'center'}}>
                  <thead>

                    <tr style={{ background: 'rgb(5 48 130)', color: 'white' }}>

                      <th scope="col" rowSpan="2" className="rowSpan">{t('GAMES')}</th>
                      <th scope="col" rowSpan="2" className="rowSpan">{t('STATUS')}</th>

                    </tr>

                  </thead>
                  <tbody>
                    
                  {games.filter(x => x.market === config.currency).map((game) =>
                    <tr onClick={() => showDetails(game)}>
                      <td>{game.name}</td>
                      <td>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={game.isActive}
                              onChange={handleChange(game.id)}
                              value="checkedB"
                              color="primary"
                            />
                          }
                        />
                      </td>
                    </tr>
                  )}
                  </tbody>
                </Table>
              </React.Fragment>
            </CardBody>
          </Col>
        </Row>
      </Container>
      <DialogError/>
      <Dialog
        open={shownJson}
        onClose={() => updateShownJson(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{t('BETTING_DETAIL')}</DialogTitle>
        <DialogContent>
          <ReactJson src={gameDetail} displayDataTypes={false} enableClipboard={true}/>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default CreateAgent
