import React, { useState, useEffect } from 'react';
import { Fab, Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import { Card, CardBody, CardHeader, Col, Row, Table, InputGroup } from 'reactstrap';
import http from 'service/http';
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'
import { TextInput, useDialog, SelectInput }  from 'feature/Component'

function BonusRow({ data: { bonus: name, config }, bonus, updateFormConfig, initialBonus, showDialogErrorWithMessage }) {
  const handleClick = () => {
    updateFormConfig({
      open: true,
      config, bonus,
      name,
      updateFormConfig,
      initialBonus,
      showDialogErrorWithMessage
    })
  }
  const { percen, isActive, rolling, timeClam } = config
  return (
    <tr key={name.toString()}>
      <td style={{fontWeight: 'bold', color: 'rgb(5 48 130)'}}>{name}</td>
      <td>{percen}</td>
      <td>{isActive ? 'ON' : 'OFF'}</td>
      <td>{rolling}</td>
      <td>{timeClam}</td>
      <td>
        <Fab style={{height: 40, width: 40}} color='primary' aria-label="Edit" onClick={handleClick}>
          <Edit/>
        </Fab>
      </td>
    </tr>
  )
}

function DialogForm ({config, bonus, name, open = false, updateFormConfig, initialBonus, showDialogErrorWithMessage}) {
  const schema = Joi.object().keys({
    percen: Joi.number(),
    isActive: Joi.number(),
    rolling: Joi.number(),
    timeClam: Joi.number()
  })

  const onSubmit = async (payload) => {
    try {
      await http.put({path: 'admin/config/bonus', payload: {
        bonus: {
          ...bonus,
          [name]: {
            ...bonus[name],
            ...payload
          }
        }
      }})
      handleClose()
      initialBonus()
    } catch (error) {
      handleClose()
      showDialogErrorWithMessage(error.message)
    }
  }

  const { form, handleSubmit } = useForm({
    onSubmit,
    validate: validate(schema),
    initialValues: {
      ...config,
      isActive: String(config.isActive)
    }
  })

  const handleClose = () => updateFormConfig({open: false})

  const [percen, isActive, rolling, timeClam] = formCreateInputs(['percen', 'isActive', 'rolling', 'timeClam'], form)
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <form style={{width: 500}} onSubmit={handleSubmit} noValidate>
        <DialogTitle id="form-dialog-title">{name} config</DialogTitle>
        <DialogContent>
          <InputGroup className="mb-6">
            <TextInput input={percen} label='Percentage' fullWidth/>
          </InputGroup>
          <InputGroup className="mb-6">
            <SelectInput options={[{
                title: 'Active',
                value: '1'
              }, {
                title: 'Inactive',
                value: '0'
              }]} input={isActive} label='Enter status'/>
          </InputGroup>
          <InputGroup className="mb-6">
            <TextInput input={rolling} label='Rolling amount' fullWidth/>
          </InputGroup>
          <InputGroup className="mb-6">
            <TextInput input={timeClam} label='Time Claim' fullWidth/>
          </InputGroup>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

function Bonus ({history}) {
  const [bonus, updateBonus] = useState([])
  const [rawBonus, updateRawBonus] = useState({})
  const [formConfig, updateFormConfig] = useState({})
  const [DialogError, showDialogErrorWithMessage] = useDialog({
    title: 'Update Bonus Error',
    type: 'error'
  })

  const initialBonus = async () => {
    const { bonus } = await http.get({path: 'config/bonus'})
    const bonusJson = JSON.parse(bonus);
    updateRawBonus(bonusJson)
    const bonusArray = Object.keys(bonusJson).map(item => ({
      bonus: item,
      config: bonusJson[item]
    }))
    updateBonus(bonusArray)
  }

  useEffect(() => {
    initialBonus()
  }, [])

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <span>Bonus</span>
            </CardHeader>
            <CardBody>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Percent</th>
                    <th scope="col">Active</th>
                    <th scope="col">Rolling</th>
                    <th scope="col">Time Claim</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {bonus.map((item, index) =>
                    <BonusRow key={index} history={history} data={item} updateFormConfig={updateFormConfig} bonus={rawBonus} initialBonus={initialBonus} showDialogErrorWithMessage={showDialogErrorWithMessage}/>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DialogError/>
      {formConfig.open && <DialogForm {...formConfig} />}
    </div>
  )
}

export default Bonus;
