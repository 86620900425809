import React from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, InputGroup, Row } from 'reactstrap';
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import http from 'service/http'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'
import { useDialog, TextInput, useLoading } from 'feature/Component'
import { useTranslation } from 'react-i18next'

function CreateAgent({ history }) {
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading()
  const [Dialog, showDialogWithMessage] = useDialog({
    title: t('CREATE_STAFF_SUCCESS')
  })
  const [DialogError, showDialogErrorWithMessage] = useDialog({
    title: t('CREATE_STAFF_ERROR'),
    type: 'error'
  })
  const onSubmit = async payload => {
    try {
      const submit = {
        ...payload,
        user_account_no: payload.mobile_phone,
      }
      await withLoading(() => http.post({ path: `admin/agent/cs`, payload: submit }))
      showDialogWithMessage(t('YOU_REQUEST_SUBMITED_SUCCESS'))
      history.push('/users')
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }

  const schema = Joi.object().keys({
    username: Joi.string().required(),
    password: Joi.string().required()
      .regex(/^[a-zA-Z0-9]{3,30}$/),
    fullname: Joi.string(),
    mobile_phone: Joi.string(),
  })

  const { form, handleSubmit } = useForm({
    onSubmit,
    validate: validate(schema)
  })

  const [username, password, fullname, mobile_phone] = formCreateInputs(['username', 'password', 'fullname', 'mobile_phone'], form)

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <form onSubmit={handleSubmit}>
              <CardGroup>
              <Card className="p-6">
                <CardBody>
                  <h1>{t('CREATE_STAFF')}</h1>
                  <InputGroup className="mb-4">
                    <TextInput fullWidth input={username} label={t('ENTER_USERNAME')}/>
                  </InputGroup>
                  <InputGroup className="mb-4">
                    <TextInput fullWidth input={password} type="password" label={t('ENTER_PASS')}/>
                  </InputGroup>
                  <InputGroup className="mb-4">
                    <TextInput fullWidth input={fullname} label={t('ENTER_FULLNAME')}/>
                  </InputGroup>
                  <InputGroup className="mb-4">
                    <TextInput fullWidth input={mobile_phone} label={t('ENTER_PHONE')}/>
                  </InputGroup>
                  <Row>
                    <Col xs="6">
                      { loading ? t('LOADING') + ' ...' : <Button type='submit' color="info" className="px-4" >{t('CREATE_NOW')}</Button> }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardGroup>
            </form>
          </Col>
        </Row>
      </Container>
      <Dialog />
      <DialogError/>
    </div>
  )
}

export default CreateAgent
