import React, { useState, useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Button,
} from "reactstrap";
import http from "service/http";
import { useForm } from "react-final-form-hooks";
import { TextInput, useLoading, SelectInput } from "feature/Component";
import { FormControl } from "@material-ui/core";
import formCreateInputs from "service/form/create";
import numeral from "numeral";
import store from "store";
import "./style.scss";
import moment from "moment";
import { useTranslation } from "react-i18next";
import LoadingUi from "feature/Component/Loading/ui";

// const DEFAULT_FROM_DATE = moment().subtract(1, 'd').format('YYYY-MM-DD')
// const DEFAULT_TO_DATE = moment().subtract(1, 'd').format('YYYY-MM-DD')
const DEFAULT_FROM_DATE = moment().format("YYYY-MM-DD");
const DEFAULT_TO_DATE = moment().format("YYYY-MM-DD");
let user = store.get("user") || { level: "" };

const correctNumber = (amount) => numeral(amount).format("0,0.00");

function History(props) {
  const { t } = useTranslation();
  user = props;
  const query = new URLSearchParams(props.location.search);
  const [loading, withLoading] = useLoading();
  const [games, setgames] = useState([]);
  const [sshare, setShare] = useState(0);
  const [selfShare, setSelfShare] = useState(props.share || 0);
  const [data, setData] = useState([]);
  const [dataExpenses, setDataExpenses] = useState({});
  const [dataDownlines, setDataDownlines] = useState([]);
  const [dataTotalDownline, setDataTotalDownline] = useState({
    totalBetAmount: 0,
    expenses: 0,
    share: 0,
    agentProfit: 0,
  });
  const [ptSelf, setptSelf] = useState({
    totalBetAmount: 0,
    expenses: 0,
    share: 0,
    agentProfit: 0,
  });
  const [dataTotal, setDataTotal] = useState({
    gameType: "Total",
    totalBetAmount: 0,
    totalTurnover: 0,
    totalWinLose: 0,
    groupTotal: 0,
    groupProfit: 0,
    profit: 0
  });
  const [initialInputs, updateInitialInputs] = useState({
    fromDate: DEFAULT_FROM_DATE,
    toDate: DEFAULT_TO_DATE,
  });
  const [adminDownLineProfit, setAdminDownLineProfit] = useState(0);
  const onSubmit1 = async (params) => {
    const exp = await fetchExpenses(params);
    const wl = await Promise.all(
      games.map(async (g) => {
        const winlose = await loadReposrt(
          {
            fromDate: query.get("fromDate") || DEFAULT_FROM_DATE,
            toDate: query.get("toDate") || DEFAULT_TO_DATE,
          },
          g.game_code,
          g.providerCode
        );
        const w = { ...g, winlose: winlose.totals };
        if (user.user_level == "PARTNER" || user.user_level == "AGENT") {
          const winlosePartner = await loadReposrt(
            {
              fromDate: query.get("fromDate") || DEFAULT_FROM_DATE,
              toDate: query.get("toDate") || DEFAULT_TO_DATE,
              userPT: user.username,
            },
            g.game_code,
            g.providerCode
          );
          w.winlosePartner = winlosePartner.totals;
        }
        return w;
      })
    );
    wl.unshift({
      agent: null,
      id: -1,
      isActive: 1,
      market: "THB",
      name: "Expenses",
      gameType: "Expenses",
      totalBetAmount: 0,
      groupTotal: 0,
      groupProfit: 0,
      totalTurnover: exp.totalAmount,
      payout: exp.totalAmount,
      totalWinLose: exp.totalAmount,
    });
    const { data, total, expenses } = calGroupTotal(wl, sshare);

    loadReposrtNew(
      {
        fromDate: params.fromDate,
        toDate: params.toDate,
      },
      exp,
      total,
      expenses,
      sshare,
      data.filter(x => x.totalBetAmount > 0 || x.id === -1)
    );
    const selfsharee = user.username == "admin" ? sshare : user.share;
    data[0].groupProfit =
      ((total.groupTotal / total.totalBetAmount) *
        exp.totalAmount *
        -1 *
        selfsharee) /
      sshare;
    total.groupProfit = 0;
    data.map((d) => {
      total.groupProfit += d.groupProfit;
    });
    setData(data);
    setDataTotal(total);
  };
  const initData = (
    params = {
      fromDate: DEFAULT_FROM_DATE,
      toDate: DEFAULT_TO_DATE,
    }
  ) => {
    http.get({ path: "config" }).then((rs1) => {
      setShare(rs1.share);
      // let selfShareLocal = selfShare
      if (props.username == "admin") {
        setSelfShare(rs1.share);
        // selfShareLocal = rs1.share
      }
      withLoading(() =>
        http.get({ path: `games` }).then(async (rss) => {
          const rs = rss.filter((x) => x.isActive == 1);
          setgames(rs);
          const wl = await Promise.all(
            rs.map(async (g) => {
              const winlose = await loadReposrt(
                {
                  fromDate: query.get("fromDate") || params.fromDate,
                  toDate: query.get("toDate") || params.toDate,
                },
                g.game_code,
                g.providerCode
              );
              const w = { ...g, winlose: [winlose.totals] };
              if (user.user_level == "PARTNER" || user.user_level == "AGENT") {
                const winlosePartner = await loadReposrt(
                  {
                    fromDate: query.get("fromDate") || params.fromDate,
                    toDate: query.get("toDate") || params.toDate,
                    userPT: user.username,
                  },
                  g.game_code,
                  g.providerCode
                );
                w.winlosePartner = winlosePartner.totals;
              }
              return w;
            })
          );
          const exp = await fetchExpenses({
            fromDate: query.get("fromDate") || params.fromDate,
            toDate: query.get("toDate") || params.toDate,
          });

          wl.unshift({
            agent: null,
            id: -1,
            isActive: 1,
            market: "THB",
            name: "Expenses",
            gameType: "Expenses",
            totalBetAmount: 0,
            groupTotal: 0,
            groupProfit: 0,
            totalTurnover: exp.totalAmount,
            payout: exp.totalAmount,
            totalWinLose: exp.totalAmount,
          });

          const { data, total, expenses } = calGroupTotal(wl, rs1.share);

          loadReposrtNew(
            {
              fromDate: query.get("fromDate") || params.fromDate,
              toDate: query.get("toDate") || params.toDate,
            },
            exp,
            total,
            expenses,
            rs1.share,
            data.filter(x => x.totalBetAmount > 0 || x.id === -1)
          );
          const selfsharee = user.username == "admin" ? rs1.share : user.share;
          data[0].groupProfit =
            ((total.groupTotal / total.totalBetAmount) *
              exp.totalAmount *
              -1 *
              selfsharee) /
            rs1.share;
          total.groupProfit = 0;
          data.map((d) => {
            total.groupProfit += d.groupProfit;
          });
          setData(data);
          setDataTotal(total);
        })
      );
    });
  };
  const onSubmit = (params) => {
    updateInitialInputs({
      fromDate: params.fromDate,
      toDate: params.toDate,
    });
    initData(params);
  };
  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: initialInputs,
  });
  const [fromDate, toDate] = formCreateInputs(["fromDate", "toDate"], form);

  const calGroupTotal = (wl, systemShare = sshare) => {
    const selfSharee = user.user_level == "ADMIN" ? systemShare : selfShare;
    const casino = {
      totalBetAmount: 0,
      totalTurnover: 0,
      totalWinLose: 0,
      groupTotal: 0,
      groupProfit: 0,
      payout: 0,
      profit: 0,
      wexpenses: 0,
      agentProfit: 0,
    };
    const slot = {
      totalBetAmount: 0,
      totalTurnover: 0,
      totalWinLose: 0,
      groupTotal: 0,
      groupProfit: 0,
      payout: 0,
      wexpenses: 0,
      agentProfit: 0,
    };
    const sportsbook = {
      totalBetAmount: 0,
      totalTurnover: 0,
      totalWinLose: 0,
      groupTotal: 0,
      groupProfit: 0,
      payout: 0,
      wexpenses: 0,
      agentProfit: 0,
    };
    const lottery = {
      totalBetAmount: 0,
      totalTurnover: 0,
      totalWinLose: 0,
      groupTotal: 0,
      groupProfit: 0,
      payout: 0,
      profit: 0,
      wexpenses: 0,
      agentProfit: 0,
    };
    const expenses = wl.find((x) => x.name == "Expenses");

    wl.filter(
      (x) =>
        x.gameType == "Casino" ||
        x.gameType == "LIVE" ||
        x.providerCode == "SEXYBCRT" ||
        x.providerCode == "BG" ||
        x.providerCode == "VENUS"
    ).map((w) => {
      const totalBetAmountCasino = w.winlose.reduce((curr, next) => Number(next.totalBetAmount || 0) + curr , 0);
      const totalTurnoverCasino = w.winlose.reduce((curr, next) => Number(next.totalTurnover || 0) + curr, 0);
      const totalWinLoseCasino = w.winlose.reduce((curr, next) => Number(next.totalWinLose || 0) + curr, 0);
      const downlineWlCasino = w.winlose.reduce((curr, next) => Number(next.downlineWl || 0) + curr, 0);
      const playerWinlose = w.winlose.reduce((curr, next) => Number(next.playerWinlose || 0) + curr, 0);
      // const groupProfit = casino.totalWinLos != 0 ? casino.totalWinLose * -1 / systemShare * selfShare : 0
      casino.totalBetAmount += totalBetAmountCasino;
      casino.totalTurnover += totalTurnoverCasino;
      casino.totalWinLose += totalWinLoseCasino;
      casino.payout += downlineWlCasino;
      casino.profit += totalWinLoseCasino;

      if (user.user_level == "ADMIN")
        casino.groupTotal += totalBetAmountCasino;
      else if (user.user_level == "PARTNER" || user.user_level == "AGENT") {
        casino.groupTotal += totalBetAmountCasino;
      }
      // console.log(casino.groupProfit, groupProfit)
      // casino.groupProfit += groupProfit
    });

    wl.filter(
      (x) =>
        x.gameType == "Slot" ||
        x.gameType == "SLOT" ||
        x.gameType == "TABLE" ||
        x.gameType == "FH" ||
        x.gameType == "EGAME" ||
        x.providerCode == "PG" ||
        x.providerCode == "RT" ||
        x.providerCode == "JILI" ||
        x.providerCode == "AWS" ||
        x.providerCode == "JDBFISH" ||
        x.providerCode == "JILI"
    ).map((w) => {
      // const groupProfit = w.winlose.totalWinLose != 0 ? w.winlose.totalWinLose * -1 / systemShare * selfShare : 0
      slot.totalBetAmount += Number(w.winlose.totalBetAmount || 0);
      slot.totalTurnover += Number(w.winlose.totalTurnover || 0);
      slot.totalWinLose += Number(w.winlose.totalWinLose || 0);
      slot.payout += Number(w.winlose.totalWinLose || 0) + Number(w.winlose.totalBetAmount || 0);
      if (user.user_level == "ADMIN")
        slot.groupTotal += Number(w.winlose.totalBetAmount || 0);
      else if (user.user_level == "PARTNER" || user.user_level == "AGENT") {
        slot.groupTotal += Number(w.winlosePartner.totalBetAmount || 0);
      }
      // slot.groupProfit += groupProfit
    });
    wl.filter(
      (x) => x.gameType == "Sportsbook" || x.providerCode == "E1SPORTS"
    ).map((w) => {
      // const groupProfit = w.winlose.totalWinLose != 0 ? w.winlose.totalWinLose * -1 / systemShare * selfShare : 0
      sportsbook.totalBetAmount += Number(w.winlose.totalBetAmount || 0);
      sportsbook.totalTurnover += Number(w.winlose.totalTurnover || 0);
      sportsbook.totalWinLose += Number(w.winlose.totalWinLose || 0);
      sportsbook.payout += Number(w.winlose.totalWinLose || 0) + Number(w.winlose.totalBetAmount || 0);
      if (user.user_level == "ADMIN")
        sportsbook.groupTotal += Number(w.winlose.totalBetAmount || 0);
      else if (user.user_level == "PARTNER" || user.user_level == "AGENT") {
        sportsbook.groupTotal += Number(w.winlosePartner.totalBetAmount || 0);
      }
      // sportsbook.groupProfit += groupProfit
    });
    wl.filter((x) => x.gameType == "Lottery").map((w) => {
      const totalBetAmount = w.winlose.reduce((curr, next) => Number(next.totalBetAmount || 0) + curr , 0);
      const totalTurnover = w.winlose.reduce((curr, next) => Number(next.totalTurnover || 0) + curr, 0);
      const totalWinLose = w.winlose.reduce((curr, next) => Number(next.totalWinLose || 0) + curr, 0);
      const downlineWl = w.winlose.reduce((curr, next) => Number(next.downlineWl || 0) + curr, 0);
      const playerWinlose = w.winlose.reduce((curr, next) => Number(next.playerWinlose || 0) + curr, 0);
      lottery.totalBetAmount += totalBetAmount;
      lottery.totalTurnover += totalTurnover;
      lottery.totalWinLose += totalWinLose;
      lottery.payout += downlineWl;
      lottery.profit += totalWinLose;
      if (user.user_level == "ADMIN")
        lottery.groupTotal += totalBetAmount;
      else if (user.user_level == "PARTNER" || user.user_level == "AGENT") {
        lottery.groupTotal += totalBetAmount;
      }
      // lottery.totalWinLose += (totalBetAmount - totalWinLose) * -1;
      // lottery.groupProfit += groupProfit
    });
    //     if(user.user_level == 'PARTNER') {
    casino.groupProfit =
      casino.groupTotal && casino.totalWinLose != 0
        ? (((casino.totalWinLose * -1) / systemShare) *
            selfSharee *
            casino.groupTotal) /
          casino.totalBetAmount
        : 0;
    slot.groupProfit =
      slot.groupTotal && slot.totalWinLose != 0
        ? (((slot.totalWinLose * -1) / systemShare) *
            selfSharee *
            slot.groupTotal) /
          slot.totalBetAmount
        : 0;

    lottery.groupProfit = 
    lottery.groupTotal && lottery.totalWinLose != 0
      ? (((lottery.totalWinLose * -1) / systemShare) *
          selfSharee *
          lottery.groupTotal) /
          lottery.totalBetAmount
      : 0;
    //lottery.payout - lottery.totalBetAmount
    //lottery.profit = lottery.payout - lottery.totalBetAmount 
      // lottery.groupTotal && lottery.totalWinLose != 0
      //   ? (((lottery.totalWinLose * -1) / systemShare) *
      //       selfSharee *
      //       lottery.groupTotal) /
      //     lottery.totalBetAmount
      //   : 0;
    sportsbook.groupProfit =
      sportsbook.groupTotal && sportsbook.totalWinLose != 0
        ? (((sportsbook.totalWinLose * -1) / systemShare) *
            selfSharee *
            sportsbook.groupTotal) /
          sportsbook.totalBetAmount
        : 0;
    
    const total = {
      totalBetAmount:
        Number(lottery.totalBetAmount || 0) +
        Number(sportsbook.totalBetAmount || 0) +
        Number(casino.totalBetAmount || 0) +
        Number(slot.totalBetAmount || 0),
      totalTurnover:
        Number(lottery.totalTurnover || 0) +
        Number(sportsbook.totalTurnover || 0) +
        Number(casino.totalTurnover || 0) +
        Number(slot.totalTurnover || 0),
      totalWinLose:
        Number(lottery.totalWinLose || 0) +
        Number(sportsbook.totalWinLose || 0) +
        Number(casino.totalWinLose || 0) +
        Number(slot.totalWinLose || 0) +
        Number(expenses.totalWinLose || 0),
      groupTotal:
        Number(lottery.groupTotal || 0) +
        Number(sportsbook.groupTotal || 0) +
        Number(casino.groupTotal || 0) +
        Number(slot.groupTotal || 0),
      groupProfit:
        Number(lottery.groupProfit || 0) +
        Number(sportsbook.groupProfit || 0) +
        Number(casino.groupProfit || 0) +
        Number(slot.groupProfit || 0) +
        Number(expenses.groupProfit || 0),
      payout:
        Number(lottery.payout || 0) +
        Number(sportsbook.payout || 0) +
        Number(casino.payout || 0) +
        Number(slot.payout || 0) +
        Number(expenses.payout || 0),
      profit:
        Number(lottery.profit || 0) +
        Number(sportsbook.profit || 0) +
        Number(casino.profit || 0) +
        Number(slot.profit || 0) +
        Number(expenses.profit || 0),
      gameType: "Total",
    };
    
    return {
      expenses,
      total: {
        ...total,
        groupProfit: total.groupProfit || 0
      },
      data: [
        { ...expenses },
        {
          ...casino,
          gameType: "Casino",
        },
        {
          ...slot,
          gameType: "Slot",
        },
        {
          ...lottery,
          gameType: "Lottery",
        },
        {
          ...sportsbook,
          gameType: "Sportsbook",
        },
      ],
    };
  };
  
  const calGroupTotalDownline = (
    wl,
    adminData,
    adminDataTotal,
    dataDownline,
    systemSahre
  ) => {
    const casino = {
      totalBetAmount: 0,
      totalTurnover: 0,
      totalWinLose: 0,
      groupTotal: 0,
      payout: 0,
      profit: 0,
      wexpenses: 0,
      agentProfit: 0,
    };
    const slot = {
      totalBetAmount: 0,
      totalTurnover: 0,
      totalWinLose: 0,
      groupTotal: 0,
      payout: 0,
      wexpenses: 0,
      agentProfit: 0,
    };
    const sportsbook = {
      totalBetAmount: 0,
      totalTurnover: 0,
      totalWinLose: 0,
      groupTotal: 0,
      payout: 0,
      wexpenses: 0,
      agentProfit: 0,
    };
    const lottery = {
      totalBetAmount: 0,
      totalTurnover: 0,
      totalWinLose: 0,
      groupTotal: 0,
      payout: 0,
      profit: 0,
      wexpenses: 0,
      agentProfit: 0,
    };
    wl.filter((x) => x.gameType == "Casino").map((w) => {
      casino.totalBetAmount += w.totalBetAmount;
      casino.totalTurnover += w.totalTurnover;
      casino.totalWinLose += w.downlineWl - w.totalBetAmount;
      casino.payout += w.downlineWl;
      casino.profit += w.totalWinLose;
      casino.groupTotal += w.totalWinLose;
      
      casino.wexpenses +=
        dataDownline.share == 0
          ? 0
          : (w.totalBetAmount / adminDataTotal.totalBetAmount) *
            dataDownline.share *
            adminData[0].totalWinLose *
            -1;
    });

    const gameTypeFromData = adminData.find((x) => x.gameType == "Casino");
    if (gameTypeFromData) {
    casino.agentProfit =
      (((gameTypeFromData.groupProfit * -1) / systemSahre) *
        dataDownline.pt *
        casino.totalBetAmount) /
        gameTypeFromData.totalBetAmount +
      casino.wexpenses;
    }
    
    wl.filter(
      (x) =>
        x.gameType == "Slot" ||
        x.gameType == "SLOT" ||
        x.gameType == "TABLE" ||
        x.gameType == "FH" ||
        x.gameType == "EGAME"
    ).map((w) => {
      slot.totalBetAmount += w.totalBetAmount;
      slot.totalTurnover += w.totalTurnover;
      slot.totalWinLose += w.totalWinLose;
      slot.payout += w.totalWinLose + w.totalBetAmount;
      slot.groupTotal += w.totalWinLose;
      
      slot.wexpenses +=
        dataDownline.share == 0
          ? 0
          : (w.totalBetAmount / adminDataTotal.totalBetAmount) *
            dataDownline.share *
            adminData[0].totalWinLose *
            -1;
    });
    const gameTypeFromDataSL = adminData.find((x) => x.gameType == "Slot");
    if (gameTypeFromDataSL) {
    slot.agentProfit =
      (((gameTypeFromDataSL.totalWinLose * -1) / systemSahre) *
        dataDownline.pt *
        slot.totalBetAmount) /
        gameTypeFromDataSL.totalBetAmount +
      slot.wexpenses;
    }
    // console.log(gameTypeFromDataSL.totalWinLose*-1 , systemSahre , dataDownline.pt , slot.totalBetAmount , gameTypeFromDataSL.totalBetAmount , slot.wexpenses)
    wl.filter((x) => x.gameType == "Sportsbook" || x.gameType == "ESPORTS").map(
      (w) => {
        sportsbook.totalBetAmount += w.totalBetAmount;
        sportsbook.totalTurnover += w.totalTurnover;
        sportsbook.totalWinLose += w.totalWinLose;
        sportsbook.payout += w.totalWinLose + w.totalBetAmount;
        sportsbook.groupTotal += w.totalWinLose;
        sportsbook.wexpenses +=
          dataDownline.share == 0
            ? 0
            : (w.totalBetAmount / adminDataTotal.totalBetAmount) *
              dataDownline.share *
              adminData[0].totalWinLose *
              -1;
        // const gameTypeFromData = adminData.find(x => x.gameType == w.gameType)
        // sportsbook.agentProfit += gameTypeFromData.totalWinLose*-1 / systemSahre * dataDownline.pt * w.totalBetAmount / gameTypeFromData.totalBetAmount + sportsbook.wexpenses
      }
    );
    const gameTypeFromDataSB = adminData.find(
      (x) => x.gameType == "Sportsbook"
    );
    if (gameTypeFromDataSB) {
    sportsbook.agentProfit =
      (((gameTypeFromDataSB.totalWinLose * -1) / systemSahre) *
        dataDownline.pt *
        sportsbook.totalBetAmount) /
        gameTypeFromDataSB.totalBetAmount +
      sportsbook.wexpenses;
    }

    wl.filter((x) => x.gameType == "Lottery").map((w) => {
      lottery.totalBetAmount += w.totalBetAmount;
      lottery.totalTurnover += w.totalTurnover;
      lottery.totalWinLose += w.downlineWl - w.totalBetAmount;
      lottery.payout += w.downlineWl;
      lottery.profit += w.totalWinLose;
      lottery.groupTotal += w.totalWinLose;
      lottery.wexpenses +=
        dataDownline.share == 0
          ? 0
          : (w.totalBetAmount / adminDataTotal.totalBetAmount) *
            dataDownline.share *
            adminData[0].totalWinLose *
            -1;
      const gameTypeFromData = adminData.find((x) => x.gameType == w.gameType);
      lottery.agentProfit +=
        (((gameTypeFromData.groupProfit * -1) / systemSahre) *
          dataDownline.pt *
          w.totalBetAmount) /
          gameTypeFromData.totalBetAmount +
        lottery.wexpenses;
    });

    const gameTypeFromDataLT = adminData.find((x) => x.gameType == "Lottery");
    if (gameTypeFromDataLT) {
    lottery.agentProfit =
      (((gameTypeFromDataLT.groupProfit * -1) / systemSahre) *
        Number(dataDownline.pt) *
        lottery.totalBetAmount) /
        gameTypeFromDataLT.totalBetAmount +
      lottery.wexpenses;
    }
    return [
      {
        ...casino,
        gameType: "Casino",
      },
      {
        ...slot,
        gameType: "Slot",
      },
      {
        ...lottery,
        gameType: "Lottery",
      },
      {
        ...sportsbook,
        gameType: "Sportsbook",
      },
    ];
  };

  useEffect(() => {
    initData();
  }, []);
  const loadReposrtNew = (
    date,
    exp,
    total,
    localexpenses,
    systemSahre = sshare,
    adminData
  ) => {
    withLoading(() =>
      http
        .get({
          path: `admin/barrel-report/`,
          params: {
            ...date,
            banker: query.get("banker") || user.lotto97Banker || undefined,
            parent: user.username,
          },
        })
        .then((rs) => {
          // console.log(rs)
          let totalBetAmount = 0;
          let expenses = 0;
          let totalAgentProfit = 0;
          const newrs = rs.map((r) => {
            totalBetAmount += Number(r.winlose.totalBetAmount || 0);
            const share = Number(systemSahre || 0) !== 0 ? (Number(r.pt || 0) / Number(systemSahre || 0)) : 0;
            const sexpenses =
            Number(total.totalBetAmount || 0) !== 0 ? ((share * Number(r.winlose.totalBetAmount || 0)) / Number(total.totalBetAmount || 0)) *
              Number(exp.totalAmount || 0) * -1 : 0;
            const agentProfit = Number(total.totalBetAmount|| 0) !== 0 ?
              (Number(r.winlose.totalBetAmount|| 0) / Number(total.totalBetAmount|| 0)) *
                share * Number(r.winlose.totalWinLose|| 0) - sexpenses : 0;
            // console.log(r)
            expenses += sexpenses;
            totalAgentProfit += agentProfit;

            return {
              ...r,
              share,
              expenses: sexpenses,
              agentProfit,
            };
          });
          const dataPtSelf = {
            ...ptSelf,
            expenses: Number(adminData[0].groupProfit || 0) - Number(expenses || 0),
            agentProfit: Number(total.groupProfit || 0) - Number(totalAgentProfit || 0),
          };
          // console.log(adminData[0].groupProfit, expenses)

          setptSelf(dataPtSelf);
          setDataTotalDownline({
            ...dataTotalDownline,
            totalBetAmount,
            expenses: Number(dataPtSelf.expenses || 0) + expenses,
            agentProfit: Number(dataPtSelf.agentProfit || 0) + totalAgentProfit,
          });
          let localAdminDownLineProfit = Number(total.groupProfit || 0);

          // console.log(total.groupProfit)
          let totalAgents = newrs.map((g) => {
            // console.log(dataPtSelf.agentProfit + totalAgentProfit, g)
            const groupTotalDownline = calGroupTotalDownline(
              g.winloses,
              adminData,
              total,
              g,
              systemSahre
            ).filter((x) => x.totalBetAmount > 0);

            // console.log(groupTotalDownline)
            let totalagentProfit = 0;
            groupTotalDownline.map((a) => {
              totalagentProfit += Number(a.agentProfit || 0);
            });

            // console.log(localAdminDownLineProfit, totalagentProfit)
            g.totalagentProfit = totalagentProfit;
            g.groupTotalDownline = groupTotalDownline; //.filter(x => x.totalBetAmount > 0 || x.totalWinLose > 0) || [];
            localAdminDownLineProfit = (-1 * totalagentProfit) - (localAdminDownLineProfit);
            // console.log(localAdminDownLineProfit)

            return g;
          });
          let totalDown = 0;
          let totalExpenses = 0;
          totalAgents
            .filter((x) => !x.isLogin)
            .map((item) => {
              totalDown += Number(item.totalagentProfit || 0);
              totalExpenses += Number(item.expenses || 0);
            });

          totalAgents = totalAgents.map((g) => {
            if (g.isLogin) {
              g.totalagentProfit = Number(total.groupProfit || 0) - Number(totalDown || 0);
              g.expenses = Number(adminData[0].groupProfit || 0) - Number(totalExpenses || 0);
            }
            return g;
          });
          setDataDownlines(totalAgents);
          setAdminDownLineProfit(localAdminDownLineProfit);
          // updateInitialInputs({
          //   fromDate: moment(date.fromDate).format('YYYY-MM-DD'),
          //   toDate: moment(date.toDate).format('YYYY-MM-DD'),
          // })
        })
    );
  };
  const loadReposrt = (date, game_code, site) => {
    date.fromDate = moment(date.fromDate).format("YYYY-MM-DD 00:00:00");
    date.toDate = moment(date.toDate).format("YYYY-MM-DD 23:59:59");
    return http.get({
      path: `admin/report/${game_code}/winlose`,
      params: {
        ...date,
        site,
      },
    });
  };
  const fetchExpenses = (date) => {
    return http
      .get({ path: `admin/expenses-sum`, params: { ...date } })
      .then((rs) => {
        setDataExpenses(rs);
        return rs;
      });
  };

  return (
    <div className="animated fadeIn">
      {loading && <LoadingUi />}
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <form onSubmit={handleSubmit}>
                <TextInput
                  style={{ marginLeft: 15, width: 200 }}
                  input={fromDate}
                  label={t("FROM")}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextInput
                  style={{ marginLeft: 15, width: 200 }}
                  input={toDate}
                  label={t("TO")}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormControl style={{ marginTop: 30, marginLeft: 15 }}>
                  {loading ? (
                    t("LOADING") + " ..."
                  ) : (
                    <Button type="submit" color="info" className="px-4">
                      {t("SEARCH")}
                    </Button>
                  )}
                </FormControl>
              </form>
            </CardHeader>
            <CardBody>
              <React.Fragment>
                <Table responsive hover className="fullReportTable" bordered>
                  <thead>
                    <tr style={{ background: "rgb(5 48 130)", color: "white" }}>
                      <th scope="col">{t("PRODUCT")}</th>
                      <th scope="col">{t("TOTAL_BET")} (THB)</th>
                      <th scope="col">{t("PAYOUT")} (THB)</th>
                      <th scope="col">{t("PROFIT")} (THB)</th>
                      <th scope="col">{t("GROUP_TOTAL")} (THB)</th>
                      <th scope="col">{t("GROUP_PROFIT")} (THB)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((g, i) => {
                      const profit = g.totalWinLose;
                      const groupProfit = g.groupProfit;
                      return (
                        <tr key={i + i}>
                          <td
                            style={{
                              fontWeight: "bold",
                              background: "#8f9ba68a",
                            }}
                          >
                            {g.gameType}
                          </td>
                          <td>{correctNumber(g.totalBetAmount)}</td>
                          <td>{correctNumber(g.payout)}</td>
                          {profit > 0 && (
                            <td style={{ color: "red" }}>
                              {correctNumber(profit * -1)}
                            </td>
                          )}
                          {profit <= 0 && (
                            <td>{correctNumber(profit * -1)}</td>
                          )}
                          <td>{correctNumber(g.groupTotal)}</td>
                          {groupProfit >= 0 && (
                            <td>{groupProfit.toFixed(2)}</td>
                          )}
                          {groupProfit < 0 && (
                            <td style={{ color: "red" }}>
                              {groupProfit.toFixed(2)}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                    <tr style={{ fontWeight: "bold" }}>
                      <td
                        style={{ fontWeight: "bold", background: "#8f9ba68a" }}
                      >
                        {dataTotal.gameType}
                      </td>
                      <td>{correctNumber(dataTotal.totalBetAmount)}</td>
                      <td>{correctNumber(dataTotal.payout)}</td>
                      {dataTotal.profit > 0 && (
                        <td style={{ color: "red" }}>
                          {correctNumber(dataTotal.profit * -1)}
                        </td>
                      )}
                      {dataTotal.profit <= 0 && (
                        <td>{correctNumber(dataTotal.profit * -1)}</td>
                      )}
                      <td>{correctNumber(dataTotal.groupTotal)}</td>
                      {dataTotal.groupProfit <= 0 && (
                        <td>{correctNumber(dataTotal.groupProfit * -1)}</td>
                      )}
                      {dataTotal.groupProfit > 0 && (
                        <td style={{ color: "red" }}>
                          {correctNumber(dataTotal.groupProfit * -1)}
                        </td>
                      )}
                    </tr>
                  </tbody>
                </Table>
                <Table responsive hover className="fullReportTable" bordered>
                  <thead>
                    <tr style={{ background: "rgb(5 48 130)", color: "white" }}>
                      <th scope="col">{t("AGENT")}</th>
                      <th scope="col">{t("GROUP_MEMBER")} (THB)</th>
                      <th scope="col">{t("EXPENSES")} (THB)</th>
                      <th scope="col">
                        {t("PT")}/{t("SHARE")}
                      </th>
                      <th scope="col">
                        {t("AGENT")} {t("PROFIT")} (THB)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataDownlines.map((g, i) => {
                      return (
                        <>
                          <tr
                            key={g.username + i}
                            style={{
                              fontWeight: "bold",
                              background: "#8f9ba68a",
                            }}
                          >
                            {g.isLogin && <td>{g.parent_id}</td>}
                            {!g.isLogin && user.user_level == "AGENT" && (
                              <td>{g.username}</td>
                            )}
                            {!g.isLogin &&
                              (user.user_level == "ADMIN" ||
                                user.user_level == "PARTNER") && (
                                <td>{g.agent || g.partner}</td>
                              )}
                            <td>{correctNumber(g.winlose.totalBetAmount)}</td>
                            {(!g.expenses || g.expenses >= 0) && (
                              <td>{(g.expenses || 0).toFixed(2)}</td>
                            )}
                            {g.expenses < 0 && (
                              <td style={{ color: "red" }}>
                                {(g.expenses || 0).toFixed(2)}
                              </td>
                            )}
                            <td>
                              {user.user_level == "AGENT" ? 0 : (Number(g.pt || 0)).toFixed(0)}/{(sshare || 0).toFixed(0)}
                            </td>
                            {(!g.totalagentProfit ||
                              g.totalagentProfit >= 0) && (
                              <td>{(g.totalagentProfit || 0).toFixed(2)}</td>
                            )}
                            {g.totalagentProfit < 0 && (
                              <td style={{ color: "red" }}>
                                {(g.totalagentProfit || 0).toFixed(2)}
                              </td>
                            )}
                          </tr>
                          {g.groupTotalDownline.map((w, i) => {
                            return (
                              <tr key={g.username + i}>
                                {g.isLogin && (
                                  <td>own player - {w.gameType}</td>
                                )}
                                {!g.isLogin && (
                                  <td>
                                    {g.agent || g.partner || g.username} -{" "}
                                    {w.gameType}
                                  </td>
                                )}
                                <td>{correctNumber(w.totalBetAmount)}</td>
                                {(!w.wexpenses || w.wexpenses >= 0) && (
                                  <td>{(w.wexpenses || 0).toFixed(2)}</td>
                                )}
                                {w.wexpenses < 0 && (
                                  <td style={{ color: "red" }}>
                                    {w.wexpenses.toFixed(2)}
                                  </td>
                                )}
                                <td>
                                  {user.user_level == "AGENT" ? 0 : Number(g.pt || 0).toFixed(2)}/
                                  {sshare}
                                </td>
                                {(!w.agentProfit || w.agentProfit >= 0) && (
                                  <td>{(w.agentProfit || 0).toFixed(2)}</td>
                                )}
                                {w.agentProfit < 0 && (
                                  <td style={{ color: "red" }}>
                                    {w.agentProfit.toFixed(2)}
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </>
                      );
                    })}
                    {user.user_level == "ADMIN" && (
                      <tr
                        style={{ fontWeight: "bold", background: "#8f9ba68a" }}
                      >
                        <td>{props.username}</td>
                        <td>{0}</td>
                        {(!ptSelf.expenses || ptSelf.expenses >= 0) && (
                          <td>{(ptSelf.expenses || 0).toFixed(2)}</td>
                        )}
                        {ptSelf.expenses < 0 && (
                          <td style={{ color: "red" }}>
                            {ptSelf.expenses.toFixed(2)}
                          </td>
                        )}
                        <td>
                          {Number(selfShare || 0).toFixed(2)}/{sshare}
                        </td>
                        {(!adminDownLineProfit || adminDownLineProfit >= 0) && (
                          <td>{(adminDownLineProfit || 0).toFixed(2)}</td>
                        )}
                        {adminDownLineProfit < 0 && (
                          <td style={{ color: "red" }}>
                            {adminDownLineProfit.toFixed(2)}
                          </td>
                        )}
                      </tr>
                    )}
                    <tr>
                      <td>Total</td>
                      <td>{correctNumber(dataTotalDownline.totalBetAmount)}</td>
                      {(!dataTotalDownline.expenses ||
                        dataTotalDownline.expenses >= 0) && (
                        <td>{(dataTotalDownline.expenses || 0).toFixed(2)}</td>
                      )}
                      {dataTotalDownline.expenses < 0 && (
                        <td style={{ color: "red" }}>
                          {dataTotalDownline.expenses.toFixed(2)}
                        </td>
                      )}
                      <td>{0}</td>
                      {(!dataTotalDownline.agentProfit ||
                        dataTotalDownline.agentProfit >= 0) && (
                        <td>
                          {(dataTotalDownline.agentProfit || 0).toFixed(2)}
                        </td>
                      )}
                      {dataTotalDownline.agentProfit < 0 && (
                        <td style={{ color: "red" }}>
                          {dataTotalDownline.agentProfit.toFixed(2)}
                        </td>
                      )}
                    </tr>
                  </tbody>
                </Table>
              </React.Fragment>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default History;
