const ADMIN = 'ADMIN'
const ADMINCS = 'ADMIN_CS'
const AGENT = 'AGENT'
const STAFF = 'AGENT_CS'
const PARTNER = 'PARTNER'

const nav = (t) => [
  {
    permission: '*',
    name: t('DASHBOARD'),
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW',
    },
    role: '*'
  },
  {
    name: t('TOTAL_BET'),
    icon: 'icon-notebook',
    children: [
      {
        name: t('BETTING_HISTORIES'),
        url: '/betting-histories',
        icon: 'icon-menu',
        permission: 'betlist',
        role: [AGENT, ADMIN, ADMINCS, PARTNER]
      }
    ]
  },
  {
    name: t('ACCOUNT'),
    icon: 'icon-menu',
    permission: 'betlist',
    role: [AGENT, ADMIN, ADMINCS, PARTNER],
    children: [
      {
        name: t('AGENT'),
        icon: 'icon-list',
        url: '/agents',
        permission: 'create_partner',
        role: [ADMIN, ADMINCS, PARTNER, AGENT]
      },
      {
        name: t('PLAYERS'),
        icon: 'icon-list',
        url: '/players',
        permission: 'user_management',
        role: [ ADMINCS,ADMIN, PARTNER, AGENT]
      },

    ]
  },
  {
    name: t('CASH'),
    icon: 'icon-menu',
    role: [AGENT, ADMIN, ADMINCS, PARTNER],
    children: [
      {
        name: t('BONUS_PENDING'),
        url: '/bonus-pending',
        icon: 'icon-action-redo',
        permission: 'transaction_deposit',
        role: [ADMIN, ADMINCS]
      },
      {
        name: t('DEPOSITS'),
        url: '/transaction/deposits',
        icon: 'icon-action-redo',
        permission: 'transaction_deposit',
        role: [ADMIN, ADMINCS]
      },
      {
        name: t('WITHDRAW'),
        url: '/transaction/withdraws',
        icon: 'icon-action-undo',
        permission: 'transaction_withdraw',
        role: [ADMIN, ADMINCS]
      },
      {
        name: t('TRANSACTION_HISTORY'),
        url: '/transaction-history',
        icon: 'icon-menu',
        role: [AGENT, ADMIN, ADMINCS, PARTNER]
      },
      {
        name: t('BANKING'),
        icon: 'icon-credit-card',
        url: '/banking',
        permission: 'config_banking',
        role: [ ADMINCS,ADMIN]
      },
    ]
  },
  {
    name: t('REPORTS'),
    icon: 'icon-menu',
    role: [AGENT, ADMIN, ADMINCS, PARTNER],
    children: [{
      name: t('NEW_REPORTS'),
      url: '/reports-new',
      icon: 'icon-arrow-right',
      role: [AGENT, ADMIN, ADMINCS, PARTNER]
    }, {
      name: t('EXPENSES'),
      url: '/expenses',
      icon: 'icon-arrow-right',
      role: [ADMIN, ADMINCS]
    },{
      name: t('CONFIG_SHARE'),
      url: '/barrel-config-share',
      icon: 'icon-arrow-right',
      role: [ADMIN, ADMINCS]
    },{
      name: t('BARREL_REPORTS'),
      url: '/barrel',
      icon: 'icon-arrow-right',
      role: [AGENT, ADMIN, ADMINCS, PARTNER]
    },{
      name: t('BARREL_REPORTS'),
      url: '/barrels',
      icon: 'icon-arrow-right',
      role: [AGENT, ADMIN, ADMINCS, PARTNER]
    }]
  },
  {
    name: t('COMMISSIONS'),
    icon: 'icon-action-redo',
    url: '/comm',
    permission: 'create_partner',
    role: [ADMIN, ADMINCS]
  },
  {
    name: t('PROMOTION'),
    icon: 'icon-settings',
    url: '/promotion',
    role: [ADMINCS,ADMIN]
  },
  {
    name: t('GAME_MANAGEMENT'),
    icon: 'icon-settings',
    url: '/game',
    permission: 'config_game',
    role: [ADMINCS,ADMIN]
  }
]
export default (acl, userLevel, t) => ({
  items: userLevel === STAFF ? nav.map((item) => {
    const { permission, children } = item
    if(permission === '*') return item;
    if(!children) {
      if(acl.includes(permission)) {
        return item
      }
      return false
    }
    const result = children.filter((item) => {
      const { permission } = item
      return acl.includes(permission)
    })
    if(!result.length) return false
    return {
      ...item,
      children: result
    }
  }).filter(exist => exist)
  : nav(t).map((item) => {
    const { role, children } = item
    if(role === '*') return item;
    if(!children) {
      if(role.includes(userLevel)) {
        return item
      }
      return false
    }
    const result = children.filter((item) => {
      const { role } = item
      return role.includes(userLevel)
    })
    if(!result.length) return false
    return {
      ...item,
      children: result
    }
  }).filter(exist => exist)
})
