import React from 'react'
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core'

const Input = ({ classes, input: { input }, label }) => {

  const handleChange = (event) => input.onChange({
    ...event,
    target: {
      ...event.target,
      value: !input.value
    }
  })

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value}
          onChange={handleChange}
          color="primary"
        />
      }
      label={<Typography>{label}</Typography>}
    />
  )
}

export default Input