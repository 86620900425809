import React, { useState, useEffect } from 'react';
import { Tooltip, Button, TextField, Dialog, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';
import { Card, CardBody, CardHeader, Col, Row, Table, FormGroup, Input } from 'reactstrap';
import http from 'service/http';
import store from 'store';
import validate from 'service/form/validation'
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import formCreateInputs from 'service/form/create'
import { useDialog, TextInput, useLoading } from 'feature/Component'
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next'
import TreeView from '../Users/treeView'
import LoadingUi from 'feature/Component/Loading/ui'

let user = store.get('user') || { level: '' };
function Comm({ respGames, data, setStage, stage, index, toggleTree, user_id, showModalDefault, setCommDATA, t }) {
  console.log(data, respGames)
  return (
    <tr>
      <td style={{ textAlign: 'left', width: 150, color: 'blue', cursor: 'pointer' }}>
        <b>{t('ALL')}</b>
      </td>

      <td>
        <FormGroup style={{margin: 0}}>
          <Input type="select" name="select" id="exampleSelect" style={{minWidth: 110}} onChange={(e) => setStage({...stage, commSet: e.target.value})}>
            <option value='partner'>PARTNER</option>
            <option value='agent'>AGENT</option>
            <option value='player'>PLAYER</option>
          </Input>
        </FormGroup>
      </td>
      {respGames.map(({game_code, label}) => {
        const found = data.find(x => x.game_code == game_code)
          // console.log(game_code)
          return (<td key={game_code+label}>
            <Tooltip title={"Edit " + game_code}>
              <Button onClick={() => showModalDefault({ parent_id: t('DEFAULT'), game_code, comm: found.comm, user_id: found.user_id })}
                      size="small">
                {found.comm} %
              </Button>
            </Tooltip>
          </td>)
        }
      )}
    </tr>
  )
}
function User({ respGames, username, parent_id, user_account_no, show, positionTaking, email, level, index, toggleTree, user_id, showModalPT, showModalPT1, user_level, setCommDATA, commisions }) {
  const comms = user_level === 'PLAYER' ? commisions.player : (user_level === 'AGENT' ? commisions.agent : commisions.partner)
  return (
    <tr style={{ display: show ? '' : 'none' }}>
      <td style={{ textAlign: 'left', width: 150, color: 'blue', cursor: 'pointer' }}>
        {user_level != 'PLAYER' ? <b onClick={() => setCommDATA(username)}>{username}</b> :
          <b>{user_account_no}</b>
        }
      </td>

      <td>{user_level}</td>
      {respGames.map(({ game_code, label }) => {
        const found = comms.find(y => y.game_code == game_code)
        return <td key={game_code+label}>
          <Tooltip title={"Edit " + game_code}>
            <Button size="small" >
              {found.comm} %
            </Button>
          </Tooltip>
        </td>
      })}
    </tr>
  )
}
const LoadChild = ({ root, docs, initialUsers}) => {
  return <TreeView parent={root} children={docs} initialUsers={initialUsers}/>
}
function Users(props) {
  const { t } = useTranslation()
  user = {...props}
  const schema = Joi.object().keys({
    comm: Joi.number().min(0).max(100).required()
  })
  // const schemaDefault = Joi.object().keys({
  //   commDefault: Joi.number().min(0).max(1).required()
  // })
  const [PT, updatePT] = useState([])
  const [ptModal, updatePTModal] = useState({})
  const [defaultModal, updateDefaultModal] = useState({})
  const [loading, withLoading] = useLoading()
  const [docs, updateDocs] = useState([])
  const [treedocs, updateTreeDocs] = useState([])
  const [respGames, setSespGames] = useState([])
  const [defaulComm, setDefaultComm] = useState([])
  const [commUpdate, setCommUpdate] = useState(0)
  const [stage, setStage] = useState({
    commisions: null,
    commSet: 'partner'
  })
  const initialUsers = async (params = {}, level = false) => {

    if (level) {
      const ptdata = PT.filter(x => x.parent_id == params.parent_id)
      // console.log(ptdata, level, params)
      updateDocs(ptdata)
      updateTreeDocs(treedocs.map((tr, i) => {
        if (tr.username == params.parent_id) tr.users = ptdata
        return tr
      }))
    } else updateTreeDocs(PT)
    // alert(params.parent_id)
  }
  const setCommDATA = (parent_id) => {
    const ptdata = PT.filter(x => x.parent_id == parent_id)
    // console.log(ptdata)
    updateDocs(ptdata)
  }
  const mapComm = (resp, games = respGames) => {
    console.log(resp)
    return resp.map((item, index) => {
      const PTJSON = JSON.parse(item.commissions)
      const PT = games
        .filter(({ isActive }) => isActive)
        .map((item) => {
          const found = PTJSON.find(({ game_code }) => game_code === item.game_code)
          if (found) {
            found.game_name = item.name
            return found
          }
          return { game_code: item.game_code, comm: 0, game_name: item.name }
        })
      return {
        ...item,
        index,
        positionTaking: PT,
        show: true
      }
    })
  }
  const initialPT = async (parent_id = null, games = null) => {
    const resp = await withLoading(() =>  http.get({ path: 'admin/config/commissions' }))
    console.log(resp)
    const commData = mapComm(resp, games)
    // console.log(commData)
    updatePT(commData)
    let docc = commData.filter(x => x.parent_id == 'admin')
    if(parent_id) docc = commData.filter(x => x.parent_id == parent_id)
    updateDocs(docc)
    updateTreeDocs(docc)
  }

  const toggleTree = (index, level) => {
    try {
      let ableToUpdate = true
      const newTable = PT.map((item, i) => {
        if (!ableToUpdate) {
          return item
        }
        if (i > index && item.level <= level) {
          ableToUpdate = false
          return item
        }

        if (ableToUpdate) {
          if (i > index && (item.level === level + 1 || item.show)) {
            return {
              ...item,
              show: !item.show
            }
          }
        }
        return item
      })
      // console.log(newTable)
      updatePT(newTable)
    } catch (error) {
      console.log(error)
    }
  }
  const [ErroDialog, showErrorDialogWithMessage] = useDialog({
    title: 'Error',
    type: 'error'
  })
  const showModalDefault = data => {
    // console.log(data)
    setCommUpdate(data.comm)
    updateDefaultModal(data)
  }
  const showModalPT = data => updatePTModal(data)
  const showModalPT1 = (t) => {
    showErrorDialogWithMessage(t('PLEASE_CONTACT_ADMIN'))
  }
  const onSubmit = async ({comm}) => {
    // console.log(comm)

    const currentPT = JSON.parse(user.commissions)
    const selectedPT = currentPT.find(({ game_code }) => game_code === ptModal.game_code)
    const cloneSelectedPt = { ...selectedPT };
    if (!'ADMIN, ADMIN_CS'.includes(user.user_level) && ((comm > 0 && (currentPT.length == 0 || !cloneSelectedPt.comm)) || cloneSelectedPt.comm < comm * 1)) {
      return updatePTModal({
        ...ptModal,
        error: t('COMM_CANNOT_GREATE_THAN')
      })
    }
    const { commissions } = PT.find(({ user_id }) => user_id === ptModal.user_id)
    const cleanedPT = JSON.parse(commissions)
    const found = cleanedPT.find(({ game_code }) => game_code === ptModal.game_code)
    if(!found) cleanedPT.push({game_code: ptModal.game_code, comm: comm})
    cleanedPT.map((item) => {

        if (item.game_code === ptModal.game_code) item.comm = comm
        return item
      })
      .filter(({ comm }) => comm !== 0)

    try {
      await withLoading(() => http.put({
        path: `admin/config/${ptModal.user_id}/commissions`, payload: {
          percen: cleanedPT,
          before: ptModal.game_code + ' - ' + ptModal.comm,
          after: ptModal.game_code + ' - ' + comm
        }
      }))
      updatePTModal({})
      initialPT(ptModal.parent_id, respGames);
    } catch ({ message: { msg } }) {
      // console.log(msg)
      updatePTModal({
        ...ptModal,
        error: msg
      })
    }

  }
  const run = async () => {
    const games = await http.get({ path: 'games' })
      setSespGames(games.filter(x => x.isActive == 1))

      http.get({ path: 'admin/default/commissions' }).then(data => {
        if(data[0].commissions) {
          const commisions = JSON.parse(data[0].commissions)
          console.log(commisions)
          const commissions = commisions.partner
          const defaultC = mapComm([{ commissions: JSON.stringify(commissions) }], games)
          setDefaultComm(defaultC)
          setStage({
            ...stage, commisions
          })
          initialPT('admin', games)
        } else console.log(data)
      })
  }
  useEffect(() => {
    run()
  }, [])

  const { form, handleSubmit } = useForm({
    validate: validate(schema),
    onSubmit,
    initialValues: {
      comm: ptModal.comm
    }
  })
  const [comm] = formCreateInputs(['comm'], form)
  const onSubmitDefault = async () => {
    if(commUpdate != 0 && commUpdate < 0.01) updateDefaultModal({...defaultModal, error: 'Minimun is 0.01'})
    else {
      updateDefaultModal({})
      const comm = commUpdate
      // console.log(defaultModal)

      const currentPT = JSON.parse(user.commissions)
      const selectedPT = currentPT.find(({ game_code }) => game_code === defaultModal.game_code)
      const cloneSelectedPt = { ...selectedPT };
      if (!'ADMIN, ADMIN_CS'.includes(user.user_level) && ((comm > 0 && (currentPT.length == 0 || !cloneSelectedPt.comm)) || cloneSelectedPt.comm < comm * 1)) {
        return updatePTModal({
          ...ptModal,
          error: t('COMM_CANNOT_GREATE_THAN')
        })
      }
      const { commissions } = defaulComm[0]
      const cleanedPT = JSON.parse(commissions).map(c => {
        if (c.game_code === defaultModal.game_code) c.comm = comm
        return c
      })
      const found = cleanedPT.find(({ game_code }) => game_code === defaultModal.game_code)
      if (!found) cleanedPT.push({ game_code: defaultModal.game_code, comm: comm })
      const newComm = { ...stage.commisions }
      newComm[stage.commSet] = respGames.map((item) => {
        const found = stage.commisions[stage.commSet].find(x => x.game_code == defaultModal.game_code)
        const found1 = stage.commisions[stage.commSet].find(x => x.game_code == item.game_code)
        // console.log(defaultModal.game_code, found, item.game_code == defaultModal.game_code)
        const i = {
          game_code: item.game_code,
          comm: found && item.game_code == defaultModal.game_code ? comm : (found1 ? found1.comm : 0)
        }
        return i
      })

      try {
        await withLoading(() => http.put({
          path: `admin/default/commissions`, payload: {
            percen: newComm
          }
        }))
        // updatePTModal({})
        run();
      } catch ({ message: { msg } }) {
        // console.log(msg)
        updatePTModal({
          ...ptModal,
          error: msg
        })
      }
    }
  }
  return (
    <div className="animated fadeIn">
      {loading && <LoadingUi/>}
      <Row>
        <Col md="4" lg="2">
          <Card>
            <CardHeader>
              <LoadChild root={user.username} docs={treedocs} initialUsers={initialUsers}/>
            </CardHeader>
          </Card>
        </Col>
        <Col md="8" lg="10">
          <Card>
            <CardHeader>
              <h6>
                Account: {user.username}
              </h6>
            </CardHeader>
            <CardBody>
              {docs.length
                ? <Table striped responsive hover style={{ textAlign: 'center' }}>
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: '15%' }}>{t('ACCOUNT')}</th>
                      <th scope="col" style={{ width: '15%' }}>{t('ROLE')}</th>
                      {respGames.map(({ game_code, name, label}) => <th key={game_code+label} scope="col" >{label ? label : name}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {<Comm respGames={respGames} stage={stage} setStage={setStage} data={stage.commisions[stage.commSet]} toggleTree={toggleTree} showModalDefault={showModalDefault} t={t}  showModalPT1={showModalPT1} setCommDATA={setCommDATA} t={t} />}
                    {docs.map((item, index) =>
                      stage.commisions && <User respGames={respGames} key={index} {...item} toggleTree={toggleTree} showModalPT={showModalPT} t={t}  showModalPT1={showModalPT1} setCommDATA={setCommDATA} commisions={stage.commisions}/>
                    )}
                  </tbody>
                </Table>
                : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Dialog open={!isEmpty(ptModal)} onClose={() => updatePTModal({})} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('UPDATE_PERCEN')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {ptModal.game_code}
          </DialogContentText>
          <form id="withdraw-form" onSubmit={handleSubmit} noValidate>
            <TextInput style={{ marginRight: 15 }} input={comm} type="number" max="100" label='Percentage' />
            <Button type="submit" variant="contained" color="primary">{t('UPDATE')}</Button>
          </form>
          {ptModal.error
            ? <p style={{ color: 'red' }}>{ptModal.error}</p>
            : null}
        </DialogContent>
      </Dialog>
      <Dialog open={!isEmpty(defaultModal)} onClose={() => updateDefaultModal({})} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('UPDATE_PERCEN')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {defaultModal.game_code}
          </DialogContentText>
          <div id="withdraw-form">
            <TextField style={{ marginRight: 15 }} value={commUpdate} onChange={e => setCommUpdate(e.target.value)} type="number" max="100" label='Percentage' />
            <Button onClick={onSubmitDefault} variant="contained" color="primary">{t('UPDATE')}</Button>
          </div>
          {defaultModal.error
            ? <p style={{ color: 'red' }}>{defaultModal.error}</p>
            : null}
        </DialogContent>
      </Dialog>
      <ErroDialog/>
    </div>
  )
}

export default Users;
