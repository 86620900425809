import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, Col, Row, Table, Button } from 'reactstrap'
import http from 'service/http'
import { useForm } from 'react-final-form-hooks'
import { TextInput, useLoading, SelectInput } from 'feature/Component'
import { FormControl } from '@material-ui/core'
import formCreateInputs from 'service/form/create'
import numeral from 'numeral'
import store from 'store'
import './style.scss'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import LoadingUi from 'feature/Component/Loading/ui'

// const DEFAULT_FROM_DATE = moment().startOf('month').format('YYYY-MM-DD')
const DEFAULT_FROM_DATE = moment().format('YYYY-MM-DD')
const DEFAULT_TO_DATE = moment().format('YYYY-MM-DD')
let user = store.get('user') || { level: '' }

const correctNumber = amount => numeral(amount).format('0,0.000')

function HistoryRow({ partner, date }) {
  let downlineReciever = partner.winlose.parentTotal + partner.winlose.playerTotal
  downlineReciever = Number(downlineReciever.toFixed(3))
  // console.log(downlineReciever, partner.winlose.parentTotal, partner.winlose.playerWinlose)
  return (
    <tr>
      <td style={{textAlign: 'left'}}><a href={'/report-payable/partner/' + partner.username + '?banker=' + partner.lotto97Banker + '&fromDate=' + date.fromDate + '&toDate=' + date.toDate}><b>{partner.username}</b></a></td>
      <td>{correctNumber(partner.winlose.totalTurnover)}</td>
      <td className="downline" style={{
        color: partner.winlose.playerWinlose >= 0 ? 'green' : 'red'
      }}>{correctNumber(partner.winlose.playerWinlose)}</td>
      <td className="downline" style={{
        color: partner.winlose.playerComm >= 0 ? 'green' : 'red'
      }}>{correctNumber(partner.winlose.playerComm)}</td>
      <td className="downline" style={{
        color: partner.winlose.playerTotal >= 0 ? 'green' : 'red'
      }}>{correctNumber(partner.winlose.playerTotal)}</td>
      <td className="downline" style={{
        color: downlineReciever >= 0 ? 'green' : 'red'
      }}>{correctNumber(downlineReciever)}</td>

      <td className="upline">{correctNumber(partner.winlose.parentSale)}</td>
      <td className="upline">{correctNumber(partner.winlose.totalParentComm)}</td>
      <td className="upline">{0}</td>
      <td className="upline" style={{
        color: partner.winlose.parentWinlose >= 0 ? 'green' : 'red'
      }}>{correctNumber(partner.winlose.parentWinlose)}</td>
      <td className="upline" style={{
        color: partner.winlose.parentTotal >= 0 ? 'green' : 'red'
      }}>{correctNumber(partner.winlose.parentTotal)}</td>

      <td className="company" style={{
        color: partner.winlose.companyTotal >= 0 ? 'green' : 'red'
      }}>{correctNumber(partner.winlose.companyTotal)}</td>

    </tr>
  )
}

function History(props) {
  const { t } = useTranslation()
  let total1 = {
    playerComm: 0,
    playerWinlose: 0,
    playerTotal: 0,
    downlineReceivable: 0,
    turnover: 0,
    downline: {
      sale: 0,
      comm: 0,
      winlose: 0,
      total: 0,
    },
    admin: {
      sale: 0,
      comm: 0,
      winlose: 0,
      total: 0,
    },
    companyTotal: 0,
  }
  user = props
  const [loading, withLoading] = useLoading()
  const [ partners, updatePartners] = useState([])
  const [ total, updateTotal] = useState(total1)
  const { user_level: role, email, lotto97Banker } = store.get('user')
  const [initialInputs, updateInitialInputs] = useState({})
  const onSubmit = async (params) => {
    // console.log(params)
    loadReposrt(params)

  }
  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: initialInputs
  })
  const [fromDate, toDate] = formCreateInputs(['fromDate', 'toDate'], form)

  useEffect(() => {
    if(props.user_level == 'PARTNER') window.location.href = '/report-payable/partner/' + props.username + '?fromDate=' +DEFAULT_FROM_DATE + '&toDate=' + DEFAULT_TO_DATE + '&banker=' + props.lotto97Banker
    else if(props.user_level == 'AGENT') window.location.href = '/report-payable/agent/' + props.username + '?fromDate=' +DEFAULT_FROM_DATE + '&toDate=' + DEFAULT_TO_DATE + '&banker=' + props.lotto97Banker
    else loadReposrt({
      fromDate: DEFAULT_FROM_DATE,
      toDate: DEFAULT_TO_DATE,
    })
  }, [])
  const loadReposrt = (date) => {

    http.get({ path: `admin/partners` }).then(pn => {
      updatePartners(pn);
      // console.log(pn)
      const newPn = [...pn]
      withLoading(() => Promise.all(pn.map((p, i) => {
        http.get({ path: `admin/report/partner/` + p.username, params: {...date, banker: p.lotto97Banker || 'abc'} }).then(rs => {
          newPn[i].winlose = rs.winlose
          total1 = {
            playerComm: total1.playerComm + rs.winlose.playerComm,
            playerWinlose: total1.playerWinlose + rs.winlose.playerWinlose,
            playerTotal: total1.playerTotal + rs.winlose.playerTotal,
            downlineReceivable: total1.downlineReceivable + rs.winlose.playerTotal + rs.winlose.parentTotal,
            turnover: total1.turnover + rs.winlose.totalTurnover,
            downline: {
              sale: total1.downline.sale + rs.winlose.downlineSale,
              comm: total1.downline.comm + rs.winlose.totalComm,
              winlose: total1.downline.winlose + rs.winlose.downlineWinlose,
              total: total1.downline.total + rs.winlose.total,
            },
            admin: {
              sale: total1.admin.sale + rs.winlose.parentSale,
              comm: total1.admin.comm + rs.winlose.totalParentComm,
              winlose: total1.admin.winlose + rs.winlose.parentWinlose,
              total: total1.admin.total + rs.winlose.parentTotal,
            },
            companyTotal: total1.companyTotal + rs.winlose.companyTotal,
          }


          // console.log(total1)
          // if(i == pn.length - 1) {
          updatePartners(newPn)
          updateTotal(total1)
          updateInitialInputs({
            fromDate: date.fromDate,
            toDate: date.toDate,

          })
          // }
        })
      })))

    })
  }

  return (
    <div className="animated fadeIn">
      {loading && <LoadingUi/>}
      <Row>
        <Col xl={12}>
          <Card>

            <CardHeader>
              <form onSubmit={handleSubmit}>
                <TextInput style={{ marginLeft: 15, width: 200 }} input={fromDate} label={t('FROM')} type='date'
                           InputLabelProps={{
                             shrink: true
                           }}/>
                <TextInput style={{ marginLeft: 15, width: 200 }} input={toDate} label={t('TO')} type='date'
                           InputLabelProps={{
                             shrink: true
                           }}/>
                <FormControl style={{ marginTop: 30, marginLeft: 15 }}>
                  {loading ? t('LOADING') + ' ...' : <Button type='submit' color="info" className="px-4">{t('SEARCH')}</Button>}
                </FormControl>
              </form>
            </CardHeader>
            <CardBody>

              <React.Fragment>
                <Table responsive hover className="fullReportTable">
                  <thead>

                  <tr style={{ background: 'rgb(5 48 130)', color: 'white' }}>

                    <th scope="col" rowSpan="2" className="rowSpan">{t('DOWNLINE_ACCOUNT')}</th>
                    <th scope="col" rowSpan="2" className="rowSpan">{t('DOWNLINE_TURNOVER')}</th>
                    <th scope="col" rowSpan="2" className="rowSpan">{t('PLAYER_WINLOSE')}</th>
                    <th scope="col" rowSpan="2" className="rowSpan">{t('PLAYER_COMM')}</th>
                    <th scope="col" rowSpan="2" className="rowSpan">{t('PLAYER_TOTAL')}</th>
                    <th scope="col" rowSpan="2" className="rowSpan">{t('DOWNLINE_RECEIIVABLE')}</th>
                    <th scope="col" colSpan="5" className="colSpan">{t('ADMIN')}</th>
                    <th rowSpan="2" className="rowSpan">{t('UPLINE_PAYABLE')}</th>
                  </tr>
                  <tr>
                    <th>{t('SALE')}</th>
                    <th>{t('EARN_COMM')}</th>
                    <th>{t('PAY_COMM')}</th>
                    <th>{t('WINLOSE')}</th>
                    <th>{t('TOTAL')}</th>
                  </tr>
                  </thead>
                  {partners.length ?
                    <tbody>
                    {partners.map((partner, i) =>
                      partner.winlose.totalTurnover > 0 && <HistoryRow partner={partner} date={initialInputs} index={i++} key={i}/>
                    )}
                    <tr style={{ fontWeight: 'bold', background: '#8f9ba68a' }}>
                      <td>Total</td>
                      <td>{correctNumber(total.turnover)}</td>
                      <td style={{
                        color: total.playerWinlose >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.playerWinlose)}</td>
                      <td style={{
                        color: total.playerComm >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.playerComm)}</td>
                      <td style={{
                        color: total.playerTotal >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.playerTotal)}</td>
                      <td style={{
                        color: total.downlineReceivable >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.downlineReceivable)}</td>

                      <td>{correctNumber(total.admin.sale)}</td>
                      <td>{correctNumber(total.admin.comm)}</td>
                      <td>{0}</td>
                      <td style={{
                        color: total.admin.winlose >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.admin.winlose)}</td>
                      <td style={{
                        color: total.admin.total >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.admin.total)}</td>
                      <td>{correctNumber(total.companyTotal)}</td>
                    </tr>

                    </tbody>
                    : <tbody>
                    <tr>
                      <td>{t('NO_DATA')}</td>
                    </tr>
                    </tbody>
                  }
                </Table>
              </React.Fragment>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </div>
  )
}

export default History