import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, Col, Row, Table, Button } from 'reactstrap'
import http from 'service/http'
import { useForm } from 'react-final-form-hooks'
import { TextInput, useLoading, SelectInput } from 'feature/Component'
import { FormControl } from '@material-ui/core'
import formCreateInputs from 'service/form/create'
import numeral from 'numeral'
import store from 'store'
import './style.scss'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import LoadingUi from 'feature/Component/Loading/ui'

// const DEFAULT_FROM_DATE = moment().startOf('month').format('YYYY-MM-DD')
const DEFAULT_FROM_DATE = moment().format('YYYY-MM-DD')
const DEFAULT_TO_DATE = moment().format('YYYY-MM-DD')
let user = store.get('user') || { level: '' }

const correctNumber = amount => numeral(amount).format('0,0.0000')

function HistoryRow({ partner, date }) {
  // console.log(partner)
  const {report} = partner
  const selfProfit = report.winlose ? report.winlose.parentTotal + report.winlose.commNewReport : 0
  // console.log(downlineReciever)
  return (
    <tr>
      <td style={{textAlign: 'left'}}>
        {partner.role != 'PLAYER' && <a href={'/reports-new-partner?parent=' + partner.username + '&fromDate=' + date.fromDate + '&toDate=' + date.toDate + '&banker=' + partner.lotto97Banker}><b>{partner.username}</b></a>}
        {partner.role == 'PLAYER' && <b>{partner.username}</b>}
      </td>
      <td style={{color: 'green'}}>{correctNumber(report.depositAmount)}</td>
      <td style={{color: 'red'}}>{correctNumber(report.withdrawAmount)}</td>
      <td>{correctNumber(report.amountRabat)}</td>
      <td>{correctNumber(report.winlose.totalBetAmount)}</td>
      <td>{correctNumber(report.winlose.totalTurnover)}</td>

      <td style={{
        color: report.winlose.playerWinlose >= 0 ? 'green' : 'red'
      }}>{correctNumber(report.winlose.playerWinlose)}</td>
      <td>{correctNumber(report.winlose.playerComm)}</td>
      <td style={{
        color: report.winlose.playerTotal >= 0 ? 'green' : 'red'
      }}>{correctNumber(report.winlose.playerTotal)}</td>
      <td className="downline" style={{
        color: report.winlose.downlineRecieverNewReport >= 0 ? 'green' : 'red'
      }}>{correctNumber(report.winlose.downlineRecieverNewReport)}</td>
      <td style={{
        color: report.winlose.parentWinlose >= 0 ? 'green' : 'red'
      }}>{correctNumber(report.winlose.parentWinlose)}</td>
      <td>{correctNumber(report.winlose.commNewReport)}</td>
      <td style={{
        color: selfProfit >= 0 ? 'green' : 'red'
      }}>{correctNumber(selfProfit)}</td>
      <td style={{
        color: report.winlose.uplinePayableNewReport >= 0 ? 'green' : 'red'
      }}>{correctNumber(report.winlose.uplinePayableNewReport)}</td>
    </tr>
  )
}

function History(props) {
  const { t } = useTranslation()
  let total1 = {
    playerComm: 0,
    playerWinlose: 0,
    playerTotal: 0,
    downlineReceivable: 0,
    turnover: 0,
    downline: {
      sale: 0,
      comm: 0,
      winlose: 0,
      total: 0,
    },
    admin: {
      sale: 0,
      comm: 0,
      winlose: 0,
      total: 0,
    },
    companyTotal: 0,
  }
  user = props
  const query = new URLSearchParams(props.location.search);
  const [loading, withLoading] = useLoading()
  const [ partners, updatePartners] = useState([])
  const [ total, updateTotal] = useState(total1)
  const { user_level: role, email, lotto97Banker } = store.get('user')
  const [initialInputs, updateInitialInputs] = useState({})
  const onSubmit = async (params) => {
    // console.log(params)
    loadReposrt(params)

  }
  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: initialInputs
  })
  const [fromDate, toDate] = formCreateInputs(['fromDate', 'toDate'], form)

  useEffect(() => {
    loadReposrt({
      fromDate: query.get('fromDate') || DEFAULT_FROM_DATE,
      toDate: query.get('toDate') || DEFAULT_TO_DATE,
    })
  }, [])
  const loadReposrt = (date) => {
    withLoading(() => http.get({ path: `admin/new-report/`, params: {
        ...date,
        banker: query.get('banker') || user.lotto97Banker || undefined,
        parent: query.get('parent') || undefined
      }
    }).then(rs => {
      // console.log(rs)
      updatePartners(rs)
      updateInitialInputs({
        fromDate: date.fromDate,
        toDate: date.toDate,
      })
    }))
  }

  return (
    <div className="animated fadeIn">
      {loading && <LoadingUi/>}
      <Row>
        <Col xl={12}>
          <Card>

            <CardHeader>
              <form onSubmit={handleSubmit}>
                <TextInput style={{ marginLeft: 15, width: 200 }} input={fromDate} label={t('FROM')} type='date'
                           InputLabelProps={{
                             shrink: true
                           }}/>
                <TextInput style={{ marginLeft: 15, width: 200 }} input={toDate} label={t('TO')} type='date'
                           InputLabelProps={{
                             shrink: true
                           }}/>
                <FormControl style={{ marginTop: 30, marginLeft: 15 }}>
                  {loading ? t('LOADING') + ' ...' : <Button type='submit' color="info" className="px-4">{t('SEARCH')}</Button>}
                </FormControl>
              </form>
            </CardHeader>
            <CardBody>

              <React.Fragment>
                <Table responsive hover className="fullReportTable">
                  <thead>

                  <tr style={{ background: 'rgb(5 48 130)', color: 'white' }}>

                    <th scope="col">{t('USERNAME')}</th>
                    <th scope="col">{t('DEPOSITS')}</th>
                    <th scope="col">{t('WITHDRAW')}</th>
                    <th scope="col">{t('RABAT_BONUS')}</th>
                    <th scope="col">{t('BET_AMOUNT')}</th>
                    <th scope="col">{t('TURNOVER')}</th>
                    <th scope="col">{t('WINLOSE')}</th>
                    <th>{t('COMM')}</th>
                    <th>{t('PLAYER_PROFIT')}</th>
                    <th>{t('DOWNLINE_RECEIIVABLE')}</th>
                    <th>{t('SHARE_WINLOSE')}</th>
                    <th>{t('SHARE_COMM')}</th>
                    <th>{t('SELF_WINLOSE')}</th>
                    <th>{t('UPLINE_PAYABLE')}</th>
                  </tr>

                  </thead>
                  {partners.length ?
                    <tbody>
                    {partners.map((partner, i) => {
                      // console.log(partner)
                      return < HistoryRow
                        partner = { partner }
                        date = { initialInputs }
                        index = { i++ }
                        key = { i }
                      />
                    })}
                    {/*<tr style={{ fontWeight: 'bold', background: '#8f9ba68a' }}>*/}
                    {/*<td>Total</td>*/}
                    {/*<td>{correctNumber(total.turnover)}</td>*/}
                    {/*<td style={{*/}
                    {/*color: total.playerWinlose >= 0 ? 'green' : 'red'*/}
                    {/*}}>{correctNumber(total.playerWinlose)}</td>*/}
                    {/*<td style={{*/}
                    {/*color: total.playerComm >= 0 ? 'green' : 'red'*/}
                    {/*}}>{correctNumber(total.playerComm)}</td>*/}
                    {/*<td style={{*/}
                    {/*color: total.playerTotal >= 0 ? 'green' : 'red'*/}
                    {/*}}>{correctNumber(total.playerTotal)}</td>*/}
                    {/*<td style={{*/}
                    {/*color: total.downlineReceivable >= 0 ? 'green' : 'red'*/}
                    {/*}}>{correctNumber(total.downlineReceivable)}</td>*/}

                    {/*<td>{correctNumber(total.admin.sale)}</td>*/}
                    {/*<td>{correctNumber(total.admin.comm)}</td>*/}
                    {/*<td>{0}</td>*/}
                    {/*<td style={{*/}
                    {/*color: total.admin.winlose >= 0 ? 'green' : 'red'*/}
                    {/*}}>{correctNumber(total.admin.winlose)}</td>*/}
                    {/*<td style={{*/}
                    {/*color: total.admin.total >= 0 ? 'green' : 'red'*/}
                    {/*}}>{correctNumber(total.admin.total)}</td>*/}
                    {/*<td>{correctNumber(total.companyTotal)}</td>*/}
                    {/*</tr>*/}

                    </tbody>
                    : <tbody>
                    <tr>
                      <td>{t('NO_DATA')}</td>
                    </tr>
                    </tbody>
                  }
                </Table>
              </React.Fragment>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </div>
  )
}

export default History