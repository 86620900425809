import React, { useEffect } from 'react'
import { Button, Card, CardBody, CardGroup, Col, Container, InputGroup, Row } from 'reactstrap'
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import http from 'service/http'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'
import { SelectInput, TextInput, useDialog } from 'feature/Component'
import { useTranslation } from 'react-i18next'

function EditUser(props) {
  const { t } = useTranslation()
  const {
    match: {
      params: { id }
    }
  } = props

  const [user, setUser] = React.useState({})
  const [initialValues, updateInitialForm] = React.useState({
    user_inactive: '0',
    newwin_token: '',
    newwin_url: '',
    user_level: '',
    password: '',
    fundPass: ''
  })

  const initialAsyncForm = async () => {
    const { user } = await http.get({ path: `admin/users/${id}` })
    setUser(user)
    const { user_inactive, newwin_token, newwin_url, fundpass } = user
    updateInitialForm({ user_inactive: user_inactive.toString(), newwin_token: newwin_token || '', newwin_url: newwin_url || '', fundPass: fundpass, password: '' })
  }

  useEffect(() => {
    initialAsyncForm()
  }, [])
// console.log(initialValues)
  return initialValues ? <FormAsync t={t} {...props} user_level={user.user_level} initialValues={initialValues}/> : t('LOADING') + ' ...'
}

function FormAsync({
                     initialValues, user_level, history, match: {
    params: { id }
  }, t, username: adminUsername
                   }) {
  const [Dialog, showDialogWithMessage] = useDialog({
    title: t('UPDATE_USER_SUCCESS')
  })
  const onSubmit = async payload => {
    // console.log(payload)
    if(payload.password == '') payload.password = undefined
    if(payload.fundPass == '' || payload.fundPass == null) payload.fundPass = undefined
    try {
      await http.put({ path: `admin/users/${id}`, payload })
      showDialogWithMessage(t('YOU_REQUEST_SUBMITED_SUCCESS'))
    } catch (error) {
      throw error
    }
  }

  const schema = Joi.object().keys({
    newwin_token: Joi.string().allow(null).allow(''),
    newwin_url: Joi.string().uri().allow(null).allow(''),
    user_inactive: Joi.string(),
    password: Joi.string().regex(/^[a-zA-Z0-9]{3,30}$/).allow(null).allow(''),
    fundPass: Joi.string().min(4).max(20).regex(/^[a-zA-Z0-9]{3,30}$/).allow(null).allow('')
  })

  const { form, handleSubmit } = useForm({
    onSubmit,
    validate: validate(schema),
    initialValues
  })

  const [user_inactive, password, newwin_token, newwin_url, fundPass] = formCreateInputs(['user_inactive', 'password', 'newwin_token', 'newwin_url', 'fundPass'], form)

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" md="6" lg="5">
            <form onSubmit={handleSubmit}>
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h1>{t('UPDATE_USER')}</h1>
                    <InputGroup className="mb-3">
                      <SelectInput options={[{
                        title: t('ACTIVE'),
                        value: '0'
                      }, {
                        title: t('INACTIVE'),
                        value: '1'
                      }]} input={user_inactive} label={t('SELECT_STATUS')} fullWidth/>
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <TextInput input={password} type="password" label={t('ENTER_PASS')} fullWidth/>
                    </InputGroup>
                    {adminUsername == 'admin' && user_level == 'PLAYER' && <InputGroup className="mb-4">
                      <TextInput input={fundPass} type="password" label={t('ENTER_2ndPASS')} fullWidth/>
                    </InputGroup>}
                    <InputGroup className="mb-4">
                      <TextInput input={newwin_token} type="text" label={t('ENTER_NEWWIN_TOKEN')} fullWidth/>
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <TextInput input={newwin_url} type="text" label={t('ENTER_NEWWIN_URL')} fullWidth/>
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button type='submit' color="info" className="px-4">{t('UPDATE')}</Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </form>
          </Col>
        </Row>
      </Container>
      <Dialog/>
    </div>
  )
}

export default EditUser
