import React, { Component } from 'react';
import {
  Route,
  BrowserRouter as Router
} from "react-router-dom"
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'

// Containers
import { DefaultLayout } from './containers';
// Pages
import Login from 'feature/Login'
import Register from 'feature/Register'


const theme = createMuiTheme({
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      // 'AIFont'
    ].join(','),
  },
  palette: {
    primary: {
        main: '#14805E'
      }
    }
  },
)

class App extends Component {

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <div>
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route path="/register" name="Register Page" component={Register} />
            <Route path="/" name="Home" component={DefaultLayout}/>
          </div>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
