import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, Col, Row, Table, Button } from 'reactstrap'
import http from 'service/http'
import { useForm } from 'react-final-form-hooks'
import { TextInput, useLoading, SelectInput } from 'feature/Component'
import { FormControl } from '@material-ui/core'
import formCreateInputs from 'service/form/create'
import numeral from 'numeral'
import store from 'store'
import './style.scss'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import LoadingUi from 'feature/Component/Loading/ui'

// const DEFAULT_FROM_DATE = moment().startOf('month').format('YYYY-MM-DD')
const DEFAULT_FROM_DATE = moment().format('YYYY-MM-DD')
const DEFAULT_TO_DATE = moment().format('YYYY-MM-DD')
let user = store.get('user') || { level: '' }

const correctNumber = amount => numeral(amount).format('0,0.000')

function HistoryRow({ partner, date }) {

  return (
    <tr>
      <td style={{textAlign: 'left'}}><a href={'/report/partner/' + partner.username + '?banker=' + partner.lotto97Banker + '&fromDate=' + date.fromDate + '&toDate=' + date.toDate}><b>{partner.username}</b></a></td>
      <td>{correctNumber(partner.winlose.totalTurnover)}</td>
      <td className="downline">{correctNumber(partner.winlose.downlineSale)}</td>
      <td className="downline">{correctNumber(partner.winlose.totalComm)}</td>
      <td className="downline" style={{
        color: partner.winlose.downlineWinlose >= 0 ? 'green' : 'red'
      }}>{correctNumber(partner.winlose.downlineWinlose)}</td>
      <td className="downline" style={{
        color: partner.winlose.total >= 0 ? 'green' : 'red'
      }}>{correctNumber(partner.winlose.total)}</td>
      <td className="upline">{correctNumber(partner.winlose.parentSale)}</td>
      <td className="upline">{correctNumber(partner.winlose.totalParentComm)}</td>
      <td className="upline" style={{
        color: partner.winlose.parentWinlose >= 0 ? 'green' : 'red'
      }}>{correctNumber(partner.winlose.parentWinlose)}</td>
      <td className="upline" style={{
        color: partner.winlose.parentTotal >= 0 ? 'green' : 'red'
      }}>{correctNumber(partner.winlose.parentTotal)}</td>

      <td className="company" style={{
        color: partner.winlose.companyTotal >= 0 ? 'green' : 'red'
      }}>{correctNumber(partner.winlose.companyTotal)}</td>

      <td>0</td>
      <td>0</td>
    </tr>
  )
}

function History(props) {
  const { t } = useTranslation()
  let total1 = {
    turnover: 0,
    downline: {
      sale: 0,
      comm: 0,
      winlose: 0,
      total: 0,
    },
    admin: {
      sale: 0,
      comm: 0,
      winlose: 0,
      total: 0,
    },
    companyTotal: 0,
    totalNet: 0,
  }
  user = props
  const [loading, withLoading] = useLoading()
  const [ partners, updatePartners] = useState([])
  const [ total, updateTotal] = useState(total1)
  const [ isSync, setIsSync] = useState(false)
  const { user_level: role, email, lotto97Banker } = store.get('user')
  const [initialInputs, updateInitialInputs] = useState({})
  const onSubmit = async (params) => {
    // console.log(params)
    loadReposrt(params)

  }
  const sync97Lotto = () => {
    setIsSync(true)
    const date = initialInputs
    withLoading(() => http.get({ path: `admin/97lotto/full-winlose`, params: date }).then(rs => {
      // console.log(rs)
      const pn = [...partners]
      let total2 = {...total}
      rs.map((p, i) => {
        const newPn = pn.map(x => {
          if(x.lotto97Banker == p.banker) {
            x.winlose = {
              totalTurnover: x.winlose.totalTurnover + p.winlose.totalTurnover,
              downlineSale: x.winlose.downlineSale + p.winlose.downlineSale,
              totalComm: x.winlose.totalComm + p.winlose.totalComm,
              downlineWinlose: x.winlose.downlineWinlose + p.winlose.downlineWinlose,
              total: x.winlose.total + p.winlose.total,
              parentSale: x.winlose.parentSale + p.winlose.parentSale,
              totalParentComm: x.winlose.totalParentComm + p.winlose.totalParentComm,
              parentWinlose: x.winlose.parentWinlose + p.winlose.parentWinlose,
              parentTotal: x.winlose.parentTotal + p.winlose.parentTotal,
              companyTotal: x.winlose.companyTotal + p.winlose.companyTotal,
              totalNet: 0,
            }
          }
          return x
        })
        updatePartners(newPn);

        // console.log(total2)
        total2 = {
          turnover: total2.turnover + p.winlose.totalTurnover,
          downline: {
            sale: total2.downline.sale + p.winlose.downlineSale,
            comm: total2.downline.comm + p.winlose.totalComm,
            winlose: total2.downline.winlose + p.winlose.downlineWinlose,
            total: total2.downline.total + p.winlose.total,
          },
          admin: {
            sale: total2.admin.sale + p.winlose.parentSale,
            comm: total2.admin.comm + p.winlose.totalParentComm,
            winlose: total2.admin.winlose + p.winlose.parentWinlose,
            total: total2.admin.total + p.winlose.parentTotal,
          },
          companyTotal: total2.companyTotal + p.winlose.companyTotal,
          totalNet: 0,
        }
        updateTotal(total2)
      })
    }))
  }
  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: initialInputs
  })
  const [fromDate, toDate] = formCreateInputs(['fromDate', 'toDate'], form)

  useEffect(() => {
    if(props.user_level == 'PARTNER') window.location.href = '/report/partner/' + props.username + '?fromDate=' +DEFAULT_FROM_DATE + '&toDate=' + DEFAULT_TO_DATE + '&banker=' + props.lotto97Banker
    else if(props.user_level == 'AGENT') window.location.href = '/report/agent/' + props.username + '?fromDate=' +DEFAULT_FROM_DATE + '&toDate=' + DEFAULT_TO_DATE + '&banker=' + props.lotto97Banker
    else loadReposrt({
      fromDate: DEFAULT_FROM_DATE,
      toDate: DEFAULT_TO_DATE,
    })
  }, [])
  const loadReposrt = (date) => {
    setIsSync(false)
    http.get({ path: `admin/partners` }).then(pn => {
      updatePartners(pn);
      // console.log(pn)
      const newPn = [...pn]
      withLoading(() => Promise.all(pn.map((p, i) => {
        return http.get({ path: `admin/report/partner/` + p.username, params: {...date, banker: p.lotto97Banker || 'abc'} }).then(rs => {
          newPn[i].winlose = rs.winlose
          total1 = {
            turnover: total1.turnover + rs.winlose.totalTurnover,
            downline: {
              sale: total1.downline.sale + rs.winlose.downlineSale,
              comm: total1.downline.comm + rs.winlose.totalComm,
              winlose: total1.downline.winlose + rs.winlose.downlineWinlose,
              total: total1.downline.total + rs.winlose.total,
            },
            admin: {
              sale: total1.admin.sale + rs.winlose.parentSale,
              comm: total1.admin.comm + rs.winlose.totalParentComm,
              winlose: total1.admin.winlose + rs.winlose.parentWinlose,
              total: total1.admin.total + rs.winlose.parentTotal,
            },
            companyTotal: total1.companyTotal + rs.winlose.companyTotal,
            totalNet: 0,
          }


          // console.log(total1)
          // if(i == pn.length - 1) {
          updatePartners(newPn)
          updateTotal(total1)
          updateInitialInputs({
            fromDate: date.fromDate,
            toDate: date.toDate,

          })
          // }
        })
      })))

    })
  }

  return (
    <div className="animated fadeIn">
      {loading && <LoadingUi/>}
      <Row>
        <Col xl={12}>
          <Card>

            <CardHeader>
              <form onSubmit={handleSubmit} style={{float: 'left'}}>
                <TextInput style={{ marginLeft: 15, width: 200 }} input={fromDate} label={t('FROM')} type='date'
                           InputLabelProps={{
                             shrink: true
                           }}/>
                <TextInput style={{ marginLeft: 15, width: 200 }} input={toDate} label={t('TO')} type='date'
                           InputLabelProps={{
                             shrink: true
                           }}/>
                <FormControl style={{ marginTop: 30, marginLeft: 15 }}>
                  {loading ? t('LOADING') + ' ...' : <Button type='submit' color="info" className="px-4">{t('SEARCH')}</Button>}

                </FormControl>

              </form>
              {/*<FormControl style={{ marginTop: 30, marginLeft: 15, float: 'left' }}>*/}
                {/*<Button type='btn' color="primary" onClick={sync97Lotto} disabled={isSync} className="px-4">{t('SYNC_REAL_REPORT')}</Button>*/}

              {/*</FormControl>*/}
            </CardHeader>
            <CardBody>

              <React.Fragment>
                <Table responsive hover className="fullReportTable">
                  <thead>

                  <tr style={{ background: 'rgb(5 48 130)', color: 'white' }}>

                    <th scope="col" rowSpan="2" className="rowSpan">{t('DOWNLINE_ACCOUNT')}</th>
                    <th scope="col" rowSpan="2" className="rowSpan">{t('DOWNLINE_TURNOVER')}</th>
                    <th scope="col" colSpan="4" className="colSpan">{t('DOWNLINE')}</th>
                    <th scope="col" colSpan="4" className="colSpan">{t('ADMIN')}</th>
                    <th rowSpan="2" className="rowSpan">{t('COMPANY_TOTAL')}</th>
                    <th scope="col" rowSpan="2" className="rowSpan">{t('SHARE_PERCEN')}</th>
                    <th scope="col" rowSpan="2" className="rowSpan">{t('TOTAL_NET')}</th>
                  </tr>
                  <tr>
                    <th>{t('SALE')}</th>
                    <th>{t('COMMISSIONS')}</th>
                    <th>{t('WINLOSE')}</th>
                    <th>{t('TOTAL')}</th>
                    <th>{t('SALE')}</th>
                    <th>{t('COMMISSIONS')}</th>
                    <th>{t('WINLOSE')}</th>
                    <th>{t('TOTAL')}</th>
                  </tr>
                  </thead>
                  {partners.length ?
                    <tbody>
                    {partners.map((partner, i) =>
                      partner.winlose.totalTurnover > 0 && <HistoryRow partner={partner} date={initialInputs} index={i++} key={i}/>
                    )}
                    <tr style={{ fontWeight: 'bold', background: '#8f9ba68a' }}>
                      <td>Total</td>
                      <td>{correctNumber(total.turnover)}</td>
                      <td>{correctNumber(total.downline.sale)}</td>
                      <td>{correctNumber(total.downline.comm)}</td>
                      <td style={{
                        color: total.downline.winlose >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.downline.winlose)}</td>
                      <td style={{
                        color: total.downline.total >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.downline.total)}</td>
                      <td>{correctNumber(total.admin.sale)}</td>
                      <td>{correctNumber(total.admin.comm)}</td>
                      <td style={{
                        color: total.admin.winlose >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.admin.winlose)}</td>
                      <td style={{
                        color: total.admin.total >= 0 ? 'green' : 'red'
                      }}>{correctNumber(total.admin.total)}</td>
                      <td>{correctNumber(total.companyTotal)}</td>
                      <td>0</td>
                      <td>{total.totalNet}</td>
                    </tr>

                    </tbody>
                    : <tbody>
                    <tr>
                      <td>{t('NO_DATA')}</td>
                    </tr>
                    </tbody>
                  }
                </Table>
              </React.Fragment>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </div>
  )
}

export default History