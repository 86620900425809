import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, CardFooter, Col, Row, Form, FormGroup, Label, Input, Button,
  Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap'
import http from 'service/http';
import moment from 'moment';
import { TextInput, useDialog, useLoading } from 'feature/Component'
import { useTranslation } from 'react-i18next'

import './style.css'

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function Banking (props) {
  const {
    match: {
      params: { id }
    },
  } = props
  const options = []
  let i = -1
  while (++i <= 200) options.push(i);
  const { t } = useTranslation()
  const classes = useStyles();
  const [loading, withLoading] = useLoading()
  const [errName, setErrName] = useState('');
  const [period, setPeriod] = useState(null)
  const [target, setTarget] = useState([
    {min: 0,
      max: 0,
      days: 0, type: 'Deposit amount'},{min: 0,
      max: 0,
      days: 0, type: 'Deposit times'},{min: 0,
      max: 0,
      days: 0, type: 'Win amount'},{min: 0,
      max: 0,
      days: 0, type: 'Lost amount'},{min: 0,
      max: 0,
      days: 0, type: 'Turnover'}
  ]);

  const [promotions, setPromotions] = useState({
    appear: {
      title: '',
      cycle: 'Daily',
      promotionPeriod: {
        from: '00:00', to: '23:59'
      },
      status: true,
      targetSwitch: false,
      confirmBuyAdmin: false,
      useLimit: 1,
      target: {
        type: 'Deposit amount',
        min: 0,
        max: 0,
        days: 0 // timestamp
      } },
    withdraw: {
      mode: 'Turnover',
      rollover: 0,
      games: [],
      gameAll: false,
    },
    bonus: {
      switch: '',
      rollover: 0,
      percent: 0,
      games: [],
      gameAll: false,
      maxBonus: 0
    },
    commission: {
      switch: false,
      noComm: false,
      percent: 0,
      games: [],
      gameAll: false,
    }
  })

  const [Dialog1, showDialogWithMessage] = useDialog({
    title: t('SUCCESS')
  })
  const [DialogError, showDialogErrorWithMessage] = useDialog({
    title: t('ERROR'),
    type: 'error'
  })

  const initial = async () => {
    // const newPro = {...promotions}
    const [rs, pro] = await withLoading(() => Promise.all([
      http.get({ path: `games` }),
      http.get({ path: `admin/promotion-new/${id}` }),
    ]))


    const prom = JSON.parse(pro.item.data)
    setPromotions(prom)
    // console.log(prom.appear.target.type)
    const newTarget = target.map(t => {
      if(t.type == prom.appear.target.type) t = {...prom.appear.target}
      return t
    })
    setPeriod(prom.appear.promotionPeriod)
    setTarget(newTarget)
    // console.log(prom.appear.promotionPeriod, period)
  }
  const handleClick = (name) => {
    const elements = document.querySelector('input[name='+name+']')
    // console.log(elements.value)
    const newObject = {...promotions}
    newObject.appear.confirmBuyAdmin = !newObject.appear.confirmBuyAdmin
    setPromotions(newObject)

  }
  useEffect(() => {
    initial()
  }, [])
  const updateTarget = (index, key, e) => {
    const newObject = {...target}
    newObject[index][key] = e.target.value
    setTarget(newObject)
    const newObjectP = { ...promotions }
    newObjectP.appear.target = newObject[index]
    // console.log(newObject[index])
    setPromotions(newObjectP)
  }
  const updateGameStatus = (key, index, isGameAll = false) => {
    const newObject = {...promotions}
    if(!isGameAll) {
      newObject[key].games[index].active = !newObject[key].games[index].active
      newObject[key].gameAll = isGameAll
    }
    else {
      newObject[key].games = newObject[key].games.map(g => {
        g.active = false
        return g
      })
      newObject[key].gameAll = isGameAll
    }
    setPromotions(newObject)

  }
  const updateOnchange = (key, key1, e) => {
    const newObject = {...promotions}
    newObject[key][key1] = e.target.value
    setPromotions(newObject)
  }
  const updateOnchange1 = (key, key1, key2, e) => {
    const newObject = {...promotions}
    newObject[key][key1][key2] = e.target.value
    setPromotions(newObject)
  }

  const setRatioValue = (id, keyO, key = 'status') => {
    const newObject = {...promotions}
    const status = document.getElementById(id)
    // console.log(status, status.value, Boolean(status.value))
    newObject[keyO][key] = status.value == 'true'
    setPromotions(newObject)
  }
  const add = async () => {
    const newObject = {...promotions}
    if(promotions.appear.title == '') setErrName('Title is required !')
    else {
      setErrName('')
      const elements = document.querySelector('input[name="radio3"]:checked')

      newObject.appear.target = {...target[elements.value]}
      setPromotions(newObject)
      try {
        await http.put({path: `admin/promotion-new/${id}`, payload: {data: promotions}})
        showDialogWithMessage(t('YOU_REQUEST_SUBMITED_SUCCESS'))
      } catch (error) {
        showDialogErrorWithMessage(error.message)
      }
    }

  }
  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <span>{t('UPDATE_PROMOTION')}</span>
              <Button style={{float: 'right'}} onClick={add} color="success">{t('SAVE')}</Button>
            </CardHeader>
            <CardFooter>
              <label htmlFor=''>Appear Seting</label>
            </CardFooter>
            <Form className="form">
              <CardBody>
                <FormGroup row>
                  <Label style={{textAlign: 'right'}} for="username" sm={4} size="lg">Package title</Label>
                  <Col sm={7}>
                    <Input value={promotions.appear.title} onChange={e => updateOnchange('appear', 'title', e)} type="text" name="useLimit" id="useLimit" placeholder="Package title" bsSize="md" />
                    <span style={{color: 'red'}}>{errName}</span>
                  </Col>
                </FormGroup>
                  <FormGroup row>
                        <Label style={{textAlign: 'right'}} for="password" sm={4} size="lg">Cycle</Label>
                        <Col sm={7}>
                          <Input type="select" value={promotions.appear.cycle} onChange={e => updateOnchange('appear', 'cycle', e)} name="select" id="exampleSelect">
                            <option value=""></option>
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Forever">Forever</option>
                          </Input>
                        </Col>
                  </FormGroup>
                  <FormGroup row>
                        <Label style={{textAlign: 'right'}} for="username" sm={4} size="lg">Use Limit</Label>
                        <Col sm={7}>
                          <Input min={1} value={promotions.appear.useLimit} onChange={e => updateOnchange('appear', 'useLimit', e)} type="number" name="useLimit" id="useLimit" placeholder="Use Limit" bsSize="md" />
                        </Col>
                  </FormGroup>
                {period && <FormGroup row>
                        <Label style={{textAlign: 'right'}} for="username" sm={4} size="lg">Promotion period</Label>

                            <Col sm={3}>
                              <TextField
                                style={{width: '100%'}}
                                id="time"
                                type="time"
                                defaultValue={period.from}
                                onChange={e => updateOnchange1('appear', 'promotionPeriod', 'from', e)}
                                className={classes.textField}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  step: 300, // 5 min
                                }}
                              />
                            </Col>~
                          <Col sm={3}>
                            <TextField
                              style={{width: '100%'}}
                              id="time"
                              type="time"
                              defaultValue={period.to}
                              onChange={e => updateOnchange1('appear', 'promotionPeriod', 'to', e)}
                              className={classes.textField}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300, // 5 min
                              }}
                            />
                          </Col>
                  </FormGroup>}
                  <FormGroup row>
                    <Col sm={4}></Col>
                    <Col sm={7}>

                    <Label check>
                      <Input type="checkbox" /> All Day
                    </Label>
                    </Col>
                  </FormGroup>
                <FormGroup row>
                  <Label style={{textAlign: 'right'}} for="username" sm={4} size="lg">Confirm By Admin</Label>
                  <Col sm={7} style={{paddingTop: 12}}>

                    <Label check>
                      <Input checked={promotions.appear.confirmBuyAdmin} onClick={() => handleClick('confirmBuyAdmin')} name={'confirmBuyAdmin'} type="checkbox" /> Yes
                    </Label>
                  </Col>
                </FormGroup>
                  <FormGroup row>
                    <Label style={{textAlign: 'right'}} for="username" sm={4} size="lg">Status</Label>
                    <Col sm={7}>
                      <FormGroup check>
                      <Label check>
                        <Input id={'appearStatus'} onClick={() => setRatioValue('appearStatus', 'appear')} value={true} checked={promotions.appear.status == 'true' ||  promotions.appear.status == true} type="radio" name="radio" /> Active
                      </Label>
                        <Label check>
                          <Input id={'appearStatusf'} onClick={() => setRatioValue('appearStatusf', 'appear')} value={false} checked={promotions.appear.status == 'false' ||  promotions.appear.status == false} type="radio" name="radio" /> Block
                        </Label>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label style={{textAlign: 'right'}} for="username" sm={4} size="lg">Target Switch</Label>
                    <Col sm={7}>
                      <FormGroup check>
                      <Label check>
                        <Input id={'appearTargetSwitch'} onClick={() => setRatioValue('appearTargetSwitch', 'appear', 'targetSwitch')} checked={promotions.appear.targetSwitch == true} value={true} type="radio" name="radio2" /> Yes
                      </Label>
                        <Label check>
                          <Input id={'appearTargetSwitchf'} onClick={() => setRatioValue('appearTargetSwitchf', 'appear', 'targetSwitch')} checked={promotions.appear.targetSwitch == false} value={false} type="radio" name="radio2" /> No
                        </Label>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label style={{textAlign: 'right'}} for="username" sm={4} size="lg">Target</Label>

                      <Col sm={8}>
                        <FormGroup check inline>
                        <Label check sm={3} style={{width: 190}}>
                          <Input value={0} checked={promotions.appear.target.type == 'Deposit amount'} type="radio" name="radio3" /> Deposit amount
                        </Label>
                          <Input value={target[0].min} min={0} onChange={e => updateTarget(0,'min', e)} type="number" name="useLimit" id="useLimit" bsSize="md" />
                          ~
                          <Input value={target[0].max} min={0} onChange={e => updateTarget(0, 'max', e)} type="number" name="useLimit" id="useLimit" bsSize="md" />
                          In
                          <Input value={target[0].days} min={0} onChange={e => updateTarget(0, 'days', e)} type="number" name="useLimit" id="useLimit" bsSize="md" />
                          days
                        </FormGroup>
                      </Col>

                  </FormGroup>
                  <FormGroup row>
                    <Label style={{textAlign: 'right'}} for="username" sm={4} size="lg"></Label>

                    <Col sm={8}>
                      <FormGroup check inline>
                        <Label check sm={3} style={{width: 190}}>
                          <Input value={1} checked={promotions.appear.target.type == 'Deposit times'} type="radio" name="radio3" /> Deposit times
                        </Label>
                        <Input value={target[1].min} min={0} onChange={e => updateTarget(1, 'min', e)} type="number" name="useLimit" id="useLimit" bsSize="md" />
                        ~
                        <Input value={target[1].max} min={0} onChange={e => updateTarget(1, 'max', e)} type="number" name="useLimit" id="useLimit" bsSize="md" />
                        In
                        <Input value={target[1].days} min={0} onChange={e => updateTarget(1, 'days', e)} type="number" name="useLimit" id="useLimit" bsSize="md" />
                        days
                      </FormGroup>
                    </Col>

                  </FormGroup>
                  <FormGroup row>
                    <Label style={{textAlign: 'right'}} for="username" sm={4} size="lg"></Label>

                    <Col sm={8}>
                      <FormGroup check inline>
                        <Label check sm={3} style={{width: 190}}>
                          <Input value={2} checked={promotions.appear.target.type == 'Win amount'} type="radio" name="radio3" /> Win amount
                        </Label>
                        <Input value={target[2].min} min={0} onChange={e => updateTarget(2, 'min', e)} type="number" name="useLimit" id="useLimit" bsSize="md" />
                        ~
                        <Input value={target[2].max} min={0} onChange={e => updateTarget(2, 'max', e)} type="number" name="useLimit" id="useLimit" bsSize="md" />
                        In
                        <Input value={target[2].days} min={0} onChange={e => updateTarget(2, 'days', e)} type="number" name="useLimit" id="useLimit" bsSize="md" />
                        days
                      </FormGroup>
                    </Col>

                  </FormGroup>
                  <FormGroup row>
                    <Label style={{textAlign: 'right'}} for="username" sm={4} size="lg"></Label>

                    <Col sm={8}>
                      <FormGroup check inline>
                        <Label check sm={3} style={{width: 190}}>
                          <Input value={3} checked={promotions.appear.target.type == 'Lost amount'} type="radio" name="radio3" /> Lost amount
                        </Label>
                        <Input value={target[3].min} min={0} onChange={e => updateTarget(3, 'min', e)} type="number" name="useLimit" id="useLimit" bsSize="md" />
                        ~
                        <Input value={target[3].max} min={0} onChange={e => updateTarget(3, 'max', e)} type="number" name="useLimit" id="useLimit" bsSize="md" />
                        In
                        <Input value={target[3].days} min={0} onChange={e => updateTarget(3, 'days', e)} type="number" name="useLimit" id="useLimit" bsSize="md" />
                        days
                      </FormGroup>
                    </Col>

                  </FormGroup>
                  <FormGroup row>
                    <Label style={{textAlign: 'right'}} for="username" sm={4} size="lg"></Label>

                    <Col sm={8}>
                      <FormGroup check inline>
                        <Label check sm={3} style={{minWidth: 190}}>
                          <Input value={4} checked={promotions.appear.target.type == 'Turnover'} type="radio" name="radio3" /> Turnover
                        </Label>
                        <Input value={target[4].min} min={0} onChange={e => updateTarget(4, 'min', e)} type="number" name="useLimit" id="useLimit" bsSize="md" />
                        ~
                        <Input value={target[4].max} min={0} onChange={e => updateTarget(4, 'max', e)} type="number" name="useLimit" id="useLimit" bsSize="md" />
                        In
                        <Input value={target[4].days} min={0} onChange={e => updateTarget(4, 'days', e)} type="number" name="useLimit" id="useLimit" bsSize="md" />
                        days
                      </FormGroup>
                    </Col>

                  </FormGroup>
              </CardBody>
            <CardFooter>
              <label htmlFor=''>Withdraw Setting</label>
            </CardFooter>
            <CardBody>

                <FormGroup row>
                  <Label style={{textAlign: 'right'}} for="username" sm={4} size="lg">Mode</Label>
                  <Col sm={7}>
                    <FormGroup check>
                      <Label check>
                        <Input value={'turnover'} onChange={e => updateOnchange('withdraw', 'mode', e)} checked={promotions.withdraw.mode == 'turnover'} type="radio" name="radio4" /> Turnover
                      </Label>
                      <Label check>
                        <Input value={'winlose'} onChange={e => updateOnchange('withdraw', 'mode', e)} checked={promotions.withdraw.mode == 'winlose'} type="radio" name="radio4" /> Winlose
                      </Label>
                    </FormGroup>
                  </Col>
                </FormGroup>

                  <FormGroup row>
                    <Label style={{textAlign: 'right'}} for="password" sm={4} size="lg">Rollover</Label>
                    <Col sm={7}>
                      <Input type="select" value={promotions.withdraw.rollover} onChange={e => updateOnchange('withdraw', 'rollover', e)} name="select" id="exampleSelect">
                        {
                          options.map((i) => <option key={i} value={i}>X{i}</option>)
                        }

                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label style={{textAlign: 'right'}} for="password" sm={4} size="lg">Games</Label>
                    <Col sm={7}>
                      <FormGroup check>
                        <Label check>
                          <Input checked={promotions.withdraw.gameAll} type="checkbox" onChange={() => updateGameStatus('withdraw', 0, true)} /> All Game
                        </Label>
                        {
                          promotions.withdraw.games.map((i, index) => (<Label key={i.label} check>
                            <Input checked={promotions.withdraw.games[index].active} type="checkbox" onChange={() => updateGameStatus('withdraw', index)}/> {i.label}
                          </Label>))
                        }
                      </FormGroup>
                    </Col>
                  </FormGroup>
            </CardBody>
              <CardFooter>
                <label htmlFor=''>Bonus</label>
              </CardFooter>
              <CardBody>

                <FormGroup row>
                  <Label style={{textAlign: 'right'}} for="username" sm={4} size="lg">Switch</Label>
                  <Col sm={7}>
                    <FormGroup check>
                      <Label check>
                        <Input id={'bonusSwitch'} onClick={() => setRatioValue('bonusSwitch', 'bonus','switch')} checked={promotions.bonus.switch == true} value={true}  type="radio" name="radio5" /> Yes
                      </Label>
                      <Label check>
                        <Input id={'bonusSwitchf'} onClick={() => setRatioValue('bonusSwitchf', 'bonus', 'switch')} checked={promotions.bonus.switch == false} value={false} type="radio" name="radio5" /> No
                      </Label>
                    </FormGroup>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label style={{textAlign: 'right'}} for="password" sm={4} size="lg">Rollover</Label>
                  <Col sm={7}>
                    <Input type="select" value={promotions.bonus.rollover} onChange={e => updateOnchange('bonus', 'rollover', e)} name="select" id="exampleSelect">
                      {
                        options.map((i) => <option key={i} value={i}>X{i}</option>)
                      }

                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label style={{textAlign: 'right'}} for="username" sm={4} size="lg">Percentage</Label>
                  <Col sm={6}>
                    <Input min={0} value={promotions.bonus.percent} onChange={e => updateOnchange('bonus', 'percent', e)} type="text" name="useLimit" id="useLimit" placeholder="Use Limit" bsSize="md" />
                  </Col>%
                </FormGroup>
                <FormGroup row>
                  <Label style={{textAlign: 'right'}} for="password" sm={4} size="lg">Games</Label>
                  <Col sm={7}>
                    <FormGroup check>
                      <Label check>
                        <Input checked={promotions.bonus.gameAll} type="checkbox" onChange={() => updateGameStatus('bonus', 0, true)} /> All Game
                      </Label>
                      {
                        promotions.bonus.games.map((i, index) => (<Label key={i.label+index} check>
                          <Input checked={promotions.bonus.games[index].active} type="checkbox" onChange={() => updateGameStatus('bonus', index)} /> {i.label}
                        </Label>))
                      }
                    </FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label style={{textAlign: 'right'}} for="username" sm={4} size="lg">Max Bonus</Label>
                  <Col sm={6}>
                    <Input min={0} value={promotions.bonus.maxBonus} onChange={e =>  updateOnchange('bonus', 'maxBonus', e)} type="number" name="useLimit" id="useLimit" placeholder="Max Bonus" bsSize="md" />
                  </Col>%
                </FormGroup>
              </CardBody>
              <CardFooter>
                <label htmlFor=''>Commission</label>
              </CardFooter>
              <CardBody>

                <FormGroup row>
                  <Label style={{textAlign: 'right'}} for="username" sm={4} size="lg">Switch</Label>
                  <Col sm={7}>
                    <FormGroup check>
                      <Label check>
                        <Input id={'commissionSwitch'} onClick={() => setRatioValue('commissionSwitch', 'commission','switch')} checked={promotions.commission.switch == true} value={true} type="radio" name="radio6" /> Yes
                      </Label>
                      <Label check>
                        <Input id={'commissionSwitchf'} onClick={() => setRatioValue('commissionSwitchf', 'commission','switch')} checked={promotions.commission.switch == false} value={false} type="radio" name="radio6" /> No
                      </Label>
                    </FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label style={{textAlign: 'right'}} for="username" sm={4} size="lg">No comm</Label>
                  <Col sm={7}>
                    <FormGroup check>
                      <Label check>
                        <Input id={'commissionNoComm'} onClick={() => setRatioValue('commissionNoComm', 'commission','noComm')} checked={promotions.commission.noComm == true} value={true} type="radio" name="radio1" /> Yes
                      </Label>
                      <Label check>
                        <Input id={'commissionNoCommf'} onClick={() => setRatioValue('commissionNoCommf', 'commission','noComm')} checked={promotions.commission.noComm == false} value={false} type="radio" name="radio1" /> No
                      </Label>
                    </FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label style={{textAlign: 'right'}} for="username" sm={4} size="lg">Percentage</Label>
                  <Col sm={6}>
                    <Input min={0} value={promotions.commission.percent} onChange={e => updateOnchange('commission', 'percent', e)} type="number" name="useLimit" id="useLimit" placeholder="Use Limit" bsSize="md" />
                  </Col>%
                </FormGroup>
                <FormGroup row>
                  <Label style={{textAlign: 'right'}} for="password" sm={4} size="lg">Games</Label>
                  <Col sm={7}>
                    <FormGroup check>
                      <Label check>
                        <Input checked={promotions.commission.gameAll} type="checkbox" onChange={() => updateGameStatus('commission', 0, true)} /> All Game
                      </Label>
                      {
                        promotions.commission.games.map((i, index) => (<Label key={i.label+index} check>
                          <Input checked={promotions.commission.games[index].active} type="checkbox" onChange={() => updateGameStatus('commission', index)} /> {i.label}
                        </Label>))
                      }
                    </FormGroup>
                  </Col>
                </FormGroup>

              </CardBody>
            </Form>
          </Card>
        </Col>
      </Row>
      <Dialog1 />
      <DialogError/>
    </div>
  )
}

export default Banking;
