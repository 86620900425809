import React, { useState, useEffect } from 'react';
import { Fab, Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import { Card, CardBody, CardHeader, Col, Row, Table, InputGroup, FormGroup, Label, Input } from 'reactstrap'
import http from 'service/http';
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'
import { TextInput, useDialog } from 'feature/Component'
import store from 'store'
import { useTranslation } from 'react-i18next'

function BankRow({ data: {name, bank_account_name: bankAccountName, bank_account_no: bankAccountNo, id, type }, handleClick, deleteBank }) {
  return (
    <tr key={id}>
      <td>{id}</td>
      <td>{name}</td>
      <td>{bankAccountName}</td>
      <td>{bankAccountNo}</td>
      <td>{type}</td>
      <td>
        <Fab style={{height: 40, width: 40}} color='primary' aria-label="Edit" onClick={() => handleClick(id)}>
          <Edit/>
        </Fab>
        <Fab style={{height: 40, width: 40}} color='error' aria-label="Delete" onClick={() => deleteBank(id)}>
           <Delete/>

        </Fab>
      </td>
    </tr>
  )
}

function DialogForm ({ open = false, initialvalues, handleUpdate, handleClose}) {

  const schema = Joi.object().keys({
    name: Joi.string().required(),
    bankAccountName: Joi.string().required(),
    bankAccountNo: Joi.string().required()
  })

  const onSubmit = (payload) => handleUpdate({ ...payload, id: initialvalues.id })

  const { form, handleSubmit } = useForm({
    onSubmit,
    validate: validate(schema),
    initialValues: {
      name: initialvalues.name,
      bankAccountName: initialvalues.bank_account_name,
      bankAccountNo: initialvalues.bank_account_no
    }
  })

  const [name, bankAccountName, bankAccountNo] = formCreateInputs(['name', 'bankAccountName', 'bankAccountNo'], form)
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <form style={{width: 500}} onSubmit={handleSubmit} noValidate>
        <DialogTitle id="form-dialog-title">Banking config</DialogTitle>
        <DialogContent>
          <InputGroup className="mb-6">
            <TextInput input={name} label='Bank name' fullWidth/>
          </InputGroup>
          <InputGroup className="mb-6">
            <TextInput input={bankAccountName} label='Bank Account Name' fullWidth/>
          </InputGroup>
          <InputGroup className="mb-6">
            <TextInput input={bankAccountNo} label='Bank Account No' fullWidth/>
          </InputGroup>

        </DialogContent>
        <DialogActions>
          <Button type="submit" color="info">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

function AddingDialogForm ({ open = false, handleAdd, handleClose, t}) {

  const [type, setType] = useState('Deposit')
  const { user_level: userLevel } = store.get('user')

  const onSubmit = (payload) => handleAdd({...payload, type})

  const { form, handleSubmit } = useForm({
    onSubmit,
    validate: validate(Joi.object().keys({
      name: Joi.string().required(),
      bankAccountName: type === 'Deposit' ? Joi.string().required(): Joi.string(),
      bankAccountNo: type === 'Deposit' ? Joi.string().required(): Joi.string(),
      // agent: Joi.string()
    }))
  })

  const [name, bankAccountName, bankAccountNo] = formCreateInputs(['name', 'bankAccountName', 'bankAccountNo'], form)
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <form style={{width: 500}} onSubmit={handleSubmit} noValidate>
        <DialogTitle id="form-dialog-title">{t('BANK_CONFIG')}</DialogTitle>
        <DialogContent>
          <InputGroup className="mb-6">
            <TextInput input={name} label='Bank name' fullWidth/>
          </InputGroup>
          <InputGroup className="mb-6">
            <TextInput input={bankAccountName} label='Bank Account Name' fullWidth/>
          </InputGroup>
          <InputGroup className="mb-6">
            <TextInput input={bankAccountNo} label='Bank Account No' fullWidth/>
          </InputGroup>
          {/*{userLevel === 'ADMIN' && <InputGroup className="mb-6">*/}
            {/*<TextInput input={agent} label='Agent' fullWidth/>*/}
          {/*</InputGroup>}*/}
          <FormGroup>
            <legend className="col-form-label">{t('TYPE')}</legend>
            <FormGroup check inline>
              <Label check >
                <Input type="radio" name="radio2" onChange={(e) => { setType('Deposit' )}} checked={type == 'Deposit'}/>{' '}
                {t('DEPOSIT')}
              </Label>
            </FormGroup>

            <FormGroup check inline>
              <Label check>
                <Input type="radio" onChange={(e) => { setType('Withdraw') }} name="radio2"checked={type == 'Withdraw'} />{' '}
                {t('WITHDRAW')}
              </Label>
            </FormGroup>

          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="info">{t('SUBMIT')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

function Banking ({history}) {
  const { t } = useTranslation()
  const [bankings, updateBankings] = useState([])
  const [formConfig, updateFormConfig] = useState({})
  const [formAdding, updateFormAdding] = useState({})
  const [DialogError, showDialogErrorWithMessage] = useDialog({
    title: 'Error',
    type: 'error'
  })

  const initialBanking = async () => {
    const bankingsRsp = await http.get({path: 'banks'})
    updateBankings(bankingsRsp)
  }

  const handleRowClick = id => {
    const initialvalues = bankings.find(item => item.id === id)
    updateFormConfig({
      open: true,
      initialvalues,
      handleUpdate,
      handleClose: () => updateFormConfig({open: false})
    })
  }

  const handleUpdate = async ({ name, bankAccountName, bankAccountNo, id }) => {
    try {
      await http.put({path: `admin/config/bank/${id}`, payload: {
        name,
        bank_account_name: bankAccountName,
        bank_account_no: bankAccountNo
      }})
      updateFormConfig({ open: false })
      initialBanking()
    } catch (error) {
      updateFormConfig({ open: false })
      showDialogErrorWithMessage(error.message)
    }
  }

  const deleteBank = async (id) => {
    try {
      await http.delete({path: `admin/config/bank/${id}`})
      initialBanking()
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }

  const handleAdd = async ({ name, bankAccountName, bankAccountNo, agent, type }) => {
    try {
      const { email, user_level: userLevel, parent_id: parentId } = store.get('user');
      await http.post({path: `admin/config/banks`, payload: {
        name,
        bank_account_name: bankAccountName,
        bank_account_no: bankAccountNo,
        type,
        // agent: agent || (userLevel === 'COMPANY' ? email : parentId)
      }})
      updateFormAdding({ open: false })
      initialBanking()
    } catch (error) {
      updateFormAdding({ open: false })
      showDialogErrorWithMessage(error.message)
    }
  }

  const showAddingBankingDialog = () => updateFormAdding({
    open: true,
    handleAdd,
    handleClose: () => updateFormAdding({open: false})
  })

  useEffect(() => {
    initialBanking()
  }, [])

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <span>{t('BANKING')}</span>
              <Button style={{float: 'right'}} variant="contained" onClick={showAddingBankingDialog} color="info" className="px-4" >{t('ADD')}</Button>
            </CardHeader>
            <CardBody>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th scope="col">{t('ID')}</th>
                    <th scope="col">{t('BANK_NAME')}</th>
                    <th scope="col">{t('BANK_ACCOUNT_NAME')}</th>
                    <th scope="col">{t('BANK_ACCOUNT_NO')}</th>
                    <th scope="col">{t('TYPE')}</th>
                    <th scope="col">{t('ACTION')}</th>
                  </tr>
                </thead>
                <tbody>
                  {bankings.map((item, index) =>
                    <BankRow t key={index} history={history} data={item} handleClick={handleRowClick} deleteBank={deleteBank} />
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DialogError/>
      {formConfig.open && <DialogForm {...formConfig} />}
      {formAdding.open && <AddingDialogForm {...formAdding} t={t} />}
    </div>
  )
}

export default Banking;
