import React, { useEffect, useState } from 'react'
import { Button, Table } from 'reactstrap'
import http from 'service/http'
import { useDialog, TextInput, useLoading } from 'feature/Component'
import LoadingUi from 'feature/Component/Loading/ui'
import validate from 'service/form/validation'
import { isEmpty } from 'ramda'
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-final-form-hooks'
import formCreateInputs from 'service/form/create'
import Joi from '@hapi/joi'

function SlotGame(props) {
  const {user: defaultUser, gameType, parentpt, commissions,  user_level, gamess} = props

  const { t } = useTranslation()
  const [loading, withLoading] = useLoading()
  const [gamesList, setGameList] = useState([])
  const [user, setUser] = useState(defaultUser)
  const [activeGames, setActivegames] = useState([])
  const [userpt, setPT] = useState({
    pt: [],
    comm: []
  })
  const [parentPT, setParentPT] = useState([])
  const [parentComm, setParentComm] = useState(JSON.parse(commissions))
  const [ptModal, updatePTModal] = useState({})

  const [DialogError, showDialogErrorWithMessage] = useDialog({
    title: 'Error',
    type: 'error'
  })

  const run = (newPT, newComm, games) => {
    // console.log(games)
    setActivegames(games.map(g => {
      let currentPT = newPT.find(x => x.game_code == g.game_code)
      let currentComm = newComm.find(x => x.game_code == g.game_code)
      // console.log(parentComm)
      let parentGameComm = parentComm.find(x => x.game_code == g.game_code)
      if(g.game_code === 'sexy_baccarat') {
        currentPT = newPT.find(x => x.label == g.label)
        currentComm = newComm.find(x => x.label == g.label)
        parentGameComm = parentComm.find(x => x.label == g.label)
      }
      // console.log(parentGameComm, g)
      g.pt = currentPT ? currentPT.pt : 0
      g.comm = currentComm ? currentComm.comm : 0
      g.parentComm = user_level == 'ADMIN' ? 100 : (parentGameComm ? parentGameComm.comm : 0)
      g.label = g.label ? g.label : g.name
      g.status = currentComm && currentComm.status ? currentComm.status : 'Active'
      return g
    }))
  }
  const initPT = async () => {
    const userPT = JSON.parse(user.position_taking)
    const userComm = JSON.parse(user.commissions).filter(x => typeof x.label != 'undefined')
    // console.log(userComm)
    setPT({pt: userPT, comm: userComm})
    return {userPT, userComm}
  }
  const getGames = async () => {
    const {userPT, userComm} = await initPT()
    run(userPT, userComm, gamess.filter(x => x.isActive == 1 && x.gameType == gameType))
    setGameList(gamess.filter(x => x.isActive == 1 && x.gameType == gameType))
    // console.log(userpt, userPT)

    if(user.parent_id != 'admin') {
      setParentPT(JSON.parse(parentpt))
    }
    else setParentPT(userpt.pt.map(p => {
      p.pt = 100
      return p
    }))
  }
  useEffect(() => {
    // console.log(userPT)
    // console.log(userpt)
    getGames()
  }, [])
  const openConfigPT = (data) => {
    updatePTModal(data)
  }
  const updatePTStage = (data) => {
    let exist = false
    const newPT = userpt.pt.map(p => {
      // console.log(p)
      if(p.label == data.label) {
        p = data
        exist = true
      }
      return {
        game_code: p.game_code,
        label: p.label,
        pt: p.pt,
        status: p.status
      }
    })
    if(!exist) newPT.push({
      game_code: data.game_code,
      label: data.label,
      pt: data.pt,
      status: data.status
    })
    setPT({...userpt, pt: newPT})
    run(newPT, userpt.comm, gamesList)
    return newPT
  }
  const updateCommStage = (data) => {
    let exist = false
    const newPT = userpt.comm.map(p => {
      // console.log(p)
      if(p.label == data.label) {
        p = data
        exist = true
      }
      return {
        game_code: p.game_code,
        label: p.label,
        comm: p.comm,
        status: p.status,
      }
    })
    // console.log(typeof newPT[1].label, data)
    if(!exist) newPT.push({
      game_code: data.game_code,
      label: data.label,
      comm: data.comm,
      status: data.status,
    })
    setPT({...userpt, comm: newPT})
    run(userpt.pt, newPT, gamesList)
    return newPT
  }
  const updatePT = async (data) => {
    // console.log(userpt)

    const newComm = updateCommStage(data)
    const payload = {
      comm: newComm,
        percen: userpt.pt,
        before: data.label + ' - pt: ' + data.pt,
        after: data.label + ' - pt: ' + data.pt
    }
    if(user.user_level != 'PLAYER') {
      const newPT = updatePTStage(data)
      payload.percen = newPT
    }
    try {
      await withLoading(() => http.put({
        path: `admin/config/${user.user_id}/position-taking`, payload
      }).then(async () => {
        const newUser = await http.get({ path: `admin/users/${user.user_id}` })
        setUser(newUser)
        const {userPT, userComm} = await initPT()
        run(userPT, userComm, gamesList)
      }))
    } catch ({ message }) {
      // console.log(message)
      showDialogErrorWithMessage(message)
    }
    // console.log(newPT)
  }
  const submitPT = async () => {
    // console.log(userpt)
    try {
      await withLoading(() => http.put({
        path: `admin/config/${user.user_id}/position-taking`, payload: {
          percen: userpt.pt,
          comm: userpt.comm,
          before: 'pt',
          after: 'pt'
        }
      }).then(async () => {
        await initPT()
      }))
    } catch ({ message }) {
      // console.log(message)
      showDialogErrorWithMessage(message)
    }
    // console.log(newPT)
  }
  const schema = Joi.object().keys({
    pt: Joi.number().min(0).max(100).required()
  })
  const onSubmit = async (data) => {
    const parentpt = parentPT.find(x => x.label == ptModal.label)

    if (user.parent_id != 'admin'&& (!parentpt || data.pt*1 > parentpt.pt * 1)) {
      // console.log(t('PT_CANNOT_GREATE_THAN'))
      return updatePTModal({
        ...ptModal,
        error: t('PT_CANNOT_GREATE_THAN')
      })
    } else {
      updatePTStage({ ...ptModal, ...data })
      updatePTModal({})
    }

  }
  const { form, handleSubmit } = useForm({
    validate: validate(schema),
    onSubmit,
    initialValues: {
      pt: ptModal.pt
    }
  })
  const [pt] = formCreateInputs(['pt'], form)

  const updateActiveGameComm = (e, game) => {
    // console.log(e.target.value, game.parentComm)
    if(game.parentComm < Number(e.target.value)) showDialogErrorWithMessage('Cant set comm great than ' + game.parentComm)
    else updateCommStage({ ...game, comm: e.target.value })
  }
  return (
    <>{loading && <LoadingUi/>}
      <Button style={{float: 'right'}} onClick={() => submitPT()} color="success">Submit</Button>
      <Table striped bordered hover>

        <thead>
        <tr>
          <th>Game</th>
          {user.user_level != 'PLAYER' && <th>PT</th>}
          <th>Comm</th>
          <th>Status</th>
          <th>Control</th>
        </tr>
        </thead>
        <tbody>
        {activeGames.map(game => (<tr key={game.label}>
          <td><b>{game.label}</b></td>
          {user.user_level != 'PLAYER' && <td>{user.pt} %</td>}
          <td><input style={{width: 50}} type="number" max={game.parentComm} min={0} onChange={(e) => updateActiveGameComm(e, game)} value={game.comm} />%</td>
          <td style={{color: game.status === 'Suspend' ? 'red' : 'green'}}>{game.status}</td>
          <td>
            <Button onClick={() => updatePT({...game, status: 'Active'})} color="success">Active</Button>
            <Button onClick={() => updatePT({...game, status: 'Suspend'})} color="danger">Suspend</Button>
          </td>
        </tr>))}

        </tbody>

      </Table>
      <Dialog open={!isEmpty(ptModal)} onClose={() => updatePTModal({})} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('UPDATE_PERCEN')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b style={{color: 'green'}}>{ptModal.label}</b>
          </DialogContentText>
          <form id="withdraw-form" onSubmit={handleSubmit} noValidate>
            <TextInput style={{ marginRight: 15 }} input={pt} type="number" max="100" label='Percentage' />
            <Button type="submit" variant="contained">{t('UPDATE')}</Button>
          </form>
          {ptModal.error
            ? <p style={{ color: 'red' }}>{ptModal.error}</p>
            : null}
        </DialogContent>
      </Dialog>
      <DialogError/>
    </>
  )
}

export default SlotGame
