import React, { useEffect } from 'react'
import { Button, Card, CardBody, CardGroup, Col, Container, InputGroup, Row } from 'reactstrap'
import { useForm } from 'react-final-form-hooks'
import Joi from '@hapi/joi'
import http from 'service/http'
import validate from 'service/form/validation'
import formCreateInputs from 'service/form/create'
import { TextInput, useDialog } from 'feature/Component'
import { useTranslation } from 'react-i18next'

function EditUser(props) {
  const { t } = useTranslation()
  const {share} = props
// console.log(props)

  useEffect(() => {
  }, [])
  return <FormAsync t={t} {...props} share={share}/>
}

function FormAsync({ share, t }) {
  const [Dialog, showDialogWithMessage] = useDialog({
    title: t('UPDATE_USER_SUCCESS')
  })
  const onSubmit = async payload => {
    console.log(payload)
    try {
      await http.put({ path: `admin/config`, payload: {
        share: payload.configShare
      }})
      showDialogWithMessage(t('YOU_REQUEST_SUBMITED_SUCCESS'))
    } catch (error) {
      throw error
    }
  }

  const schema = Joi.object().keys({
    configShare: Joi.number().min(0).required(),
  })

  const { form, handleSubmit } = useForm({
    onSubmit,
    validate: validate(schema),
    configShare: {share}
  })

  const [configShare] = formCreateInputs(['configShare'], form)

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" md="6" lg="5">
            <form onSubmit={handleSubmit}>
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h1>{t('CONFIG_SHARE')}</h1>
                    <InputGroup className="mb-3">
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <TextInput input={configShare} type="text" label={t('ENTER_SHARE_AMOUNT')} fullWidth/>
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button type='submit' color="info" className="px-4">{t('UPDATE')}</Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </form>
          </Col>
        </Row>
      </Container>
      <Dialog/>
    </div>
  )
}

export default EditUser
