import io from 'socket.io-client'
import config from 'config'

const socket = io(`${config.socketUrl}admin`, {
  transports: ['websocket'],
  forceNew: true,
  reconnection: true,
  reconnectionDelay: 500,
  reconnectionAttempts: 10
})

export default socket