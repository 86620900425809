import React, { useEffect, useState } from 'react';
import Joi from '@hapi/joi'
import validate from 'service/form/validation'
import { Card, CardBody, CardHeader, Col, Row, Table, Button } from 'reactstrap';
import Pagination from "react-js-pagination";
import http from 'service/http';
import { useForm } from 'react-final-form-hooks'
import formCreateInputs from 'service/form/create'
import moment from 'moment'
import {isEmpty} from 'ramda'
import queryString from 'query-string'
import { FormControl, Dialog, DialogTitle, DialogContent, Fab } from '@material-ui/core'
import ReactJson from 'react-json-view'
import numeral from 'numeral'
import store from 'store';
import { TextInput, useLoading, SelectInput } from 'feature/Component';
import { useTranslation } from 'react-i18next'
import { Details } from '@material-ui/icons'
import LoadingUi from 'feature/Component/Loading/ui'

const correctNumber = amount =>  numeral(amount).format('0,0.000')

const DEFAULT_FROM_DATE = moment().startOf('week').format('YYYY-MM-DD')
const DEFAULT_TO_DATE = moment().format('YYYY-MM-DD')

const selectColor = value => {
  if (value > 0) {
    return '#14805E'
  }
  if (value < 0) {
    return 'red'
  }
  return 'inherit'
}
function HistoryRow({history, index, showDetails, getRabat}) {
  const { dateCal, created_at, amountRabat, agent, username, winlose, betAmount, before, after, amount, gameName, type, origin, target, status, bonus_amount } = history;
  let afterr = after
  if(type === 'Deposit' && 'fail,reject,canceled'.includes(status)) afterr = after - amount
  return (

    <tr key={index}>
      <th scope="row">{index + 1}</th>
      {type == 'Rabat' && <td style={{color: 'blue', cursor: 'pointer'}} onClick={() => getRabat({parent: agent})}><b>{agent}</b></td>}
      {type !== 'Rabat' && <td style={{color: 'green'}}><b>{origin}</b></td>}
      <td><b>{username}</b></td>
      <td style={{color: 'green', textTransform: 'uppercase'}}><b>{gameName}{target}</b></td>
      <td>{correctNumber(betAmount)}</td>
      <td style={{color: selectColor(winlose)}}>{correctNumber(winlose)}</td>
      <td>{correctNumber(amount)}</td>
      <td>{correctNumber(bonus_amount)}</td>
      <td>{correctNumber(before)}</td>
      <td>{correctNumber(afterr)}</td>
      <td>{dateCal ? dateCal : moment(created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
      <td>{type}</td>
      <td style={{color: 'fail,reject,canceled'.includes(status) ? 'red' : 'green'}}>{status}</td>
    </tr>
  )
}


function History (props) {
  const { t } = useTranslation()
  const {location} = props
  const itemsCountPerPage = 20
  const [activePage, updateActivePage] = useState(1)
  const [docs, updateDocs] = useState([])
  const [histories, updateRabat] = useState([])
  const [date, setDate] = useState({
    fromDate: DEFAULT_FROM_DATE,
    toDate: DEFAULT_TO_DATE,
    game: undefined
  })
  const [totals, updateTotals] = useState({
    totalBetAmount: 0,
    totalWinAmount: 0,
    totalBonus: 0,
    totalTurnover: 0
  })
  const [ loading, withLoading ] = useLoading()
  const [games, setGames] = React.useState([])
  const [initial, updateInitial] = React.useState({})
  const [shownJson, updateShownJson] = React.useState(false)
  const [agents, updateAgents] = useState([])
  const [bettingDetail, updateBettingDetail] = React.useState({})
  const { user_level: role } = store.get('user')

  const showDetails = payload => {
    updateBettingDetail(payload)
    updateShownJson(true)
  }

  const initialGames = async() => {
    const gamesResp = await http.get({path: 'games'}).then(rs => {
      return rs.filter(x => x.isActive == 1)
    })
    const initialValues = queryString.parse(location.search)
    updateInitial({
      ...initial,
      ...initialValues
    })
    const initialGames = [{ title: 'All', value: 'All' }, ...gamesResp.map(({ name, game_code, label, providerCode }) => {
      let title = label ? label : name
      let value = providerCode ? providerCode : game_code
      if(title == 'maxbet') title = 'M-Sport'
      return { title, value, providerCode, game_code }
    })]
    // const initialGames = [{ title: 'All', value: 'All' }, ...gamesResp
    //   .filter(({ game_code, isActive }) => isActive == 1)
    //   .map(({ name, game_code }) => ({ title: name, value: game_code }) )]
    setGames(initialGames)
    !isEmpty(initialValues) && onSubmit(initialValues)
  }
  const get = async (query) => {
    const data = await withLoading(() => http.get({path: `admin/report/transaction-history`, params: query}))
    updateRabat(data.rabats)
    updateTotals(data.totals)
  }
  const getRabat = async (dataa) => {
    try {
      const query = {
        fromDate: date.fromDate,
        toDate: date.toDate,
        parent: dataa.parent,
        game: dataa.game
      }

      get(query)
    } catch (error) {
      console.log(error)
    }
  }
  const onSubmit = async(params) => {
    try {
      let {
        fromDate, toDate, username, game, agent
      } = params;
      // console.log(txType.input.value)
      setDate({fromDate, toDate, game, txType})
      const query = {
        fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD 00:00:00') : undefined,
        toDate: toDate ? moment(toDate).format('YYYY-MM-DD 23:59:59') : undefined,
        username: (username && username !== '') ? username : undefined,
        game: (game && game !== ''&& game !== 'All') ? game : undefined,
        txType: (txType && txType.input.value !== '' && txType.input.value !== 'all') ? txType.input.value : undefined,
      }

      get(query)
    } catch (error) {
      console.log(error)
    }
  }

  const initialAgent = async () => {
    const correctAgents = ({ username }) => ({
      title: username, value: username
    })
    const agentsResp = await withLoading(() => http.get({path: 'admin/users', params: {
      user_level: 'AGENT'
    }}))
    const cleanedAgents = agentsResp.map(correctAgents)
    const [{ value: defaultAgent }] = cleanedAgents
    updateAgents(cleanedAgents)
    updateInitial({
      ...initial,
      agent: defaultAgent
    })
  }

  const loadDocs = () => {
    if(!histories.length) {
      updateDocs([])
      return
    }
    const from = (activePage - 1) * itemsCountPerPage
    const to = from + itemsCountPerPage
    const docs = histories.slice(from, to)
    updateDocs(docs)
  }

  useEffect(() => {
    initialGames()
    if (role === 'PARTNER') {
      initialAgent()
    }
  }, [])

  useEffect(() => {

    loadDocs()
  }, [histories, activePage])

  const schema = Joi.object().keys({
    username: Joi.string().allow('', null),
    fromDate: Joi.string(),
    toDate: Joi.string(),
    game: Joi.string(),
    txType: Joi.string(),
    agent: Joi.string(),
  })
  const query = new URLSearchParams(props.location.search);
  const { form, handleSubmit } = useForm({
    onSubmit,
    validate: validate(schema),
    initialValues: {
      fromDate: query.get('fromDate') ? query.get('fromDate') : DEFAULT_FROM_DATE,
      toDate: query.get('toDate') ? query.get('toDate'): DEFAULT_TO_DATE,
      username: query.get('username') ? query.get('username'): '',
      game: props.match.params.id ? props.match.params.id : (games.length ? games[0].value : undefined),
      ...initial
    }
  })

  const [username, fromDate, toDate, game, txType, agent] = formCreateInputs(['username', 'fromDate', 'toDate', 'game', 'txType', 'agent'], form)

  return (
    <div className="animated fadeIn">
      {loading && <LoadingUi/>}
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <form onSubmit={handleSubmit} novalidate>
                { (role === 'PARTNER') && <SelectInput style={{width: 200, marginRight: 15}} options={agents} input={agent} label={t('SELECT_AGENT')}/>}
                <SelectInput style={{marginRight: 15, width: 200, marginTop: 16}} input={game} options={games} label={t('SELECT_GAME')} />
                <SelectInput style={{marginRight: 15, width: 200, marginTop: 16}} input={txType} options={[
                  { title: 'All', value: 'all' },
                  { title: 'Deposit', value: 'deposit' },
                  { title: 'Withdraw', value: 'withdraw' },
                  { title: 'Transfer', value: 'transfer' },
                  { title: 'Bonus', value: 'bonus' },
                  { title: 'Comm', value: 'comm' }
                ]} label={t('SELECT_TYPE')} />
                <TextInput input={username} label={t('USERNAME')} />
                <TextInput style={{marginLeft: 15, width: 200}} input={fromDate} label={t('FROM')} type='date' InputLabelProps={{
                    shrink: true,
                }}/>
                <TextInput style={{marginLeft: 15, width: 200}} input={toDate} label={t('TO')} type='date' InputLabelProps={{
                    shrink: true,
                }}/>
                <FormControl style={{ marginTop: 30, marginLeft: 15}}>
                {loading ? t('LOADING') + ' ...' : <Button type='submit' color="info" className="px-4" >{t('SEARCH')}</Button> }
                </FormControl>
              </form>
            </CardHeader>
            { loading ? t('LOADING') + ' ...' :
              <CardBody>
                <React.Fragment>
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t('NO.')}</th>
                        <th scope="col">{t('PARENT')}</th>
                        <th scope="col">{t('USERNAME')}</th>
                        <th scope="col">{t('GAME')}</th>
                        <th scope="col">{t('BET')}</th>
                        <th scope="col">{t('WINLOSE')}</th>
                        <th scope="col">{t('AMOUNT')}</th>
                        <th scope="col">{t('BONUS_AMOUNT')}</th>
                        <th scope="col">{t('BEFORE')}</th>
                        <th scope="col">{t('AFTER')}</th>

                        <th scope="col">{t('CREATED')}</th>
                        <th scope="col">{t('TYPE')}</th>
                        <th scope="col">{t('STATUS')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {docs.map((history, index) =>
                        <HistoryRow key={index} history={history} index={index ++} showDetails={showDetails} getRabat={getRabat}/>
                      )}
                      <tr style={{fontWeight: 'bold', background: '#acb5bc'}}>
                        <td colSpan="10"></td>
                        <td colSpan="2">{t('TOTAL_BET')}</td>
                        <td style={{color: selectColor(totals.totalBetAmount)}}>{totals.totalBetAmount ? correctNumber(totals.totalBetAmount) : 0}</td>
                      </tr>
                      <tr style={{fontWeight: 'bold', background: '#acb5bc'}}>
                        <td colSpan="10"></td>
                        <td colSpan="2">{t('TOTAL_WINLOSE')}</td>
                        <td style={{color: selectColor(totals.totalWinLose)}}>{totals.totalWinLose ? correctNumber(totals.totalWinLose) : 0}</td>
                      </tr>
                      <tr style={{fontWeight: 'bold', background: '#acb5bc'}}>
                        <td colSpan="10"></td>
                        <td colSpan="2">{t('TOTAL_TURNOVER')}</td>
                        <td style={{color: selectColor(totals.totalTurnover)}}>{totals.totalTurnover ? correctNumber(totals.totalTurnover) : 0}</td>
                      </tr>
                      <tr style={{fontWeight: 'bold', background: '#acb5bc'}}>
                        <td colSpan="10"></td>
                        <td colSpan="2">{t('TOTAL_BONUS')}</td>
                        <td style={{color: selectColor(totals.totalBonus)}}>{totals.totalBonus ? correctNumber(totals.totalBonus) : 0}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <div>
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={histories.length}
                      pageRangeDisplayed={5}
                      onChange={updateActivePage}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </React.Fragment>
              </CardBody>
            }
          </Card>
        </Col>
      </Row>
      <Dialog
        open={shownJson}
        onClose={() => updateShownJson(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{t('BETTING_DETAIL')}</DialogTitle>
        <DialogContent>
          <ReactJson src={bettingDetail} displayDataTypes={false} />
        </DialogContent>
      </Dialog>
    </div>
  )
};

export default History;
