import React from 'react';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'
import { Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap';
import http from 'service/http'
import { useDialog, useLoading } from 'feature/Component'

function ACL(props) {
  const [loading, withLoading] = useLoading()
  const [acls, updatePermissions] = React.useState([])
  const [DialogError, showDialogErrorWithMessage] = useDialog({
    title: 'ACL Setting Error',
    type: 'error'
  })

  const handleChange = (namePer) => async event => {
    try {
      const selected = event.target.checked;
      const { match: {
        params: { id }
      }} = props
      const { user } = await http.get({ path: `admin/users/${id}` })
      let pers = user.permissions ? JSON.parse(user.permissions) : []
      if (selected) {
        pers.push(namePer)
      } else {
        pers = pers.filter(item => item !== namePer)
      }
      
      await http.put({ path: `admin/agent/cs/${id}`, payload: {
        permissions: pers
      }})
      initialGames()
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }

  const initialGames = async () => {
    try {
      const { match: {
        params: { id }
      }} = props
      const { user: { permissions }} = await http.get({ path: `admin/users/${id}` })
      const pers = permissions || []
      const aclResp = await withLoading(() => http.get({ path: `config/permissions` }))
      const result = aclResp.map(item => {
        if(pers.includes(item)) {
          return {
            name: item,
            actived: true
          }
        }
        return {
          name: item,
          actived: false
        }
      })
      updatePermissions(result)
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }

  React.useEffect(() => {
    initialGames()
  }, [])

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <CardGroup>
            <Card className="p-6">
              <CardBody>
                <h1>ACL Setting</h1>
                <FormGroup>
                {loading ? 'Loading' : acls.map(({actived, name}) => <FormControlLabel
                  key={name}
                  control={
                    <Checkbox
                      checked={actived}
                      onChange={handleChange(name)}
                      value="checkedB"
                      color="primary"
                    />
                  }
                  label={name}
                />)}
                </FormGroup>
              </CardBody>
            </Card>
          </CardGroup>
          </Col>
        </Row>
      </Container>
      <DialogError/>
    </div>
  )
} 

export default ACL
