import React, { useState, useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Button,
} from "reactstrap";
import http from "service/http";
import { useForm } from "react-final-form-hooks";
import { TextInput, useLoading, SelectInput } from "feature/Component";
import { FormControl } from "@material-ui/core";
import formCreateInputs from "service/form/create";
import numeral from "numeral";
import store from "store";
import "./style.scss";
import moment from "moment";
import { useTranslation } from "react-i18next";
import LoadingUi from "feature/Component/Loading/ui";

// const DEFAULT_FROM_DATE = moment('2022-10-26').format('YYYY-MM-DD')
// const DEFAULT_TO_DATE = moment('2022-10-26').format('YYYY-MM-DD')
const DEFAULT_FROM_DATE = moment().format("YYYY-MM-DD");
const DEFAULT_TO_DATE = moment().format("YYYY-MM-DD");
let user = store.get("user") || { level: "" };

const correctNumber = (amount) => numeral(amount).format("0,0.00");

function History(props) {
  const { t } = useTranslation();
  user = props;
  const query = new URLSearchParams(props.location.search);
  const [loading, withLoading] = useLoading();
  const [sshare, setShare] = useState(0);
  const [selfShare, setSelfShare] = useState(props.share || 0);
  const [data, setData] = useState([]);
  const [dataDownlines, setDataDownlines] = useState([]);
  const [dataTotalDownline, setDataTotalDownline] = useState({
    totalBetAmount: 0,
    expenses: 0,
    share: 0,
    agentProfit: 0,
  });
  const [ptSelf, setptSelf] = useState({
    totalBetAmount: 0,
    expenses: 0,
    share: 0,
    agentProfit: 0,
  });
  const [dataTotal, setDataTotal] = useState({
    gameType: "Total",
    totalBetAmount: 0,
    totalTurnover: 0,
    totalWinLose: 0,
    groupTotal: 0,
    groupProfit: 0,
  });
  const [initialInputs, updateInitialInputs] = useState({});
  const [adminDownLineProfit, setAdminDownLineProfit] = useState(0);

  const fetchBarrel = (params = {}) => {
    withLoading(() =>
      http.get({ path: "admin/barrel-reports", params }).then((rs) => {
        const barrel = rs;
        // console.log(barrel.detailTableDownline)
        if (props.username == "admin") {
          setSelfShare(barrel.sshare);
        }
        setShare(barrel.sshare);
        setptSelf(barrel.dataPtSelf);
        setAdminDownLineProfit(barrel.adminDownLineProfit);
        setDataDownlines(barrel.detailTableDownline);
        setData(barrel.detailTable);
        setDataTotal(barrel.totalTable);
        setDataTotalDownline(barrel.totalTableDownline);
      })
    );
  };
  const onSubmit = (params) => {
    fetchBarrel(params);
  };
  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: initialInputs,
  });
  const [fromDate, toDate] = formCreateInputs(["fromDate", "toDate"], form);

  useEffect(() => {
    fetchBarrel();
  }, []);

  return (
    <div className="animated fadeIn">
      {loading && <LoadingUi />}
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <form onSubmit={handleSubmit}>
                <TextInput
                  style={{ marginLeft: 15, width: 200 }}
                  input={fromDate}
                  label={t("FROM")}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextInput
                  style={{ marginLeft: 15, width: 200 }}
                  input={toDate}
                  label={t("TO")}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormControl style={{ marginTop: 30, marginLeft: 15 }}>
                  {loading ? (
                    t("LOADING") + " ..."
                  ) : (
                    <Button type="submit" color="info" className="px-4">
                      {t("SEARCH")}
                    </Button>
                  )}
                </FormControl>
              </form>
            </CardHeader>
            <CardBody>
              <React.Fragment>
                <Table responsive hover className="fullReportTable" bordered>
                  <thead>
                    <tr style={{ background: "rgb(5 48 130)", color: "white" }}>
                      <th scope="col">{t("PRODUCT")}</th>
                      <th scope="col">{t("TOTAL_BET")} (THB)</th>
                      <th scope="col">{t("PAYOUT")} (THB)</th>
                      <th scope="col">{t("PROFIT")} (THB)</th>
                      <th scope="col">{t("GROUP_TOTAL")} (THB)</th>
                      <th scope="col">{t("GROUP_PROFIT")} (THB)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((g, i) => {
                      return (
                        <tr key={i + i}>
                          <td
                            style={{
                              fontWeight: "bold",
                              background: "#8f9ba68a",
                            }}
                          >
                            {g.gameType}
                          </td>
                          <td>{correctNumber(g.totalBetAmount)}</td>
                          <td>{correctNumber(g.payout)}</td>
                          <td
                            style={{
                              color: g.totalWinLose > 0 ? "red" : "#000",
                            }}
                          >
                            {correctNumber(g.totalWinLose * -1)}
                          </td>
                          <td>{correctNumber(g.groupTotal)}</td>
                          <td
                            style={{
                              color: g.groupProfit < 0 ? "red" : "#000",
                            }}
                          >
                            {g.groupProfit.toFixed(2)}
                            <span
                              className="barrel-info"
                              title={
                                correctNumber(g.totalWinLose * -1) +
                                " / " +
                                sshare +
                                " * " +
                                selfShare +
                                " * (" +
                                correctNumber(g.groupTotal) +
                                " / " +
                                correctNumber(g.totalBetAmount) +
                                ") = " +
                                g.groupProfit.toFixed(2)
                              }
                            >
                              i
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                    <tr style={{ fontWeight: "bold" }}>
                      <td
                        style={{ fontWeight: "bold", background: "#8f9ba68a" }}
                      >
                        {dataTotal.gameType}
                      </td>
                      <td>{correctNumber(dataTotal.totalBetAmount)}</td>
                      <td>{correctNumber(dataTotal.payout)}</td>
                      <td
                        style={{
                          color: dataTotal.totalWinLose > 0 ? "red" : "#000",
                        }}
                      >
                        {correctNumber(dataTotal.totalWinLose * -1)}
                      </td>
                      <td>{correctNumber(dataTotal.groupTotal)}</td>
                      <td
                        style={{
                          color: dataTotal.groupProfit < 0 ? "red" : "#000",
                        }}
                      >
                        {dataTotal.groupProfit.toFixed(2)}
                        <span
                          className="barrel-info"
                          title={
                            correctNumber(dataTotal.totalWinLose * -1) +
                            " / " +
                            sshare +
                            " * " +
                            selfShare +
                            " * (" +
                            correctNumber(dataTotal.groupTotal) +
                            " / " +
                            correctNumber(dataTotal.totalBetAmount) +
                            ") = " +
                            dataTotal.groupProfit.toFixed(2)
                          }
                        >
                          i
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table responsive hover className="fullReportTable" bordered>
                  <thead>
                    <tr style={{ background: "rgb(5 48 130)", color: "white" }}>
                      <th scope="col">{t("AGENT")}</th>
                      <th scope="col">{t("GROUP_MEMBER")} (THB)</th>
                      <th scope="col">{t("EXPENSES")} (THB)</th>
                      <th scope="col">
                        {t("PT")}/{t("SHARE")}
                      </th>
                      <th scope="col">
                        {t("AGENT")} {t("PROFIT")} (THB)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataDownlines.map((g, i) => {
                      return (
                        <>
                          <tr
                            key={g.username + i}
                            style={{
                              fontWeight: "bold",
                              background: "#8f9ba68a",
                            }}
                          >
                            {(user.user_level == "ADMIN" ||
                              user.user_level == "AGENT") && (
                              <td>{g.username}</td>
                            )}
                            {user.user_level == "PARTNER" && (
                              <td>{g.agent || g.partner}</td>
                            )}
                            <td>{correctNumber(g.winlose.totalBetAmount)}</td>
                            {(!g.expenses || g.expenses >= 0) && (
                              <td>{(g.expenses || 0).toFixed(2)}</td>
                            )}
                            {g.expenses < 0 && (
                              <td style={{ color: "red" }}>
                                {g.expenses.toFixed(2)}
                              </td>
                            )}
                            <td>
                              {user.user_level == "AGENT" ? 0 : g.pt}/{sshare}
                            </td>
                            {(!g.totalagentProfit ||
                              g.totalagentProfit >= 0) && (
                              <td>{(g.totalagentProfit || 0).toFixed(2)}</td>
                            )}
                            {g.totalagentProfit < 0 && (
                              <td style={{ color: "red" }}>
                                {g.totalagentProfit.toFixed(2)}
                              </td>
                            )}
                          </tr>
                          {g.groupTotalDownline.map((w, i) => {
                            return (
                              <tr key={g.username + i}>
                                <td>
                                  {g.username} - {w.gameType}
                                </td>
                                <td>{correctNumber(w.totalBetAmount)}</td>
                                {(!w.wexpenses || w.wexpenses >= 0) && (
                                  <td>{(w.wexpenses || 0).toFixed(2)}</td>
                                )}
                                {w.wexpenses < 0 && (
                                  <td style={{ color: "red" }}>
                                    {w.wexpenses.toFixed(2)}
                                  </td>
                                )}
                                <td>
                                  {user.user_level == "AGENT" ? 0 : g.pt}/
                                  {sshare}
                                </td>
                                {(!w.agentProfit || w.agentProfit >= 0) && (
                                  <td>{(w.agentProfit || 0).toFixed(2)}</td>
                                )}
                                {w.agentProfit < 0 && (
                                  <td style={{ color: "red" }}>
                                    {w.agentProfit.toFixed(2)}
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </>
                      );
                    })}
                    <tr style={{ fontWeight: "bold", background: "#8f9ba68a" }}>
                      <td>{props.username}</td>
                      <td>{correctNumber(dataTotalDownline.totalBetAmount)}</td>
                      {(!ptSelf.expenses || ptSelf.expenses >= 0) && (
                        <td>{(ptSelf.expenses || 0).toFixed(2)}</td>
                      )}
                      {ptSelf.expenses < 0 && (
                        <td style={{ color: "red" }}>
                          {ptSelf.expenses.toFixed(2)}
                        </td>
                      )}
                      <td>
                        {user.user_level == "AGENT" ? 0 : selfShare}/{sshare}
                      </td>
                      {(!adminDownLineProfit || adminDownLineProfit >= 0) && (
                        <td>{(adminDownLineProfit || 0).toFixed(2)}</td>
                      )}
                      {adminDownLineProfit < 0 && (
                        <td style={{ color: "red" }}>
                          {adminDownLineProfit.toFixed(2)}
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td>{correctNumber(dataTotalDownline.totalBetAmount)}</td>
                      {(!dataTotalDownline.expenses ||
                        dataTotalDownline.expenses >= 0) && (
                        <td>{(dataTotalDownline.expenses || 0).toFixed(2)}</td>
                      )}
                      {dataTotalDownline.expenses < 0 && (
                        <td style={{ color: "red" }}>
                          {dataTotalDownline.expenses.toFixed(2)}
                        </td>
                      )}
                      <td>{0}</td>
                      {(!dataTotalDownline.agentProfit ||
                        dataTotalDownline.agentProfit >= 0) && (
                        <td>
                          {(dataTotalDownline.agentProfit || 0).toFixed(2)}
                        </td>
                      )}
                      {dataTotalDownline.agentProfit < 0 && (
                        <td style={{ color: "red" }}>
                          {dataTotalDownline.agentProfit.toFixed(2)}
                        </td>
                      )}
                    </tr>
                  </tbody>
                </Table>
              </React.Fragment>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default History;
